import React, { useEffect, useState } from 'react';
import { useLocalStorage } from 'config/hooks';
import { Controlled as CodeMirror } from 'react-codemirror2';
import { Annotation } from 'codemirror/addon/lint/lint.js';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/lint/lint.css';
import 'codemirror/theme/material.css';
require('codemirror/mode/javascript/javascript'); // This is needed to paint the code
require('codemirror/addon/lint/lint');  // Needs to be there so it calls the linter

export interface ValidationError {
  line: number;
  column: number;
  message: string;
  severity: string;
}

interface CodeEditorProps {
  onChange?: (text: string) => void; // Defaulting to string, since valid object can't always be returned
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  errors?: ValidationError[];
  name?: string;
  editable?: boolean;
  value?: string;
  label?: string;
}

const CodeEditor: React.FC<CodeEditorProps> = ({  onChange, onBlur, name, errors, editable = true, value, label }) => {
  
  const [code, setCode] = useState(value || '');
  const [editor, setEditor] = useState<CodeMirror.Editor | null>(null)
  const [isDarkTheme] = useLocalStorage('darkTheme', window.matchMedia('(prefers-color-scheme: dark)').matches)

  // Use useEffect to update linting when errors prop changes
  useEffect(() => {

    if (!!editor && !!errors) {
      if (errors?.length > 0) {
        // Re-apply linting each time the errors prop changes              
        editor.setOption('lint', {
          getAnnotations: (editorInstance: any) => errorMarker(editorInstance, errors),
          lintOnChange: true,
          async: false,
        });
      } else {
        editor.setOption('lint', {
          getAnnotations: (editorInstance: any) => errorMarker(editorInstance, []),
          lintOnChange: true,
          async: false,
        });
      }
    }
  }, [editor, errors]); // Run this effect whenever `errors` changes

  function errorMarker(editor: CodeMirror.Editor, errors: ValidationError[]): Annotation[] {
    let annotations: Annotation[] = [];

    // Map your ValidationError to a valid Annotation type
    errors?.forEach((error) => {
      
      annotations.push({
        from: { line: error.line, ch: error.column },  // Use Position object with `line` and `ch`
        to: { line: error.line, ch: error.column + 5 },  // Use Position object with `line` and `ch`
        message: error.message,
        severity: error.severity as "error" | "warning" | "info",  // Correct severity type
      });
    });    
    return annotations;
  }

  // Sync external value updates
  useEffect(() => {
    setCode(value || ''); // Set code only if value exists
  }, [value]);

  const handleOnChange = (val: string) => {
    if (onChange) onChange(val);
  };

  const handleBlur = (editor: any, event: any) => {
    const currentValue = editor.getValue();  // Get the current value from the editor

    if (onBlur) {
      const syntheticEvent = {
        ...event,
        currentTarget: { value: currentValue },  // Set the editor value as event's currentTarget value
      };
      onBlur(syntheticEvent as React.FocusEvent<HTMLInputElement>);  // Pass the modified event
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <label>{label}</label>

      <CodeMirror
        key={name}
        value={typeof code === 'string' ? code : JSON.stringify(code, null, 2)}  // Ensure it's a string
        options={{
          gutters: ["CodeMirror-lint-markers"],
          mode: 'application/json',  // Use proper mode
          theme: !isDarkTheme?'default':'material',
          readOnly: !editable,
          lineNumbers: true,
        }}
        onBeforeChange={(editor, data, value) => {
          if (editable) {
            handleOnChange(value);
          }
        }}
        onBlur={handleBlur}  // Use the enhanced blur handler

        editorDidMount={(editorInstance) => {
          setEditor(editorInstance); // Save reference to editor instance          
        }}

      />

      {editable && (
        null        
      )}
    </div>
  );
};

export default CodeEditor;