import styled from '@emotion/styled/macro'

export const Container = styled.section`
  width: 100%;
  height: calc(100vh - 60px);
  align-items: flex-start;
  background: ${(props: any) => props.theme.secondary20};
  color: ${(props: any) => props.theme.body};
  display: flex;
  justify-content: center;
  overflow: scroll;
  padding: ${(props: any) => props.theme.spacing.large25};
`
