import { css } from '@emotion/react'
import { colors as lightColors } from './light'
import { colors as darkColors } from './dark'

const sizes = {
  display01: '3.815rem',
  display02: '3.052rem',
  heading01: '2.441rem',
  heading02: '1.953rem',
  heading03: '1.563rem',
  heading04: '1.25rem',
  subhead: '1.125rem',
  body: '1rem',
  body02: '0.875rem',
  caption: '0.8rem',
  small: '0.64rem',
  label: '0.64rem'
}

const shadow = {
  none: 'none',
  xsmall: '0px 1px 2px',
  small: '0px 2px 4px',
  medium: '0px 4px 6px',
  large: '0px 6px 8px',
  xlarge: '0px 8px 10px'
}

const spacing = {
  small: '0.25rem',   // 4px
  medium: '0.5rem',   // 8px
  large: '0.75rem',   // 12px
  large10: '1rem',    // 16px
  large15: '1.5rem',  // 24px
  large20: '2rem',    // 32px
  large25: '2.5rem',  // 40px
  large30: '3rem',    // 48px
  large35: '3.5rem',  // 56px
  large40: '4rem',    // 64px
  large45: '4.5rem',  // 72px
  large50: '5rem'     // 80px
}

const radius = {
  none: '0px',
  small: '4px',
  medium: '8px',
  large: '16px',
  xlarge: '24px',
  full: '9999px'
}

const weight = {
  regular: 400,
  semiBold: 600,
  bold: 700,
  extraBold: 800
}

const texts = {
  display1: css`
    font-family: 'Poppins', sans-serif;
    font-size: ${sizes.display01};
    letter-spacing: -0.5px;
    line-height: 70px;
  `,
  display2: css`
    font-family: 'Poppins', sans-serif;
    font-size: ${sizes.display02};
    letter-spacing: -0.25px;
    line-height: 58px;
  `,
  heading1: css`
    font-family: 'Poppins', sans-serif;
    font-size: ${sizes.heading01};
    line-height: 48px;
  `,
  heading2: css`
    font-family: 'Poppins', sans-serif;
    font-size: ${sizes.heading02};
    line-height: 38px;
  `,
  heading3: css`
    font-family: 'Poppins', sans-serif;
    font-size: ${sizes.heading03};
    line-height: 32px;
  `,
  heading4: css`
    font-family: 'Poppins', sans-serif;
    font-size: ${sizes.heading04};
    line-height: 24px;
  `,
  subhead01: css`
    font-family: 'Public Sans', sans-serif;
    font-size: ${sizes.subhead};
    letter-spacing: 0.1px;
    line-height: 24px;
  `,
  body: css`
    font-family: 'Public Sans', sans-serif;
    font-size: ${sizes.body};
    letter-spacing: 0.15px;
    line-height: 19px;
  `,
  body2: css`
    font-family: 'Public Sans', sans-serif;
    font-size: ${sizes.body02};
    letter-spacing: 0.25px;
    line-height: 16px;
  `,
  buttonmd: css`
    font-family: 'Public Sans', sans-serif;
    font-size: ${sizes.body};
    font-weight: ${weight.semiBold};
    line-height: 24px;
  `,
  buttonsm: css`
    font-family: 'Public Sans', sans-serif;
    font-size: ${sizes.body02};
    font-weight: ${weight.semiBold};
    letter-spacing: 0.1px;
    line-height: 20px;
  `,
  buttonxs: css`
    font-family: 'Public Sans', sans-serif;
    font-size: ${sizes.caption};
    font-weight: ${weight.semiBold};
    letter-spacing: 0.1px;
    line-height: 16px;
  `,
  caption: css`
    font-family: 'Public Sans', sans-serif;
    font-size: ${sizes.caption};
    letter-spacing: 0.4px;
    line-height: 16px;
  `,
  small: css`
    font-family: 'Public Sans', sans-serif;
    font-size: ${sizes.small};
    letter-spacing: 0.2px;
    line-height: 14px;
  `,
  label: css`
    font-family: 'Public Sans', sans-serif;
    font-size: ${sizes.label};
    letter-spacing: 0.2px;
    line-height: 12px;
  `
}

const scrollbar = {
  small: css`
    overflow: auto;
    ::-webkit-scrollbar {
      width: 4px; /* Mostly for vertical scrollbars */
      height: 26px; /* Mostly for horizontal scrollbars */
    }
    ::-webkit-scrollbar-thumb { /* Foreground */
      background: ${lightColors ? lightColors.global30 : darkColors.global30};
      border-radius: ${radius.full};
    }
    ::-webkit-scrollbar-track { /* Background */
      background: ${lightColors ? lightColors.base20 : darkColors.base20};
    }
    ::-webkit-scrollbar-corner {
      background: rgba(0,0,0,0);
    }

    /* Firefox */
    scrollbar-width: thin;
    scrollbar-color: ${lightColors ? lightColors.global30 : darkColors.global30} ${lightColors ? lightColors.base20 : darkColors.base20};
  `,
  medium: css`
    overflow: auto;
    ::-webkit-scrollbar {
      width: 6px; /* Mostly for vertical scrollbars */
      height: 80px; /* Mostly for horizontal scrollbars */
    }
    ::-webkit-scrollbar-thumb { /* Foreground */
      background: ${lightColors ? lightColors.global30 : darkColors.global30};
      border-radius: ${radius.full};
    }
    ::-webkit-scrollbar-track { /* Background */
      background: ${lightColors ? lightColors.base20 : darkColors.base20};
    }
    ::-webkit-scrollbar-corner {
      background: rgba(0,0,0,0);
    }

    /* Firefox */
    scrollbar-width: thin;
    scrollbar-color: ${lightColors ? lightColors.global30 : darkColors.global30} ${lightColors ? lightColors.base20 : darkColors.base20};
  `,
  textarea: css`
    overflow: auto;
    ::-webkit-scrollbar {
      width: 12px; /* Mostly for vertical scrollbars */
      height: 12px; /* Mostly for horizontal scrollbars */
    }
    ::-webkit-scrollbar-thumb { /* Foreground */
      width: 4px;
      height: 24px;
      background-color: ${lightColors ? lightColors.global30 : darkColors.global30};
      background-clip: padding-box;
      border: 4px solid transparent;
      border-radius: ${radius.full};
    }
    ::-webkit-scrollbar-track { /* Background */
      /* background: ${lightColors ? lightColors.base20 : darkColors.base20}; */
      background: transparent;
    }
    ::-webkit-scrollbar-corner {
      background: rgba(0,0,0,0);
    }

    /* Firefox */
    scrollbar-width: thin;
    scrollbar-color: ${lightColors ? lightColors.global30 : darkColors.global30} ${lightColors ? lightColors.base20 : darkColors.base20};
  `,
}

const defaultTheme = {
  shadow,
  spacing,
  radius,
  sizes,
  weight,
  texts,
  scrollbar
}

export const theme: any = {
  'light': { ...lightColors, ...defaultTheme },
  'dark': { ...darkColors, ...defaultTheme }
}
