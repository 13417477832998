import styled from '@emotion/styled/macro'

export const Img = styled.img`
  width: 100%;
  max-width: 250px;
  height: auto;
  display: block;
  margin: 0 auto;
`

export const ImageWrapper = styled.div`
  width: 100%;
  max-width: 250px;
  &.smallImage {
    max-width: 190px;
  }
`

export const Title = styled.h1`
  ${(props: any) => props.theme.texts.subhead01};
  font-weight: 700;
  margin: ${(props: any) => props.theme.spacing.large10} 0;
`

export const Subtitle = styled.h2`
  ${(props: any) => props.theme.texts.body};
  font-weight: 600;
  margin-bottom: ${(props: any) => props.theme.spacing.small};
`

export const Text = styled.p`
  ${(props: any) => props.theme.texts.body02};
`

export const TxtWrapper = styled.div`
  width: 100%;
  max-width: 352px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
`

export const Container = styled.div`
  width: 100%;
  max-width: 448px;
  align-items: center;
  background-color: ${(props: any) => props.theme.base10};
  border-radius: ${(props: any) => props.theme.radius.large};
  color: ${(props: any) => props.theme.body};
  display: flex;
  flex-direction: column;
  padding: ${(props: any) => props.theme.spacing.large15};

  .action--button {
    width: fit-content;
    margin-top: ${(props: any) => props.theme.spacing.large25};
  }

  &.small {
    ${Img} {
      width: 100%;
      max-width: 125px;
    }

    ${ImageWrapper} {
      width: 100%;
      max-width: 125px;
    }

    ${Title} {
      display: none;
    }

    ${Text} {
      padding-top: ${(props: any) => props.theme.spacing.large15};
    }
  }
`
