import axios from 'axios'
import { APIURL } from 'config/helpers'

export const getAccounts = (
    token: string,
    limit: number = 25,
    offset: number = 0,
    onSuccess: (r: any) => void,
    onError: (r: any) => void
) => {
    axios
        .get(`${APIURL}/accounts`, {
            headers: { Authorization: `Bearer ${token}` },
            params: {
                limit: limit,
                offset: offset,
            }
        })
        .then(response => {
            onSuccess(response)
        })
        .catch(error => {
            onError(error)
        })
}

export const getAccount = (token: string, account_id: string, onSuccess: (r: any) => void, onError: (r: any) => void) => {
    axios
        .get(`${APIURL}/accounts/${account_id}`, {
            headers: { Authorization: `Bearer ${token}` }
        })
        .then(response => {
            onSuccess(response)
        })
        .catch(error => {
            onError(error)
        })
}


// Promise based instead of callbacks

export const deleteAccount = async (
    token: string,
    account_id: string,
): Promise<any> => {
    try {
        const response = await axios
            .delete(`${APIURL}/accounts/${account_id}`, {
                headers: { Authorization: `Bearer ${token}` }
            })
        return response
    } catch (error) {
        throw error
    }
}


export const createAccount = async (
    token: string,
    account_name: string,
): Promise<any> => {
    try {
        const response = await axios
            .post(`${APIURL}/accounts/`, { name: account_name }, {
                headers: { Authorization: `Bearer ${token}` }
            })
        return response
    } catch (error) {
        throw error
    }
};


export const updateAccount = async (
    token: string,
    account_id: string,
    payload: any
): Promise<any> => {
    try {
        const response = await axios
            .put(`${APIURL}/accounts/${account_id}`, payload, {
                headers: { Authorization: `Bearer ${token}` }
            })
        return response
    } catch (error) {
        throw error
    }
};



export const activateAccount = async (
    token: string,
    account_id: string,
): Promise<any> => {
    try {
        const response = await axios
            .post(`${APIURL}/accounts/${account_id}/activate`, {}, {
                headers: { Authorization: `Bearer ${token}` }
            })
        return response
    } catch (error) {
        throw error
    }
};

export const deactivateAccount = async (
    token: string,
    account_id: string,
): Promise<any> => {
    try {
        const response = await axios
            .post(`${APIURL}/accounts/${account_id}/deactivate`, {}, {
                headers: { Authorization: `Bearer ${token}` }
            })
        return response
    } catch (error) {
        throw error
    }
};



type AccountResponse = {
    data: {
        results: any[]; // Replace 'any' with the actual type of the items if you know it
        count: number;
    };
};

export const getAllAccounts = async (token: string) => {
    let allItems: any[] = [];
    let offset = 0;
    const itemsPerPage = 200; // The number of items to fetch per request
    let total = 0;

    try {
        while (true) {
            let currentOffset = offset;
            // Wrap getAccounts in a promise to use async/await
            const response = await new Promise<AccountResponse>((resolve, reject) => {
                getAccounts(
                    token,
                    itemsPerPage,
                    currentOffset,
                    (response: any) => resolve(response),
                    (error: any) => reject(new Error(error.response.data.details))
                );
            });

            const items = response.data.results;
            total = response.data.count;
            
            allItems = [...allItems, ...items];

            if (allItems.length >= total) {
                break; // Exit the loop when all items have been fetched
            }
            offset += items.length; // Increase the offset by the number of items fetched
        }
        return { items: allItems, total };
    } catch (error: any) {
        throw new Error(error.message || "Something went wrong.");
    }
};