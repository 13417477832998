export const polygonSchema = {
    "$schema": "http://json-schema.org/draft-07/schema#",
    "title": "GeoJSON Polygon or MultiPolygon",
    "type": "object",
    "properties": {
        "type": {
            "type": "string",
            "enum": ["Polygon", "MultiPolygon"]
        },
        "coordinates": {
            "oneOf": [
                {
                    "type": "array",   // Polygon coordinates
                    "items": {
                        "type": "array",
                        "items": {
                            "type": "array",
                            "minItems": 2,
                            "maxItems": 2,
                            "items": { "type": "number" }
                        },
                        "minItems": 4   // Each ring must have at least 4 coordinates
                    }
                },
                {
                    "type": "array",   // MultiPolygon coordinates
                    "items": {
                        "type": "array",
                        "items": {
                            "type": "array",
                            "items": {
                                "type": "array",
                                "minItems": 2,
                                "maxItems": 2,
                                "items": { "type": "number" }
                            },
                            "minItems": 4
                        }
                    }
                }
            ]
        }
    },
    "required": ["type", "coordinates"]
}  