import styled from '@emotion/styled/macro'

export const DialogFooter = styled.div`
  width: 100%;
  padding: ${(props: any) => props.theme.spacing.large10};
`

export const DialogHeader = styled.div`
  width: 100%;
  background-color: ${(props: any) => props.theme.base20};
  border-top-left-radius: ${(props: any) => props.theme.radius.medium};
  border-top-right-radius: ${(props: any) => props.theme.radius.medium};
  margin-bottom: ${(props: any) => props.theme.spacing.large10};
  padding: ${(props: any) => props.theme.spacing.large10};
`

export const HeaderTitle = styled.h5`
  align-items: center;
  color: ${(props: any) => props.theme.body};
  display: flex;
  ${(props: any) => props.theme.texts.heading4};
  font-weight: ${(props: any) => props.theme.weight.bold};
  svg {
    width: ${(props: any) => props.theme.spacing.large15};
    flex: 0 0 ${(props: any) => props.theme.spacing.large15};
    color: ${(props: any) => props.theme.body};
    margin-right: ${(props: any) => props.theme.spacing.large10};
  }
`

export const DialogBody = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 ${(props: any) => props.theme.spacing.large10};
  ${(props: any) => props.theme.scrollbar.small};
  overflow-x: hidden;
  overflow-y: auto;
`

export const BodyTitle = styled.h6`
  color: ${(props: any) => props.theme.body};
  ${(props: any) => props.theme.texts.subhead01};
  font-weight: 600;
  margin-bottom: 10px;
`

export const BodyText = styled.div`
  ${(props: any) => props.theme.texts.body2};
  color: ${(props: any) => props.theme.body};
`

export const ButtonContainer = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  .cancel--button {
    margin-right: ${(props: any) => props.theme.spacing.large10};
  }
`

export const DialogContainer = styled.div`
  width: 430px;
  max-height: 90vh;
  border-radius: ${(props: any) => props.theme.radius.medium};
  background-color: ${(props: any) => props.theme.base10};
  display: flex;
  flex-flow: column;
  &.medium {
    width: 600px;
  }
  &.large {
    width: 700px;
  }
  &.extra-large {
    width: 900px;
  }
  &.info {
    ${DialogHeader} {
      background-color: ${(props: any) => props.theme.info};
    }
    ${HeaderTitle} {
      color: ${(props: any) => props.theme.secondary70};
      svg {
        color: ${(props: any) => props.theme.secondary70};
      }
    }
  }
  &.success {
    ${DialogHeader} {
      background-color: ${(props: any) => props.theme.success};
    }
    ${HeaderTitle} {
      color: ${(props: any) => props.theme.secondary70};
      svg {
        color: ${(props: any) => props.theme.secondary70};
      }
    }
  }
  &.warning {
    ${DialogHeader} {
      background-color: ${(props: any) => props.theme.alert};
    }
    ${HeaderTitle} {
      color: ${(props: any) => props.theme.secondary70};
      svg {
        color: ${(props: any) => props.theme.secondary70};
      }
    }
  }
  &.error {
    ${DialogHeader} {
      background-color: ${(props: any) => props.theme.error};
    }
    ${HeaderTitle} {
      color: ${(props: any) => props.theme.global10};
      svg {
        color: ${(props: any) => props.theme.global10};
      }
    }
  }
`
