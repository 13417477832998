import styled from '@emotion/styled'

export const TooltipContainer = styled.div`
  position: relative;
`

export const ToggleElement = styled.div``

export const TooltipComponent = styled.div`
  width: max-content;
  max-width: 300px;
  height: auto;
  align-items: center;
  border-radius: ${(props: any) => props.theme.radius.small};
  background-color: ${(props: any) => props.theme.secondary60};
  color: ${(props: any) => props.theme.global10};
  display: flex;
  flex-direction: row;
  justify-content: center;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  padding: 8px;
  pointer-events: none;
  z-index: 9999;
  ${(props: any) => props.theme.texts.caption};
  position: absolute;
  &.show {
    opacity: 1;
    pointer-events: all;
  }
  &::after {
    border-style: solid;
    border-width: 7px;
    content: '';
    display: block;
    z-index: 1;
    position: absolute;
  }

  &.left {
    transform: translate(-100%, -50%);
    top: 50%;
    left: -11px;
  }
  &.left::after {
    border-color: transparent transparent transparent ${(props: any) => props.theme.secondary60};
    left: 100%;
  }

  &.top-left {
    transform: translate(0, -100%);
    top: -11px;
    left: calc(50% - 27px);
  }
  &.top-left::after {
    border-color: ${(props: any) => props.theme.secondary60} transparent transparent transparent;
    top: 100%;
    left: 20px;
  }

  &.top {
    transform: translate(-50%, -100%);
    top: -11px;
    left: 50%;
  }
  &.top::after {
    border-color: ${(props: any) => props.theme.secondary60} transparent transparent transparent;
    top: 100%;
    left: calc(50% - 7px);
  }

  &.top-right {
    transform: translate(0, -100%);
    top: -11px;
    right: calc(50% - 27px);
  }
  &.top-right::after {
    border-color: ${(props: any) => props.theme.secondary60} transparent transparent transparent;
    top: 100%;
    right: 20px;
  }

  &.right {
    transform: translate(100%, -50%);
    top: 50%;
    right: -11px;
  }
  &.right::after {
    border-color: transparent ${(props: any) => props.theme.secondary60} transparent transparent;
    right: 100%;
  }

  &.bottom-right {
    transform: translate(0, 100%);
    right: calc(50% - 27px);
    bottom: -11px;
  }
  &.bottom-right::after {
    border-color: transparent transparent ${(props: any) => props.theme.secondary60} transparent;
    right: 20px;
    bottom: 100%;
  }

  &.bottom {
    transform: translate(-50%, 100%);
    bottom: -11px;
    left: 50%;
  }
  &.bottom::after {
    border-color: transparent transparent ${(props: any) => props.theme.secondary60} transparent;
    bottom: 100%;
    left: calc(50% - 7px);
  }

  &.bottom-left {
    transform: translate(0, 100%);
    bottom: -11px;
    left: calc(50% - 27px);
  }
  &.bottom-left::after {
    border-color: transparent transparent ${(props: any) => props.theme.secondary60} transparent;
    bottom: 100%;
    left: 20px;
  }

  &.small {
    ${(props: any) => props.theme.texts.small};
    padding: ${(props: any) => props.theme.spacing.small};
    &::after {
      border-width: 5px;
    }
    &.left {
      left: -9px;
    }
    &.top-left {
      top: -9px;
      left: calc(50% - 25px);
    }
    &.top {
      top: -9px;
    }
    &.top::after {
      left: calc(50% - 5px);
    }
    &.top-right {
      top: -9px;
      right: calc(50% - 25px);
    }
    &.right {
      right: -9px;
    }
    &.bottom-right {
      right: calc(50% - 25px);
      bottom: -9px;
    }
    &.bottom {
      bottom: -9px;
    }
    &.bottom::after {
      left: calc(50% - 5px);
    }
    &.bottom-left {
      bottom: -9px;
      left: calc(50% - 25px);
    }
  }
`
