import { useState, useRef, useEffect } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import type { RootState, AppDispatch } from '../store'

export function useMergeState<T>(initialState: T): [T, (newState: Partial<T>) => void, any] {
  const [state, setState] = useState(initialState)
  const dataRef: any = useRef(initialState)
  const setMergedState = (newState: Partial<T>) => {
    setState(prevState => {
      const mergeState = Object.assign({}, prevState, newState)
      dataRef.current = mergeState
      return mergeState
    })
  }
  return [state, setMergedState, dataRef]
}

export function useLocalStorage<T>(key: string, defaultValue: T): [T, (value: T) => void] {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    const item = localStorage.getItem(key)
    if (!item) localStorage.setItem(key, JSON.stringify(defaultValue))
    setValue(item ? JSON.parse(item) : defaultValue)

    const handler = (e: StorageEvent) => {
      if (e.key !== key) return
      const lsi = localStorage.getItem(key)
      setValue(JSON.parse(lsi ?? ''))
    }

    window.addEventListener("storage", handler)

    return () => window.removeEventListener("storage", handler)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setValueWrap = (value: T) => {
    try {
      setValue(value)
      localStorage.setItem(key, JSON.stringify(value))
      if (typeof window !== "undefined") {
        window.dispatchEvent(new StorageEvent("storage", { key }))
      }
    } catch (e) { console.error(e) }
  }

  return [value, setValueWrap]
}

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
