import axios from 'axios'
import { APIURL } from 'config/helpers'

export enum PrincipalInterfaces {
    USER = 'user',
    REST_API = 'rest_api'
}

export const getRoles = (
  token: string,  
  onSuccess: (r: any) => void,
  onError: (r: any) => void
) => {
  axios
    .get(`${APIURL}/auth/roles/`, {
      headers: { Authorization: `Bearer ${token}` },      
    })
    .then(response => {
      onSuccess(response)
    })
    .catch(error => {
      onError(error)
    })
}

  
export const getRole = (
  token: string,
  role_id: string,  
  onSuccess: (r: any) => void,
  onError: (r: any) => void
) => {
  axios
    .get(`${APIURL}/auth/roles/${role_id}`, {
      headers: { Authorization: `Bearer ${token}` },        
    })
    .then(response => {
      onSuccess(response)
    })
    .catch(error => {
      onError(error)
    })
}

// Promise based instead of callbacks

export const deleteRole = async (
  token: string,
  role_id: string,  
): Promise<any> => {
  try {
      const response = await axios
          .delete(`${APIURL}/auth/roles/${role_id}`, {
              headers: { Authorization: `Bearer ${token}` }
          })
      return response
  } catch (error) {
      throw error
  }
}

export const updateRole = async (
  token: string,
  role_id: string,  
  payload: any
): Promise<any> => {
  try {
      const response = await axios
          .put(`${APIURL}/auth/roles/${role_id}`, payload, {
              headers: { Authorization: `Bearer ${token}` }
          })
      return response
  } catch (error) {
      throw error
  }
};