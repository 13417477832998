import styled from '@emotion/styled/macro'

export const ModalOverlay = styled.div`
  width: 100%;
  height: 100%;
  background: #000000B2;
  position: absolute;
  top: 0;
  left: 0;
`

export const ModalWindow = styled.div`
  box-shadow: ${(props: any) => props.theme.shadow.xlarge} ${(props: any) => props.theme.shadowColor};
  border-radius: ${(props: any) => props.theme.radius.large};
  margin: 0;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
`

export const ModalBase = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 9999994;
`
