import { createSlice } from '@reduxjs/toolkit'


interface globalState {
    refreshCounter: number
}

const initialState: globalState = {
    refreshCounter: 0,    
}

export const globaltoreSlice = createSlice({
    name: 'globalStore',
    initialState,
    reducers: {        
        refreshTrigger: (state) => {
            state.refreshCounter = state.refreshCounter + 1
        },        
    }
})

export const { refreshTrigger} = globaltoreSlice.actions

export default globaltoreSlice.reducer
