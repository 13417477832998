import axios from 'axios'
import { APIURL } from 'config/helpers'

export const getUsers = (
  token: string,
  account_id: string,
  limit: number = 25,
  offset: number = 0,
  onSuccess: (r: any) => void,
  onError: (r: any) => void
) => {
  axios
    .get(`${APIURL}/auth/users/${account_id}`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        limit: limit,
        offset: offset,
      }
    })
    .then(response => {
      onSuccess(response)
    })
    .catch(error => {
      onError(error)
    })
}

export const getUser = (
  token: string,
  account_id: string,
  principal_id: string,
  onSuccess: (r: any) => void,
  onError: (r: any) => void
) => {
  axios
    .get(`${APIURL}/auth/users/${account_id}/${principal_id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(response => {
      onSuccess(response)
    })
    .catch(error => {
      onError(error)
    })
}


export const getApiCredentials = (
  token: string,
  account_id: string,
  onSuccess: (r: any) => void,
  onError: (r: any) => void
) => {
  axios
    .get(`${APIURL}/auth/api-credentials/${account_id}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(response => {
      onSuccess(response)
    })
    .catch(error => {
      onError(error)
    })
}

export const generateApiCredential = (
  token: string,
  account_id: string,
  payload: object,
  onSuccess: (r: any) => void,
  onError: (r: any) => void
) => {
  axios
    .post(
      `${APIURL}/auth/api-credentials/${account_id}`,
      payload,
      { headers: { Authorization: `Bearer ${token}` } }
    )
    .then(response => {
      onSuccess(response)
    })
    .catch(error => {
      onError(error)
    })
}

export const revokeApiCredential = (
  token: string,
  account_id: string,
  credentialId: string,
  onSuccess: (r: any) => void,
  onError: (r: any) => void
) => {
  axios
    .delete(`${APIURL}/auth/api-credentials/${account_id}/${credentialId}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(response => {
      onSuccess(response)
    })
    .catch(error => {
      onError(error)
    })
}

// Promise based instead of callbacks

export const deleteUser = async (
  token: string,
  account_id: string,
  user_id: string,
): Promise<any> => {
  try {
    const response = await axios
      .delete(`${APIURL}/auth/users/${account_id}/${user_id}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
    return response
  } catch (error) {
    throw error
  }
}


export const createUser = async (
  token: string,
  account_id: string,
  payload: any,
): Promise<any> => {
  try {
    const response = await axios
      .post(`${APIURL}/auth/users/${account_id}`, payload, {
        headers: { Authorization: `Bearer ${token}` }
      })
    return response
  } catch (error) {
    throw error
  }
};

/*
Not more valid
export const updateUser = async (
  token: string,
  account_id: string,
  user_id: string,
  payload: any
): Promise<any> => {
  try {
    const response = await axios
      .put(`${APIURL}/auth/users/${account_id}/${user_id}`, payload, {
        headers: { Authorization: `Bearer ${token}` }
      })
    return response
  } catch (error) {
    throw error
  }
};
*/

export const deactivateUser = async (
  token: string,
  account_id: string,
  user_id: string,
): Promise<any> => {
  try {
    const response = await axios
      .post(`${APIURL}/auth/users/${account_id}/${user_id}/deactivate`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      })
    return response
  } catch (error) {
    throw error
  }
};


export const activateUser = async (
  token: string,
  account_id: string,
  user_id: string,
): Promise<any> => {
  try {
    const response = await axios
      .post(`${APIURL}/auth/users/${account_id}/${user_id}/activate`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      })
    return response
  } catch (error) {
    throw error
  }
};

export const resetPassword = async (
  token: string,
  account_id: string,
  user_id: string,
): Promise<any> => {
  try {
    const response = await axios
      .post(`${APIURL}/auth/users/${account_id}/${user_id}/reset-password`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      })
    return response
  } catch (error) {
    throw error
  }
};

export const assignRoles = async (
  token: string,
  account_id: string,
  user_id: string,
  role_ids: string[]
): Promise<any> => {
  try {
    const response = await axios
      .post(`${APIURL}/auth/users/${account_id}/${user_id}/assign-role`, {role_ids: role_ids}, {
        headers: { Authorization: `Bearer ${token}` }
      })
    return response
  } catch (error) {
    throw error
  }
};

export const removeRoles = async (
  token: string,
  account_id: string,
  user_id: string,
  role_ids: string[]
): Promise<any> => {
  try {
    const response = await axios
      .post(`${APIURL}/auth/users/${account_id}/${user_id}/remove-role`, {role_ids: role_ids}, {
        headers: { Authorization: `Bearer ${token}` }
      })
    return response
  } catch (error) {
    throw error
  }
};


type AccountResponse = {
  data: {
    results: any[]; // Replace 'any' with the actual type of the items if you know it
    count: number;
  };
};

export const getAllUsers = async (token: string, account_id: string) => {
  let allItems: any[] = [];
  let offset = 0;
  const itemsPerPage = 200; // The number of items to fetch per request
  let total = 0;

  try {
    while (true) {
      let currentOffset = offset;
      // Wrap getAccounts in a promise to use async/await
      const response = await new Promise<AccountResponse>((resolve, reject) => {
        getUsers(
          token,
          account_id,
          itemsPerPage,
          currentOffset,
          (response: any) => resolve(response),
          (error: any) => reject(new Error(error.response.data.details))
        );
      });

      const items = response.data.results;
      total = response.data.count;      
      allItems = [...allItems, ...items];

      if (allItems.length >= total) {
        break; // Exit the loop when all items have been fetched
      }
      offset += items.length; // Increase the offset by the number of items fetched
    }
    return { items: allItems, total };
  } catch (error: any) {
    throw new Error(error.message || "Something went wrong.");
  }
};