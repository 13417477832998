import axios from 'axios'
import { APIURL } from 'config/helpers'

export const getMy = async (
  token: string, 
  what: string
): Promise<any> => {  
  try {
    const response = await axios
      .get(`${APIURL}/my/${what}`,        
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
    return response.data
  } catch (error) {
    throw error
  }
};