import React, { useState, ChangeEvent, useEffect, useRef } from 'react';
import { DurationInputForm, DurationLabel, DurationWrapper, ClearButton, HelperText } from './input.styles';
import { Duration } from 'luxon';

interface DurationInputProps {
  onChange?: (duration: string | undefined) => void;  // Allow `undefined` to be passed as a duration
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  helperText?: string;
  name?: string;
  value?: string;
}

const DurationInput: React.FC<DurationInputProps> = ({ onChange, onBlur, name, helperText, value }) => {
  const [days, setDays] = useState<number | undefined>(undefined);
  const [hours, setHours] = useState<number | undefined>(undefined);
  const [minutes, setMinutes] = useState<number | undefined>(undefined);

  // Track the previous duration value to avoid unnecessary calls to `onChange`
  const prevDurationRef = useRef<string | undefined>(undefined);

  // Effect to notify parent when any value changes or is cleared
  useEffect(() => {
    
    if (!!days || !!hours || !!minutes){
      const duration = Duration.fromObject({
        days: days ?? 0,
        hours: hours ?? 0,
        minutes: minutes ?? 0
      }).toISO();
  
      // If the new duration is the same as the previous one, don't trigger `onChange`
      if (duration === prevDurationRef.current) {
        // Update the previous duration value
        prevDurationRef.current = duration; 
        return;
      }
  
      // Update the previous duration value
      prevDurationRef.current = duration;
  
      // If it's zero duration, send undefined to the parent
      if (duration === "PT0S") {
        if(onChange) onChange(undefined);        
      } else {
        if (onChange) onChange(duration);
      }  
    }
  }, [days, hours, minutes, onChange]);  // Add `onChange` back into the dependency array

  // Helper function to handle input changes and update state
  const handleInputChange = (setter: React.Dispatch<React.SetStateAction<number | undefined>>) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(e.target.value, 10);
      if (isNaN(value)) {
        setter(0); // Default to 0 if input is not a valid number
      } else {
        setter(value);
      }
    };

  // Function to clear all values (set to null)
  const clearValues = () => {
    setDays(undefined);
    setHours(undefined);
    setMinutes(undefined);
    if(onChange) onChange(undefined); // Important to keep this, because the useffect wont trigger the callback when cleared otherwise.
  };
  
  useEffect(() => {
    if (value){
      const {days, hours, minutes} = Duration.fromISO(value);
      setDays(days)
      setHours(hours)
      setMinutes(minutes)      
    }    
  }, [value]);
  

  return (
    <>
    <div style={{ width: "100%", display:"flex" }}>
    {!!name && <label style={{width: "50%", alignContent:"center"}}>{name}</label>}
    <DurationWrapper>
      <DurationLabel>
        Days:
        <DurationInputForm
          type="number"
          value={days ?? ''}  // Handle null state
          onChange={handleInputChange(setDays)}
          onBlur={onBlur}  // Add the onBlur handler
          min="0"
        />
      </DurationLabel>
      <DurationLabel>
        Hours:
        <DurationInputForm
          type="number"
          value={hours ?? ''}  // Handle null state
          onChange={handleInputChange(setHours)}
          onBlur={onBlur}  // Add the onBlur handler
          min="0"
          max="23"
        />
      </DurationLabel>
      <DurationLabel>
        Minutes:
        <DurationInputForm
          type="number"
          value={minutes ?? ''}  // Handle null state
          onBlur={onBlur}  // Add the onBlur handler
          onChange={handleInputChange(setMinutes)}
          min="0"
          max="59"
        />
      </DurationLabel>

      {/* Clear/Reset Button */}
      <ClearButton onClick={clearValues}>X</ClearButton>
      
    </DurationWrapper>    
    </div>
    {!!helperText && <HelperText>{helperText}</HelperText>}
    </>
  );
};

export default DurationInput;