import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import type { RootState } from "store";
import { TableCRUD } from "components/tableCRUD"

import { generateApiCredential, revokeApiCredential, } from "services/account-service/userServices";

import { TrashIcon } from "@heroicons/react/24/outline";

import { ContentWrapper, EmptyStateWrapper } from "views/views.styles";
import { EmptyState } from "components/emptyState";
import { renderListAsPill, renderDateCell } from "config/helpers";
import { useState } from "react";
import { Contract, Role, UserApiCredential } from "typings";

import { DetailsModal } from "./components/detailsModal";
import { refreshTrigger } from "stores/globalStore/globalStoreSlice";
import { PrincipalInterfaces } from "services/account-service/rolesServices";

export const ApiCredentials2 = () => {

    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();


    const roles = useSelector((state: RootState) => state.userStore.roles);

    const contracts = useSelector((state: RootState) => state.userStore.contracts);

    const [activeCredential, setActiveCredential] = useState<UserApiCredential | undefined>(undefined);
    const [showCredentialModal, setShowCredentialModal] = useState<boolean>(false);

    const apiCredentials = useSelector((state: RootState) => state.userStore.apiCredentials);

    const active_account = useSelector((state: RootState) => state.userStore.active_account);

    const scopes = useSelector((state: RootState) => state.userStore.scopes)

    const newApiCredential = async (data: any): Promise<{ success: boolean; message: string }> => {
        const token = await getAccessTokenSilently()
        return new Promise<{ success: boolean; message: string }>((resolve, reject) => {

            if (Object.keys(data).includes("role_ids")) {
                data['role_ids'] = data['role_ids'].split(",").map((x: string) => x.trim())
            } else {
                data['role_ids'] = []
            }
            if (Object.keys(data).includes("contract_ids")) {
                data['contract_ids'] = data['contract_ids'].split(",").map((x: string) => x.trim())
            } else {
                data['contract_ids'] = []
            }

            generateApiCredential(
                token,
                active_account?.account_id!,
                data,
                (response) => {
                    const credential: UserApiCredential = {
                        ...response.data
                    }

                    setActiveCredential(credential);
                    setShowCredentialModal(true);
                    dispatch(refreshTrigger());
                    resolve({
                        success: true,
                        message: "Credential succesfully created",
                    });
                },
                (error) => {
                    console.error(error)
                    reject({
                        success: false,
                        message: `Couldn´t create credential: ${error.response.data.message}`,
                    });
                }
            )
        })
    }

    const removeApiCredential = async (data: any): Promise<{ success: boolean; message: string }> => {
        const token = await getAccessTokenSilently()

        return new Promise<{ success: boolean; message: string }>((resolve, reject) => {
            revokeApiCredential(
                token,
                active_account?.account_id!,
                data.principal_id,
                () => {
                    dispatch(refreshTrigger());
                    resolve({
                        success: true,
                        message: "Credential succesfully created",
                    });
                },
                (error) => {
                    reject({
                        success: false,
                        message: `Couldn´t revoke credential: ${error.response.data.message}`,
                    });
                }
            )
        }
        )
    }
    const roleOptions = roles ? Object.entries(roles.filter((role: Role) => role.interface === PrincipalInterfaces.REST_API)).map(([key, role]) => ({
        value: role.role_id,
        label: role.name,
    })) : undefined;

    const contractOptions = contracts ? contracts.map((contract: Contract) => ({
        value: contract.contract_id,
        label: contract.name,
    })) : undefined;

    return (

        (!!active_account ?
            <>
                <ContentWrapper full>
                    <TableCRUD
                        title={"API credentials"}
                        columns={[
                            { label: "Name", dataKey: "name" },
                            { label: "ID", dataKey: "principal_id" },
                            {
                                label: "Roles", dataKey: "role_ids",
                                renderer: ({ cellData }: { cellData?: any }) =>
                                    renderListAsPill({
                                        data: cellData?.map((item: any) => ({
                                            id: item,
                                            label: roles?.find((role: any) => role.role_id === item)?.name || item
                                        }))
                                    })
                            },
                            {
                                label: "Contracts", dataKey: "contracts_ids",
                                renderer: ({ cellData }: { cellData?: any }) =>
                                    renderListAsPill({
                                        data: cellData?.map((item: any) => ({
                                            id: item,
                                            label: contracts?.find((role: any) => role.contract_id === item)?.name || item
                                        }))
                                    })
                            },
                            { label: "Created", dataKey: "created_at", renderer: renderDateCell },
                            { label: "Last Updated", dataKey: "updated_at", renderer: renderDateCell },
                        ]}
                        idKey="principal_id"
                        actions={{
                            ...(scopes?.includes("accounts:api-credentials:write-account") ? {
                                create: {
                                    actionHandler: newApiCredential,
                                    fields: [
                                        {
                                            name: 'name',
                                            label: 'Name',
                                            type: 'text',
                                            validation: { required: true, minLength: 3, message: 'Name is required' },
                                        },
                                        {
                                            name: 'role_ids',
                                            label: 'Roles',
                                            type: 'list',
                                            validation: { options: roleOptions },
                                        },
                                        {
                                            name: 'contract_ids',
                                            label: 'Assigned contracts',
                                            type: 'list',
                                            validation: { options: contractOptions },
                                        },
                                    ]
                                },

                                revoke: {
                                    icon: <TrashIcon />,
                                    tooltip: "Revoke credential",
                                    confirm: true,
                                    confirmationText: "Are you sure you want to revoke the credential: {name} ({id})?",
                                    actionHandler: (item: any) => { return removeApiCredential(item) }
                                },
                            } : {}),
                        }}
                        data={{ items: apiCredentials ? apiCredentials : [], total: apiCredentials ? apiCredentials?.length : 999 }}
                    />
                </ContentWrapper>
                <DetailsModal
                    apiCredential={activeCredential}
                    isShown={showCredentialModal}
                    onClose={() => {
                        setShowCredentialModal(false);
                        setActiveCredential(undefined)
                        dispatch(refreshTrigger());
                    }}
                />
            </>
            :
            <EmptyStateWrapper>
                <EmptyState
                    imgUrl="/assets/empty-state.svg"
                    text="Please select an organization first"
                    s-ll
                />
            </EmptyStateWrapper>
        )





    )


};