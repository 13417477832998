import styled from "@emotion/styled/macro"

export const Container = styled.main`
  width: 100%;
  height: 100vh;
  align-items: center;
  background-image: url('/assets/satellogic-background-image.jpeg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-flow: column;
  gap: ${(props: any) => props.theme.spacing.large10};
  justify-content: center;
`

export const LoaderWrapper = styled.section`
  align-items: center;
  background-color: ${(props: any) => props.theme.secondary70};
  border-radius: ${(props: any) => props.theme.radius.large};
  display: flex;
  flex-flow: column;
  gap: ${(props: any) => props.theme.spacing.large10};
  justify-content: center;
  padding: ${(props: any) => props.theme.spacing.large20};
  .logo {
    width: 150px;
    height: auto;
  }
`

export const Title = styled.h1`
  ${(props: any) => props.theme.texts.heading1};
  color: ${(props: any) => props.theme.global10};
`
