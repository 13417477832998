import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'

import { ModalOverlay, ModalWindow, ModalBase } from './modal.styles'

export interface ModalProps {
  className?: string
  overlayOff?: boolean
  isShown: boolean
  hideKey?: boolean
  hide?: () => void
  children?: React.ReactNode
}

export const Modal = ({
  className,
  overlayOff = false,
  isShown,
  hide,
  hideKey = false,
  children
}: ModalProps) => {

  const customHide = () => {
    document.body.style.overflow = "auto"
    if (hide) hide()
  }

  const modal = (
    <ModalBase data-testid="modal-base">
      <ModalOverlay
        data-testid="modal-overlay"
        onClick={() => {
          if (overlayOff) return
          customHide()
        }}
      />
      <ModalWindow className={className} role="dialog">
        {children}
      </ModalWindow>
    </ModalBase>
  )

  const onKeyDown = (event: KeyboardEvent) => {
    isShown ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'unset')
    if (event.key === "escape" && isShown && hideKey) {
      customHide()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, false)
    return () => {
      document.removeEventListener('keydown', onKeyDown, false)
    }
  })

  return isShown ? ReactDOM.createPortal(modal, document.body) : null
}

Modal.displayName = 'Modal'
