import { Pill } from "components/pill"

type ClassCondition = { [key: string]: boolean | undefined }
type ClassType = undefined | string | string[] | ClassCondition

export const APIURL = process.env.REACT_APP_API_URL
export const STAC_API_URL = process.env.REACT_APP_STAC_API_URL
export const MOCKAPIURL = process.env.REACT_APP_MOCK_API_URL

export const classes = (...args: ClassType[]): string => {
  const classes = []

  for (let i = 0; i < args.length; i++) {
    const arg = args[i]
    if (!arg) continue

    const argType = typeof arg

    if (argType === 'string' || argType === 'number') {
      classes.push(arg)
    } else if (argType === 'object') {
      for (const key of Object.keys(arg)) {
        if ((arg as ClassCondition)[key]) {
          classes.push(key)
        }
      }
    }
  }
  return classes.join(' ')
}

export const isTextValid = (text: string, type: string): boolean => {
  switch (type) {
    case 'email':
      return /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(text)
        ? true
        : false;
    case 'password':
      // validate password maybe?
      return true;
    case 'text':
    default:
      return text !== '' ? true : false
  }
}

export const lettersNumbersRegex = /^\b(?!.*?\s{2})[A-Za-z 0-9]{1,50}\b$/

export const onlyLettersRegex= /^(?=.{1,30}$)[A-Za-z]+(?:\s[A-Za-z]+)?$/

export const oneLetterRegex = /^[a-zA-Z]$/

export const equalArrays = (a: Array<any>, b: Array<any>) => {
  if (a.length !== b.length) return false;
  const uniqueValues: any = new Set([...a, ...b]);
  for (const v of uniqueValues) {
    const aCount = a.filter(e => e.value === v.value).length;
    const bCount = b.filter(e => e.value === v.value).length;
    if (aCount !== bCount) return false;
  }
  return true;
};

export const transformDateTime = (input: string): string => {
  const date = new Date(input);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};

export const renderDateCell = ({ cellData }: { cellData?: any }) => {
  if (!cellData) return '';
  return <div style={{ display: "flex", justifyContent: "center" }}>{transformDateTime(cellData)}</div>
};

interface ListItem {
  id: string | number;
  label: string;
}
export const renderListAsPill = ({ data }: { data?: ListItem[] }) => {
  return <div style={{ display: "flex", justifyContent: "center" }}>
      {data?.map((element: ListItem) => {                    
          return <Pill key={element.id} label={element.label} />          
      })}
  </div>
};