import { useSelector } from 'react-redux'

import { Loader } from "components/loader"

import type { RootState } from 'store'
import { NoScopesLanding } from "views/noScopesLanding"

interface ScopedViewProps {
  view: JSX.Element
  scopes: string[]
}

export const ScopedView = ({ scopes, view }: ScopedViewProps) => {
  const userScopes = useSelector((state: RootState) => state.userStore.scopes)

  return !userScopes ? (
    <Loader />
  ) : (
    scopes.find(scope => userScopes.includes(scope)) ? view : <NoScopesLanding />
  )
}
