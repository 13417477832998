import React from 'react'

import { Container } from './mainContainer.styles'

interface Props {
  children: React.ReactNode
}

export const MainContainer = ({ children }: Props) => {
  return (

    <Container>
      <div style={{ width: "100%", height: "100%" }}>
        {children}
        <div style={{ width:"100%", textAlign:"center"}}>
          <h3 style={{color: "#000000" }}> {process.env.REACT_APP_VERSION || 'No version information'}</h3>
        </div>
      </div>
    </Container>



  )
}

MainContainer.displayName = 'MainContainer'
