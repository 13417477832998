import { Link, useRouteError } from 'react-router-dom'



import { ErrorPageContainer } from './errorPage.styles'

export const ErrorPage = () => {
  const error: any = useRouteError()

  return (
    <ErrorPageContainer>
      Page not found
      {JSON.stringify(error)}
      <Link to={'/'}> Back to console main page</Link>    
    </ErrorPageContainer>
  )
}
