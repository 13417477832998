import styled from '@emotion/styled/macro'


export const InputWrapper = styled.div`
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: all 0.2s ease-in-out;
  position: relative;
  &.warning {
    background-color: rgba(253, 226, 109, 0.9);
  }
  &.open {
    height: 35px;
    padding: 10px 0;
    opacity: 1;
  }
  svg:not(.custom-select svg) {
    width: ${(props: any) => props.theme.spacing.large15};
    color: ${(props: any) => props.theme.body};
    transition: all 0.2s ease-in-out;
    position: absolute;
    top: ${(props: any) => props.theme.spacing.medium};
    right: ${(props: any) => props.theme.spacing.large10};
  }
  .togglerContainer {
    width: ${(props: any) => props.theme.spacing.large15};
    height: ${(props: any) => props.theme.spacing.large15};
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    transform: translateY(-75%);
    position: absolute;
    top: 50%;
    right: 10px;
    svg {
      width: 20px;
      height: 20px;
      color: ${(props: any) => props.theme.body};
      position: relative;
      transition: stroke 0.2s ease-in-out;
      position: relative;
      top: 0;
      right: 0;
    }
    &.disabled {
      pointer-events: none;
      svg {
        color: ${(props: any) => props.theme.grayscale40};
      }
    }
  }
`

export const ErrorText = styled.span`
  ${(props: any) => props.theme.texts.small};
  color: ${(props: any) => props.theme.error};
  display: block;
  margin-left: ${(props: any) => props.theme.spacing.large10};
`

export const HelperText = styled.span`
  ${(props: any) => props.theme.texts.small};
  color: ${(props: any) => props.theme.bodyGray};
  display: block;
  margin-left: ${(props: any) => props.theme.spacing.large10};
`

export const BottomText = styled.div`
  opacity: 0;
  position: absolute;
  transition: all 0.2s ease-in-out;
  visibility: hidden;
  top: 100%;
  &.shown {
    width: 100%;
    opacity: 1;
    visibility: visible;
  }
`

export const Label = styled.label`
  ${(props: any) => props.theme.texts.body};
  color: ${(props: any) => props.theme.body};
  margin: auto;
  padding: 0 2px;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  left: ${(props: any) => props.theme.spacing.large10};
  z-index: 1;
  &::before {
    width: 100%;
    height: 2px;
    background-color: transparent;
    content: ' ';
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
  }
  &.small {
    ${(props: any) => props.theme.texts.body2};
  }
`

export const ClearButton = styled.button`
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
`;

export const DurationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;  
  margin-bottom: ${(props: any) => props.theme.spacing.small}; /* Space between input groups */
`;

export const DurationInputForm = styled.input`
  border: 1px solid ${(props: any) => props.theme.grayscale20};
  border-radius: ${(props: any) => props.theme.radius.small};
  padding: ${(props: any) => props.theme.spacing.small};
  font-size: 1rem;
  width: 50px;
  height: ${(props: any) => props.theme.spacing.large25};  
  text-align: center;
  box-sizing: border-box;
  margin-bottom: ${(props: any) => props.theme.spacing.small}; /* Space between inputs */
  margin-left: 5px;
`;

export const DurationLabel = styled.label`
  display: flex;
  justify-content: center;  /* Centers content horizontally */
  align-items: center;      /* Centers content vertically */
  margin-bottom: ${(props: any) => props.theme.spacing.xsmall}; /* Space between label and input */
  height: ${(props: any) => props.theme.spacing.large25};  
  font-size: 1rem;
  padding-right: 10px;    
`;


export const InputStyled = styled.input`
  ${(props: any) => props.theme.texts.body};
  width: 100%;
  height: ${(props: any) => props.theme.spacing.large25};
  background-color: ${(props: any) => props.theme.secondary10};
  border: 1px solid ${(props: any) => props.theme.grayscale20};
  border-radius: ${(props: any) => props.theme.radius.medium};
  color: ${(props: any) => props.theme.body};
  outline: none;
  padding: ${(props: any) => props.theme.spacing.medium} ${(props: any) => props.theme.spacing.large10};
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: ${(props: any) => props.theme.secondary30};
    border-color: ${(props: any) => props.theme.primary10};
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &.active {
    background-color: ${(props: any) => props.theme.secondary10};
    border-color: ${(props: any) => props.theme.primary10};
    outline: none;
    & + ${Label} {
      ${(props: any) => props.theme.texts.label};
      color: ${(props: any) => props.theme.primary10};
      font-weight: 600;
      transform: translateY(-25.5px);
      left: ${(props: any) => props.theme.spacing.large15};
      &::before {
        background-color: ${(props: any) => props.theme.base10};
      }
    }
    &:hover {
      background-color: ${(props: any) => props.theme.secondary20};
      border-color: ${(props: any) => props.theme.primary20};
    }
    &.error {
      & + ${Label} {
        color: ${(props: any) => props.theme.error};
      }
    }
    &.password {
      //padding-right: 40px;
    }
  }
  &:focus {
    background-color: ${(props: any) => props.theme.secondary20};
    border-color: ${(props: any) => props.theme.primary10};
    outline: none;
    & + ${Label} {
      ${(props: any) => props.theme.texts.label};
      color: ${(props: any) => props.theme.primary10};
      font-weight: 600;
      transform: translateY(-25.5px);
      left: ${(props: any) => props.theme.spacing.large15};
      &::before {
        background-color: ${(props: any) => props.theme.base10};
      }
    }
    &.error {
      & + ${Label} {
        color: ${(props: any) => props.theme.error};
      }
    }
  }
  &.error {
    border-color: ${(props: any) => props.theme.error};
    &:hover {
      border-color: ${(props: any) => props.theme.error};
    }
  }
  &:disabled,
  &.disabled {
    background-color: ${(props: any) => props.theme.base10};
    border-color: ${(props: any) => props.theme.grayscale40};
    box-shadow: ${(props: any) => props.theme.shadow.large} transparent;
    color: ${(props: any) => props.theme.grayscale40};
    pointer-events: none;
    & + ${Label} {
      color: ${(props: any) => props.theme.grayscale40};
    }
    &.active {
      & + ${Label} {
        ${(props: any) => props.theme.texts.label};
        color: ${(props: any) => props.theme.grayscale40};
        font-weight: 600;
        transform: translateY(-25.5px);
        &::before {
          background-color: ${(props: any) => props.theme.base10};
        }
      }
      &.error {
        & + ${Label} {
          color: ${(props: any) => props.theme.error};
        }
      }
    }
    &.with--icon {
      & + svg {
        color: ${(props: any) => props.theme.grayscale40};
        & + ${Label} {
          color: ${(props: any) => props.theme.grayscale40};
        }
      }
      &.active {
        & + svg {
          color: ${(props: any) => props.theme.grayscale40};
          & + ${Label} {
            ${(props: any) => props.theme.texts.label};
            color: ${(props: any) => props.theme.grayscale40};
            font-weight: 600;
            transform: translateY(-25.5px);
            &::before {
              background-color: ${(props: any) => props.theme.base10};
            }
          }
        }
      }
    }
  }
  &.with--icon {
    padding-right: ${(props: any) => props.theme.spacing.large25};
    &.active {
      & + svg {
        color: ${(props: any) => props.theme.primary10};
        & + ${Label} {
          ${(props: any) => props.theme.texts.label};
          color: ${(props: any) => props.theme.primary10};
          font-weight: 600;
          transform: translateY(-25.5px);
          &::before {
            background-color: ${(props: any) => props.theme.base10};
          }
        }
      }
    }
  }
  &.small {
    ${(props: any) => props.theme.texts.body2};
    height: ${(props: any) => props.theme.spacing.large20};
    &.active,
    &:focus {
      & + ${Label} {
        transform: translateY(-21.5px);
      }
      &.with--icon {
        & + svg {
          & + ${Label} {
            transform: translateY(-21.5px);
          }
        }
      }
    }
    &.with--icon {
      & + svg {
        width: 20px;
        top: 6px;
        right: ${(props: any) => props.theme.spacing.large10};
      }
      &.active,
      &:focus {
        & + svg {
          color: ${(props: any) => props.theme.primary10};
          & + ${Label} {
            ${(props: any) => props.theme.texts.label};
            color: ${(props: any) => props.theme.primary10};
            font-weight: 600;
            transform: translateY(-21.5px);
            &::before {
              background-color: ${(props: any) => props.theme.base10};
            }
          }
        }
        &.disabled {
          & + svg {
            color: ${(props: any) => props.theme.grayscale40};
            & + ${Label} {
              color: ${(props: any) => props.theme.grayscale40};
            }
          }
        }
      }
    }
  }
`

