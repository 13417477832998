import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import type { RootState } from "store";
import { TableCRUD } from "components/tableCRUD"


import { EyeIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { renderDateCell } from "config/helpers";
import { ContentWrapper, EmptyStateWrapper } from "views/views.styles";
import { refreshTrigger } from "stores/globalStore/globalStoreSlice";
import { Contract } from "typings";
import { EmptyState } from "components/emptyState";
import { createOnboardingRule, deleteOnboardingRule } from "services/account-service/onboardingRulesServices";


export const OnBoardingRules = () => {

    const onboarding_rules = useSelector((state: RootState) => state.userStore.onboardingRules);

    const roles = useSelector((state: RootState) => state.userStore.roles);
    const contracts = useSelector((state: RootState) => state.userStore.contracts);
    const active_account = useSelector((state: RootState) => state.userStore.active_account);

    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const handleCreateOnboardingRule = async (data: any): Promise<{ success: boolean; message: string }> => {
        const token = await getAccessTokenSilently();
        try {
            const augmented_data = {
                ...data,
                account: active_account?.account_id
            }
            await createOnboardingRule(token, augmented_data);
            dispatch(refreshTrigger());
            return {
                success: true,
                message: `Onboarding rule ${data.name} created successfully`
            };
        } catch (error: any) {
            return {
                success: false,
                message: error.response?.data?.message + " " + JSON.stringify(error.response?.data?.errors) || 'Failed to create onboarding rule',
            };
        }
    };

    const handleDeleteOnboardingRule = async (item: any): Promise<{ success: boolean; message: string }> => {
        const token = await getAccessTokenSilently();
        const ruleID = item.onboarding_rule_id;
        dispatch(refreshTrigger());
        try {
            await deleteOnboardingRule(token, ruleID);
            return {
                success: true,
                message: `Rule ${ruleID} deleted successfully`
            };
        } catch (error: any) {
            return {
                success: false,
                message: "Error deleting: " + error.response?.data?.message + " " + JSON.stringify(error.response?.data?.errors) || 'Failed to delete rule',
            };
        }
    };

    const roleOptions = roles ? Object.entries(roles).map(([key, role]) => ({
        value: role.role_id,
        label: role.name,
    })) : undefined;

    const contractOptions = contracts ? contracts.map((contract: Contract) => ({
        value: contract.contract_id,
        label: contract.name,
    })) : undefined;


    return (

        (!!active_account ?
            <ContentWrapper full>
                <TableCRUD
                    data={{ items: onboarding_rules ? onboarding_rules : [], total: onboarding_rules ? onboarding_rules?.length : 999 }}
                    title={"On boarding rules"}
                    columns={[
                        { label: "Name", dataKey: "name" },
                        { label: "ID", dataKey: "onboarding_rule_id" },
                        { label: "Created", dataKey: "created_at", renderer: renderDateCell },
                        { label: "Last Updated", dataKey: "updated_at", renderer: renderDateCell },
                    ]}

                    idKey="onboarding_rule_id"

                    actions={{
                        create: {
                            actionHandler: handleCreateOnboardingRule,
                            fields: [
                                {
                                    name: 'name',
                                    label: 'Name',
                                    type: 'text',
                                    validation: { required: true, minLength: 3, validator: "lettersNumbers", message: 'Name is required' },
                                },
                                {
                                    name: 'email_domain',
                                    label: 'Email doman',
                                    type: 'text',
                                    validation: { required: true, message: 'Email domain is required' },
                                },
                                {
                                    name: 'emails',
                                    label: 'Specific emails',
                                    type: 'text',
                                    //validation: { required: true, minLength: 3, validator:"lettersNumbers", message: 'Name is required' },
                                },
                                {
                                    name: 'roles',
                                    label: 'Assign roles',
                                    type: 'list',
                                    validation: { options: roleOptions },
                                    //validation: { required: true, minLength: 3, validator:"lettersNumbers", message: 'Name is required' },
                                },
                                {
                                    name: 'contracts',
                                    label: 'Assign contracts',
                                    type: 'list',
                                    validation: { options: contractOptions },
                                    //validation: { required: true, minLength: 3, validator:"lettersNumbers", message: 'Name is required' },
                                },
                            ]
                        },

                        view: {
                            icon: <EyeIcon />,
                            actionHandler: (item: any) => {
                                navigate(`/onboardingrules/${item.onboarding_rule_id}`)
                            }
                        },

                        edit: {
                            icon: <PencilSquareIcon />,
                            actionHandler: (item: any) => {
                                navigate(`/onboardingrules/${item.onboarding_rule_id}?edit=true`)
                            }
                        },

                        
                        delete: {
                            icon: <TrashIcon />,
                            tooltip: "Delete rule",
                            confirm: true,
                            confirmationText: "Are you sure you want to delete the rule: {name} ({id})?",
                            actionHandler: (item: any) => { return handleDeleteOnboardingRule(item) }
                        },
                    }}


                />

            </ContentWrapper>
            :
            <EmptyStateWrapper>
                <EmptyState
                    imgUrl="/assets/empty-state.svg"
                    text="Please select an organization first"
                    s-ll
                />
            </EmptyStateWrapper>
        )
    )
}