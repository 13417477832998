
import { useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { polygonSchema } from "./geojsonschema";

import {
    ContentWrapper,
    Table,
    TableRow,
    TableColumn,
    ModalContainer,
    LoadingWrapper,
    TableWrapper,
    Title,
} from 'views/views.styles'

import "react-sliding-pane/dist/react-sliding-pane.css";
import { Button } from 'components/button';
import { addNotification } from 'stores/notificationsStore/notificationsStoreSlice';
import { Modal } from 'components/modal';
import { Loader } from 'components/loader';
import { Restriction } from 'typings';
import { transformDateTime } from 'config/helpers';
import { Input } from 'components/input';
import { getRestriction, updateRestriction } from 'services/account-service/restrictionsServices';
import { FieldError } from 'components/input/input';
import { Duration } from 'luxon';
import CodeEditor from 'components/input/codeeditor';

export const RestrictionPage = () => {

    const { restriction_id } = useParams();
    const dispatch = useDispatch()
    
    const [showLoading, setShowLoading] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const isEditMode = searchParams.get('edit') === 'true'; // Check if 'edit' param is present and set to 'true'
    const [restriction, setRestriction] = useState<Restriction | undefined>(undefined);
    const [errors, setErrors] = useState<{ [key: string]: FieldError }>({});

    const [formData, setFormData] = useState({
        name: '',
        geometry: {},
        restricted_time_window: ''
    });

    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {

        const fetchRestriction = async () => {

            if (!!restriction_id) {
                const token = await getAccessTokenSilently();

                setShowLoading(true);

                getRestriction(token, restriction_id,
                    (response) => {

                        const restriction = response.data;

                        setRestriction(restriction);
                        setFormData(restriction);
                        setShowLoading(false);
                    },
                    (error) => {
                        //console.error("Cannot load restriction", error),
                        dispatch(addNotification({ type: 'error', description: error.response.data.details }));
                        setShowLoading(false);
                    }
                )
            }
        }

        fetchRestriction();

    }, [getAccessTokenSilently, dispatch, restriction_id])

    const handleSubmit = async () => {


        const transformedData = {
            restricted_time_window: formData.restricted_time_window,
            name: formData.name,
            geometry: JSON.parse(formData.geometry as string),
        };

        const token = await getAccessTokenSilently();
        if (restriction) {
            try {
                setShowLoading(true);
                await updateRestriction(token, restriction?.restriction_id, transformedData)
                dispatch(addNotification({ type: 'success', description: "Restriction updated succesfully" }));

                setSearchParams({}); // Remove the 'edit' parameter to exit edit mode

            } catch (error: any) {
                console.log(error)
                dispatch(addNotification({ type: 'error', description: "Error updating: " + error.response?.data?.message + " " + JSON.stringify(error.response?.data?.errors) || 'Failed to update account', }));
            } finally {
                setShowLoading(false);
            }
        }
    };

    // Handle input change
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isEditMode) {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            });
        }
    };

    // Handle cancel action
    const handleCancel = () => {
        setSearchParams({}); // Remove the 'edit' parameter to exit edit mode        
        if (restriction) {
            setFormData(restriction);
        }
    };

    // Toggle edit mode by modifying query parameters
    const toggleEditMode = () => {

        if (isEditMode) {
            setSearchParams({}); // Remove 'edit' parameter to exit edit mode            
        } else {
            setSearchParams({ edit: 'true' }); // Add 'edit=true' to enter edit mode            
            if (!!restriction) {
                setFormData({
                    name: restriction.name,
                    geometry: JSON.stringify(restriction.geometry, null, 2),
                    restricted_time_window: restriction.restricted_time_window
                })
            }
        }
    };

        
    return (
        <>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <Link to={`/restrictions`}>← Back to restrictions</Link>

                {!!restriction &&
                    <>
                        <ContentWrapper full>
                            <TableWrapper>
                                <Table style={{ tableLayout: "fixed" }}>
                                    <TableRow>
                                        <TableColumn width={'200px'}>
                                            <b>Name</b>
                                        </TableColumn>
                                        <TableColumn>
                                            {!isEditMode ? (
                                                restriction.name
                                            ) : (
                                                <Input
                                                    minLength={3}
                                                    required
                                                    name={'name'}
                                                    onChange={handleInputChange}
                                                    helperText='Name for the restriction'
                                                    value={formData.name}
                                                    onValidate={(error) => {
                                                        setErrors((prevErrors) => ({ ...prevErrors, name: error }))
                                                    }}
                                                />
                                            )}
                                        </TableColumn>
                                    </TableRow>
                                    <TableRow>
                                        <TableColumn>
                                            <b>Restriction ID</b>
                                        </TableColumn>
                                        <TableColumn>{restriction?.restriction_id}</TableColumn>
                                    </TableRow>
                                    <TableRow>
                                        <TableColumn>
                                            <b>Created</b>
                                        </TableColumn>
                                        <TableColumn>
                                            {restriction.created_at ? transformDateTime(restriction?.created_at) : ""}
                                        </TableColumn>
                                    </TableRow>
                                    <TableRow>
                                        <TableColumn>
                                            <b>Last modified</b>
                                        </TableColumn>
                                        <TableColumn>
                                            {restriction.updated_at ? transformDateTime(restriction?.updated_at) : ""}
                                        </TableColumn>
                                    </TableRow>
                                    <TableRow>
                                        <TableColumn>
                                            <b>Data Range exclusion</b>
                                        </TableColumn>
                                        <TableColumn>
                                            {!isEditMode ? (
                                                restriction.restricted_time_window ? Duration.fromISO(restriction.restricted_time_window).toHuman() : "None"
                                            ) : (
                                                <Input
                                                    type="timedelta"
                                                    helperText='Specify the dates before which data cannot be accessed. As a TBD format'
                                                    value={formData.restricted_time_window}
                                                    onChange={handleInputChange}
                                                    name={'restricted_time_window'}
                                                    onValidate={(error) => {
                                                        setErrors((prevErrors) => ({ ...prevErrors, restricted_time_window: error }))
                                                    }}
                                                />
                                            )}
                                        </TableColumn>
                                    </TableRow>
                                    <TableRow>
                                        <TableColumn>
                                            <b>Exclusion zones</b>
                                        </TableColumn>
                                        <TableColumn>
                                            <Input
                                                name={'geometry'}
                                                type="json"
                                                disabled={!isEditMode}
                                                value={formData.geometry}
                                                onChange={handleInputChange}
                                                validator="geoJSON"
                                                schema={polygonSchema}
                                                onValidate={(error) => {
                                                    setErrors((prevErrors) => ({ ...prevErrors, geometry: error }))
                                                }}
                                            />
                                        </TableColumn>
                                    </TableRow>
                                </Table>
                            </TableWrapper>


                            <div style={{ display: 'flex', gap: '10px', margin: '10px' }}>
                                {!isEditMode ? (
                                    <Button
                                        size="sm"
                                        text="Edit"
                                        onClick={toggleEditMode}
                                    />
                                ) : (
                                    <>
                                        <Button
                                            size="sm"
                                            text="Save"
                                            onClick={handleSubmit}
                                            disabled={!Object.values(errors).every((fieldError) => fieldError.isValid)}
                                        />
                                        <Button
                                            size="sm"
                                            text="Cancel"
                                            onClick={handleCancel}
                                        />
                                    </>
                                )}
                            </div>
                        </ContentWrapper>

                        <ContentWrapper full>
                            <Title>
                                Rendered STAC filter                                
                            </Title>   
                            <div style={{padding:"10px"}}>
                            <CodeEditor                            
                                editable={false}
                                value={JSON.stringify(restriction.stac_filter, null, 2)}
                            />
                            </div>                         
                            <label> Note: The render is done when loading this page. Values can change over time.</label>
                        </ContentWrapper>

                        <ContentWrapper full>
                            <Title>
                                Contracts using it
                            </Title>
                            TO BE IMPLEMENTED
                        </ContentWrapper>
                    </>
                }
            </div>

            <Modal isShown={showLoading}>
                <ModalContainer>
                    <LoadingWrapper>
                        <Loader text={"Loading data..."} />
                    </LoadingWrapper>
                </ModalContainer>
            </Modal>

        </>
    );
};