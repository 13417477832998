import React, { useState } from 'react'

import {
  TooltipContainer,
  ToggleElement,
  TooltipComponent,
} from './tooltip.styles'

import { classes } from 'config/helpers'

interface TooltipProps {
  className?: string
  content: string | JSX.Element
  disabled?: boolean
  position: string
  small?: boolean
  toggleElement: JSX.Element
  tooltipClassName?: string
}

export const Tooltip = ({
  className,
  content,
  disabled = false,
  position,
  small = false,
  toggleElement,
  tooltipClassName
}: TooltipProps) => {
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <TooltipContainer className={classes(className)}>
      <ToggleElement
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {toggleElement}
      </ToggleElement>

      {showTooltip && <TooltipComponent className={classes(position, tooltipClassName, { show: showTooltip && !disabled, small })}>
        {content}
      </TooltipComponent>}
    </TooltipContainer>
  )
};

Tooltip.displayName = "Tooltip"
