import { createSlice } from '@reduxjs/toolkit'

import { NotificationData } from 'typings'

interface notificationsState {
  notifications: NotificationData[],
  nextId: number
}

const initialState: notificationsState = {
  notifications: [],
  nextId: 0
}

export const notificationsStoreSlice = createSlice({
  name: 'notificationsStore',
  initialState,
  reducers: {
    addNotification: (state, action) => {
      if (state.notifications.length >= 4) state.notifications.pop()
      state.notifications = [...state.notifications, { id: state.nextId, ...action.payload }]
      state.nextId++
    },
    removeNotification: (state, action) => {
      state.notifications = state.notifications.filter(notification => notification.id !== action.payload)
    }
  }
})

export const { addNotification, removeNotification } = notificationsStoreSlice.actions

export default notificationsStoreSlice.reducer
