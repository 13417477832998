import React, { useState } from 'react';
import { UserPlusIcon } from '@heroicons/react/24/outline';
import { Input } from 'components/input';
import { Content } from './newItemModal.styles';
import { Dialog } from 'components/dialog';
import { FieldError, ValidatorType } from 'components/input/input';

// Define validation rules for each field
interface ValidationRule {
  required?: boolean;
  minLength?: number;
  pattern?: RegExp;
  message?: string;
  options?: any;
  validator?: ValidatorType;
  schema?: Object;
}

// Define the structure for form fields with validation
export interface Field {
  name: string;
  label: string;
  type: string;
  validation?: ValidationRule;
}

// Define props for the modal
interface Props {
  fields: Field[];
  isShown: boolean;
  initialData?: { [key: string]: any };  // Prop for initial values
  onClose: () => void;
  onSubmit: (data: any) => void;
}

export const NewItemModal: React.FC<Props> = ({
  fields,
  isShown,
  onClose,  
  onSubmit,
  initialData = {},
}) => {
  const [formData, setFormData] = useState<{ [key: string]: any }>({});  // Form data state
  const [errors, setErrors] = useState<{ [key: string]: FieldError }>({});

  
  
  // Handle changes in form inputs
  const handleChange = (name: string, value: string) => {
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));  // Update formData for specific field    
  };

  // Handle form submission
  const handleSubmit = () => {
    if (Object.values(errors).every((fieldError) => fieldError.isValid)) {
      onSubmit(formData);  // Submit the final formData
      setFormData({});  // Clear formData after successful submission      
    }
  };

  // Handle modal close, reset the form to the initial state
  const handleClose = () => {
    onClose();
    setFormData({});  // Reset form data to an empty object on close
    setErrors({});  // Clear errors    
  };

  return (
    <Dialog
      size= {'large'}
      cancel={{
        text: 'Cancel',        
        action: () => {
          handleClose();  // Call close handler
        },
      }}
      confirmation={{          
        text: 'Submit',
        action: () => handleSubmit(),
      }}
      disableConfirmation= {!Object.values(errors).every((fieldError) => fieldError.isValid)}
      customIcon={<UserPlusIcon />}
      isShown={isShown}
      title="Create new item"
      onClose={handleClose}  // Ensure the modal resets on close
    >
      <Content>

        {fields.map((field) => (
          <Input
            key={field.name}
            className="input"
            label={field.label}
            name={field.name}
            type={field.type}
            minLength={field.validation?.minLength}
            schema={field.validation?.schema}
            validator={field.validation?.validator}
            required={field.validation?.required?field.validation.required:false}       
            value={formData[field.name] || ''}  // Bind input to formData state
            error={errors[field.name]}  // Pass structured error object            
            onChange={(e) => {
              const value = e.currentTarget.value;  // Get the input value
              handleChange(field.name, value);  // Update formData state
            }}
            onValidate={(error) => {              
              setErrors((prevErrors) => ({ ...prevErrors, [field.name]: error }))
            }}
            onBlur={(e) => {
              //const value = e.currentTarget.value;
              //const error = validateField(field, value);  // Validate on blur
              //setErrors((prevErrors) => ({ ...prevErrors, [field.name]: error }));  // Update errors
            }}
            options={field.validation?.options}
          />
        ))}  
      </Content>
    </Dialog>
  );
};