import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { removeNotification } from 'stores/notificationsStore/notificationsStoreSlice'

import { ToastAction } from './toastAction'

import { List } from './toast.styles'

import { NotificationData } from 'typings'

interface Props {
  notifications: NotificationData[]
  onClose: (id: number) => void
}

export const ToastList = ({notifications, onClose}: Props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => {
      dispatch(removeNotification(notifications[notifications.length - 1].id))
    }, 7000)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications])

  return (
    <List>
      {notifications?.map((noti: NotificationData, k: number) =>
        <ToastAction
          description={noti.description}
          type={noti.type}
          key={k}
          onClose={() => { if (noti.id !== undefined) onClose(noti.id) }}
          allowClose={noti.allowClose}
        />
      )}
    </List>
  )
}
