
import { useSelector, useDispatch } from 'react-redux'
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from "react-router-dom";
import type { RootState } from 'store'
import { InputSwitch } from 'components/inputSwitch'
import { useAuth0 } from "@auth0/auth0-react";

import {
    ContentWrapper,
    Table,
    TableRow,
    TableColumn,
    ModalContainer,
    LoadingWrapper,
} from 'views/views.styles'

import "react-sliding-pane/dist/react-sliding-pane.css";
import { Button } from 'components/button';
import { setAccounts, setActiveAccount } from 'stores/userStore/userStoreSlice';
import { activateAccount, deactivateAccount, getAccount, updateAccount } from 'services/account-service/accountServices';
import { addNotification } from 'stores/notificationsStore/notificationsStoreSlice';
import { Modal } from 'components/modal';
import { Loader } from 'components/loader';
import { Organization } from 'typings';
import { getContracts } from 'services/account-service/contractServices';
import { Contracts } from 'views/contracts';
import { Input } from 'components/input';

export const OrganizationPage = () => {

    const { account_id } = useParams();

    const accounts = useSelector((state: RootState) => state.userStore.accounts)


    const active_account = useSelector((state: RootState) => state.userStore.active_account);
    const dispatch = useDispatch()


    const [searchParams, setSearchParams] = useSearchParams();
    const isEditMode = searchParams.get('edit') === 'true'; // Check if 'edit' param is present and set to 'true'

    const [nameIsValid, setNameIsValid] = useState<boolean>(false);
    

    const [account, setAccount] = useState<Organization | undefined>(undefined)
    const [relatedContracts, setRelatedContracts] = useState<any[] | undefined>(undefined)

    const [formData, setFormData] = useState({
        name: '',
        status: false,
    });

    const [showLoading, setShowLoading] = useState(false);

    const { getAccessTokenSilently } = useAuth0();

    const loadAccountInfo = useCallback(async () => {
        if (account_id) {
            const token = await getAccessTokenSilently();

            getAccount(token, account_id,
                (response) => {
                    setAccount(response.data)
                    setFormData(response.data)
                },
                (error) => { dispatch(addNotification({ type: 'error', description: "Cannot load account information " + error })); }
            )            
            getContracts(token, account_id,
                100,
                0,
                (response) => {
                    setRelatedContracts(response.data.results)                    
                },
                (error) => { dispatch(addNotification({ type: 'error', description: "Cannot load related contracts " + error })); }
            )
        }
    }, [account_id, getAccessTokenSilently, dispatch])

    useEffect(() => {
        loadAccountInfo();
    }, [loadAccountInfo]);


    //FIXME: Dont remove this!, it will render the form blank, no idea why
    useEffect(() => {
    }, [formData])

    // Handle input change
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    // Handle switch change separately
    const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.checked,
        });
        return e.target.value === "on"
    };

    // Handle form submission
    const handleSubmit = async () => {

        if (account?.account_id) {

            const token = await getAccessTokenSilently();
            const transformedData = {
                ...formData,
                status: formData.status ? 'ACTIVE' : 'INACTIVE',
            };

            try {
                setShowLoading(true);
                await updateAccount(token, account?.account_id, transformedData);

                const updatedAccounts = accounts?.map(acc =>
                    acc.account_id === account.account_id ? { ...acc, ...transformedData } : acc
                );
                dispatch(setAccounts(updatedAccounts));

                /* Status update */
                try {
                    if (account_id && account.status === "ACTIVE" && transformedData.status === "INACTIVE") await deactivateAccount(token, account_id);
                    if (account_id && account.status === "INACTIVE" && transformedData.status === "ACTIVE") await activateAccount(token, account_id);

                    dispatch(addNotification({ type: 'success', description: "Account status updated succesfully" }));
                } catch (error: any) {
                    console.log(error)
                    dispatch(addNotification({ type: 'error', description: "Error updating user status: " + error.response?.data?.message + " " + JSON.stringify(error.response?.data?.errors), }));
                }

                if (active_account?.account_id === account_id) {
                    dispatch(setActiveAccount({ ...account, ...transformedData }));
                }
                setSearchParams({}); // Remove the 'edit' parameter to exit edit mode
                loadAccountInfo();
                dispatch(addNotification({ type: 'success', description: "Account updated succesfully" }));
            } catch (error: any) {
                console.log(error)
                dispatch(addNotification({ type: 'error', description: "Error updating: " + error.response?.data?.message + " " + JSON.stringify(error.response?.data?.errors) || 'Failed to update account', }));
            } finally {
                setShowLoading(false);
            }
        }
    };

    // Handle cancel action
    const handleCancel = () => {
        setFormData({
            name: account?.name || '',
            status: account?.status === 'ACTIVE',
        });
        setSearchParams({}); // Remove the 'edit' parameter to exit edit mode
    };

    // Toggle edit mode by modifying query parameters
    const toggleEditMode = () => {
        if (isEditMode) {
            setSearchParams({}); // Remove 'edit' parameter to exit edit mode
        } else {
            setSearchParams({ edit: 'true' }); // Add 'edit=true' to enter edit mode
        }
    };
    

    return (
        <>

            <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "20px", }}>

                <Link to={`/accounts/`}>← Back to accounts</Link>

                <ContentWrapper full>

                    {account_id !== active_account?.account_id && (
                        <Button
                            size="sm"
                            text="Set as active account"
                            onClick={() => {
                                dispatch(setActiveAccount(account));
                            }}
                        />
                    )}
                    <Table>
                        <TableRow>
                            <TableColumn width={'100px'}>
                                <b>Account id</b>
                            </TableColumn>
                            <TableColumn>{account?.account_id}</TableColumn>
                        </TableRow>

                        <TableRow>
                            <TableColumn width={'100px'}>
                                <b>Account name</b>
                            </TableColumn>
                            <TableColumn>
                                {isEditMode ? (
                                    <Input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        validate
                                        minLength={3}
                                        validator="lettersNumbers"
                                        onValidate={(error) => setNameIsValid(error.isValid)}
                                    />
                                ) : (
                                    account?.name
                                )}
                            </TableColumn>
                        </TableRow>

                        <TableRow>
                            <TableColumn width={'100px'}>
                                <b>Active</b>
                            </TableColumn>
                            <TableColumn>
                                {isEditMode ? (
                                    <InputSwitch
                                        checked={formData.status}
                                        name="status"
                                        small
                                        onChange={handleSwitchChange}
                                    />
                                ) : (
                                    account?.status
                                )}
                            </TableColumn>
                        </TableRow>


                        <TableRow>
                            <TableColumn width={'100px'}>
                                <b>Created</b>
                            </TableColumn>
                            <TableColumn>
                                {account?.created_at}
                            </TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn width={'100px'}>
                                <b>Last modified</b>
                            </TableColumn>
                            <TableColumn>
                                {account?.updated_at}
                            </TableColumn>
                        </TableRow>
                    </Table>

                    <ContentWrapper style={{ height: "500px" }} full>
                        {relatedContracts && <Contracts fixedContracts={relatedContracts} />}
                    </ContentWrapper>                    
                                    
                <div style={{ display: 'flex', gap: '10px', margin: '10px' }}>

                    {!isEditMode ? (
                        <Button
                            size="sm"
                            text="Edit"
                            onClick={toggleEditMode}
                        />
                    ) : (
                        
                            <>
                                <Button
                                    disabled={!nameIsValid}
                                    size="sm"
                                    text="Save"                                    
                                    onClick={handleSubmit}
                                />
                                <Button
                                    size="sm"
                                    text="Cancel"
                                    onClick={handleCancel}
                                />
                            </>
                        )}
                    </div>
                </ContentWrapper>
            </div >

            <Modal isShown={showLoading}>
                <ModalContainer>
                    <LoadingWrapper>
                        <Loader text={"Executing operation..."} />
                    </LoadingWrapper>
                </ModalContainer>
            </Modal>
        </>
    );
};