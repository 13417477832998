import { useEffect, useState, ReactNode, ReactElement } from "react";
import { useDispatch } from "react-redux";
import { Column, Table, AutoSizer } from "react-virtualized";
import { addNotification } from "stores/notificationsStore/notificationsStoreSlice";
import { CheckIcon, DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { classes } from "config/helpers";
import { useMergeState } from "config/hooks";

import { Tooltip } from 'components/tooltip';
import { EmptyState } from "components/emptyState";
import { Loader } from "components/loader";
import { Dialog } from 'components/dialog';
import { Modal } from 'components/modal';
import { Button } from "components/button";
import { Checkbox } from "components/checkbox";

import { Field, NewItemModal } from "./newItemModal";

import {
  TitleWrapper,
  Title,
  LoadingWrapper,
  ModalContainer,
  EmptyStateWrapper,
  SearchWrapper,
  SearchInput,
  TableWrapper,
  ButtonsWrapper,
  ActionButtons,
} from "views/views.styles";
import { calculateColumnWidths, renderTemplate, sortItems } from "./helpers";

type Action = {
  confirm?: boolean;
  confirmTitle?: string;
  tooltip?: string;
  confirmationText?: string;
  icon?: ReactElement;
  fields?: Field[]; // used for create and edit only
  actionHandler?: (item?: any) => Promise<{ success: boolean; message: string }> | void;
  enabled?: (item?: any) => boolean;
};

interface TableCRUDProps {
  title: string;
  onView?: () => void; 
  onEdit?: () => void; 
  onSelect?: (data: any) => void;
  data?: { items: any[], total: number } | undefined;
  isSelected?: (id: any) => boolean;
  
  rowStyle?: any | undefined;
  actions: { [key: string]: Action };
  idKey?: string;
  columns: any[];
}

export const TableCRUD: React.FC<TableCRUDProps> = ({
  idKey = "id",
  title,
  onSelect,
  data,
  rowStyle,
  actions,
  isSelected,
  columns,  
}) => {
  type ActionKeys = keyof typeof actions; // Create a type based on the keys of `actions`

  const initialState = {
    click: false,
    loading: false,
    error: false,
    create: false,
    ...Object.fromEntries(actions ? Object.keys(actions).map(key => [key, false]) : [])
  };

  const [showModal, setShowModal] = useMergeState(initialState);

  const [filteredItems, setFilteredItems] = useState<any[] | undefined>(undefined);
  const [paginatedItems, setPaginatedItems] = useState<any[]>([]);
  
  const [errorMessage, setErrorMessage] = useState<ReactNode | string>('');
  const [actionItem, setActionItem] = useState<any | undefined>(undefined);
  
  const [searchTerm, setSearchTerm] = useState("");
  const [idCopied, setIdCopied] = useState<string | undefined>('');
  const [action, setAction] = useState<ActionKeys>('');
  const [sortBy, setSortBy] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<"ASC" | "DESC">("ASC");

  const [showActive, setShowActive] = useState(true);
  const [showInactive, setShowInactive] = useState(true);

  const dispatch = useDispatch();

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const rowHeight = 40;

  const handleSort = ({ sortBy: newSortBy }: { sortBy: string }) => {
    if (newSortBy === sortBy) {
      // Toggle sort direction if the same column is clicked
      setSortDirection((prevDirection) => (prevDirection === "ASC" ? "DESC" : "ASC"));
    } else {
      // Set new sort column and reset direction to ASC
      setSortBy(newSortBy);
      setSortDirection("ASC");
    }
  };

  useEffect(() => {    
    if (!!data){      
      // Check if both "active" and "inactive" filters are unchecked
      if (!showActive && !showInactive) {
        setFilteredItems([]);
        setPaginatedItems([]);
        return;
      }
      // Apply filtering based on name and status
      let filtered = data.items.filter(item => {
        const matchesSearchTerm = item.name.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesStatus = (showActive && item.status === 'ACTIVE') || (showInactive && item.status === 'INACTIVE');
        return matchesSearchTerm && matchesStatus;
      });

      // Apply sorting
      filtered = sortItems(filtered || [], sortBy, sortDirection);

      // Update the filteredItems state with the total filtered items
      setFilteredItems(filtered);
      
      // Calculate the total number of pages
      const totalPages = Math.ceil(filtered.length / itemsPerPage);
    
      if (totalPages === 0){        
        setPaginatedItems([]);
        setCurrentPage(1);
      }else if (currentPage > totalPages) { // If the current page is greater than the total number of pages, set it to the last page
        setCurrentPage(totalPages);
      } else {
        // Apply pagination
        const paginated = filtered.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
        setPaginatedItems(paginated);        
      }
      
    }
  }, [data, searchTerm, currentPage, sortBy, sortDirection, itemsPerPage, showActive, showInactive]);

  const capitalizeFirstLetter = (str: string) => str ? `${str[0].toUpperCase()}${str.slice(1)}` : str;

  const renderAccountSelection = ({ cellData }: { cellData?: any }) => {
    if (onSelect && filteredItems) {
      const selectedAccount = filteredItems.find(
        (x) => x[idKey] === cellData
      );

      return (
        <Checkbox
          checked={isSelected ? isSelected(cellData) : false}
          onChange={(selected: any) => {onSelect(selected?selectedAccount:undefined)}}
        />
      );
    }
  };

  const renderActionButtons = ({ cellData, rowData }: { cellData?: any, rowData?: any }) => {
    const { [idKey]: id } = rowData;

    return (
      <ActionButtons>
        <Tooltip
          content={idCopied === id ? 'Copied' : 'Copy ID'}
          position="left"
          key="copy-action-button"
          toggleElement={
            <Button
              className={classes({ copied: idCopied === id })}
              icon={idCopied === id ? <CheckIcon /> : <DocumentDuplicateIcon />}
              onlyIcon
              onlyText
              onClick={() => {
                //@ts-ignore
                navigator.clipboard.writeText(id);
                setIdCopied(id);
                setTimeout(() => {
                  setIdCopied('');
                }, 2500);
              }}
            />
          }
        />

        {!!actions && (
          Object.entries(actions)
            .filter(([action]) => action !== 'create') // Filter out the "create" action, its on a button not the table
            .filter(([_, config]) => {
              // Evaluate the `enabled` function or check for a static enabled value
              if (typeof config.enabled === 'function') {
                return config.enabled(rowData); // Pass the row data to the enabled function
              }
              return config.enabled !== false; // Static enabled check
            })
            .map(([action, config]) => (
              <Tooltip
                content={config.tooltip || capitalizeFirstLetter(action)}
                position="left"
                key={`tooltip-${action}`}
                toggleElement={
                  <Button
                    icon={config.icon ? config.icon : undefined}
                    text={config.icon ? undefined : action}
                    onlyIcon
                    onlyText
                    size="md"
                    onClick={() => {
                      const item = filteredItems?.find((item) => item[idKey] === id);
                      setAction(action);
                      setActionItem(item);
                      if (!config.confirm) {
                        const result = actions[action]?.actionHandler?.(item);
                        if (result instanceof Promise) {
                          setShowModal({ loading: true });
                          result
                            .then(() => {
                              setShowModal({ loading: false });
                              dispatch(addNotification({ type: 'success', description: "successful operation" }));
                            })
                            .catch(error => {
                              setShowModal({ loading: false });
                              console.error(`${action} operation failed: `, error.message);
                              setErrorMessage(error.message);
                              setShowModal({ error: true });
                            });
                        }
                      } else {
                        setShowModal({ [action]: true });
                      }
                    }}
                  />
                } />
            ))
        )}
      </ActionButtons>
    );
  };

  const renderColumns = [
    ...columns,
    { label: "Actions", dataKey: "id", renderer: renderActionButtons, width: 180 }
  ];
  
  return (
    <div style={{height:"100%", display: "flex", flexDirection: "column"}}>
      <TitleWrapper>
        <Title>{title}</Title>
      </TitleWrapper>

      <>

        {
          !!paginatedItems && paginatedItems.length > 0 ? (
            <div> Total: {data?.items.length} / Showing: {paginatedItems?.length} </div>
          ) :
            <div> Total: {data?.items.length} / Showing: {0} </div>
        }

        <div style={{ display: "flex", width: "100%" }}>
          {
            !!data?.items && data.items.length > 0 ? (
              <SearchWrapper style={{ width: "50%" }}>
                <SearchInput
                  type="text"
                  placeholder="Filter by name"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </SearchWrapper>
            ) : null
          }

          {!!data?.items && data.items.length > 0 &&
            <div style={{ display: 'flex', alignItems: 'center', margin: '10px', gap: "10px", width: "70%" }}>
              <Checkbox
                checked={showActive}
                onChange={(e) => setShowActive(e)}
                label="Show Active"
              />

              <Checkbox
                checked={showInactive}
                onChange={(e) => setShowInactive(e)}
                label="Show Inactive"
              />
            </div>
          }
          {Object.keys(actions).includes("create") &&
            <TitleWrapper justify="space-between" >
              <ButtonsWrapper className="users">
                <Button size="sm" text="Create new" onClick={() => setShowModal({ create: true })} />
              </ButtonsWrapper>
            </TitleWrapper>
          }
        </div>
      </>

      {
        !!paginatedItems && paginatedItems.length === 0  ? (
          <EmptyStateWrapper>
            <EmptyState
              imgUrl="/assets/empty-state.svg"
              text="There are no items to show."
              s-ll
            />
          </EmptyStateWrapper>
        ) : null
      }

      {
        paginatedItems && paginatedItems.length > 0  ? (
          <TableWrapper>
            <AutoSizer>
              {({ height, width }) => {
                const columnWidths = calculateColumnWidths(renderColumns, paginatedItems, width);
                setItemsPerPage(Math.ceil(height/rowHeight || 0)-2)

                return (
                  <Table
                    width={width}
                    height={height}
                    headerHeight={40}
                    rowHeight={rowHeight}
                    rowCount={paginatedItems.length}
                    rowGetter={({ index }: { index: number }) => paginatedItems[index]}
                    rowStyle={({ index }: { index: number }) => rowStyle? rowStyle(paginatedItems[index]):undefined}
                    sort={handleSort}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    style={{}}
                  >
                    {!!onSelect ? (<Column width={width * 0.03} label="" dataKey={idKey} cellRenderer={renderAccountSelection}></Column>) : null}

                    {renderColumns
                      .filter((col) => col.condition !== false)
                      .map((col) => (
                        <Column
                          key={col.dataKey}
                          width={col.width || columnWidths[col.dataKey]}
                          label={col.label}
                          dataKey={col.dataKey}
                          cellRenderer={col.renderer}
                          style={col.label==="Actions"?{overflow:"visible"}:{}}
                        />
                      ))}
                  </Table>
                );
              }}
            </AutoSizer>
          </TableWrapper>
        ) : null
      }

      {/* Pagination Controls */}
      {Math.ceil((filteredItems?.length || 0) / itemsPerPage) > 1 &&
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
          <Button
            text="Previous"
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          />
          <span>Page {currentPage} of {Math.ceil((filteredItems?.length || 0) / itemsPerPage)}</span>
          <Button
            text="Next"
            onClick={() => setCurrentPage(prev => prev + 1)}
            disabled={currentPage * itemsPerPage >= (filteredItems?.length || 0)}
          />
        </div>
      }

      {/* Loading modal */}
      <Modal isShown={showModal.loading}>
        <ModalContainer>
          <LoadingWrapper>
            <Loader text={"Executing operation..."} />
          </LoadingWrapper>
        </ModalContainer>
      </Modal>

      {/* Action confirmation User Modal */}
      {action && actions && !!actions[action].confirm ?
        <Dialog
          cancel={{
            text: 'Cancel',
            action: () => {
              setShowModal({ [action]: false })
            }
          }}
          confirmation={{
            text: 'Confirm',
            action: () => {

              setShowModal({ [action]: false, loading: true })

              const result = actions[action]?.actionHandler?.(actionItem);

              if (!!actions && Object.keys(actions).includes(action as keyof typeof showModal) && !!actions[action].actionHandler && !!actionItem) {

                if (result instanceof Promise) {

                  result
                    .then((res: { success: boolean; message: string }) => {

                      setShowModal({ loading: false });

                      if (res.success) {
                        dispatch(addNotification({ type: 'success', description: res.message }))
                      } else {
                        console.error(`${action} operation failed: `, res.message);
                        dispatch(addNotification({ type: 'error', description: res.message }));
                      }

                    })
                    .catch(error => {
                      // Catch any unexpected errors
                      setShowModal({ loading: false });
                      console.error(`${action} operation failed: `, error.message);
                      dispatch(addNotification({ type: 'error', description: error.message }));
                    });
                }
              } else {
                // If no promise is returned, immediately stop loading
                setShowModal({ loading: false });
              }
            }
          }}
          description={
            <>
              {renderTemplate(actions[action].confirmationText, { name: actionItem.name, id: actionItem[idKey] })}
            </>}
          isShown={showModal?.[action as keyof typeof showModal]}
          title={actions[action].confirmTitle || `Confirm ${action}`}
          type="info"
        />
        : null}

      {/* New item Modal */}
      {Object.keys(actions).includes("create") && actions["create"]?.fields &&
        <NewItemModal
          fields={actions["create"]?.fields}
          
          isShown={showModal.create}
          onClose={() => {
            setShowModal({ create: false })
          }}
          onSubmit={(data) => {

            setShowModal({ create: false, loading: true })

            const result = actions["create"]?.actionHandler?.(data);

            if (!!data) {
              if (result instanceof Promise) {
                result
                  .then((res: { success: boolean; message: string }) => {

                    setShowModal({ loading: false });

                    if (res.success) {
                      dispatch(addNotification({ type: 'success', description: res.message }))
                    } else {
                      console.error(`${action} operation failed: `, res.message);
                      dispatch(addNotification({ type: 'error', description: res.message }));
                    }

                  })
                  .catch(error => {
                    // Catch any unexpected errors
                    setShowModal({ loading: false });
                    console.error(`${action} operation failed: `, error.message);
                    dispatch(addNotification({ type: 'error', description: error.message }));
                  });
              }
            } else {
              // If no promise is returned, immediately stop loading
              setShowModal({ loading: false });
            }

          }}
        />}

      {/* Error modal TODO: Remove this modal, we have the toasts*/} 
      <Dialog
        size="extra-large"
        cancel={{
          text: 'Close',
          action: () => {
            setShowModal({ error: false })
            setAction('')
            setActionItem(undefined)
          }
        }}
        description={errorMessage}
        isShown={showModal.error}
        title={`${action} error`}
        type="error"
      />
    </div>
  );
};