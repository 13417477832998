import React from 'react'

import { Button } from 'components/button'

import {
  Container,
  TxtWrapper,
  ImageWrapper,
  Img,
  Title,
  Subtitle,
  Text
} from './emptyState.styles'

import { classes } from 'config/helpers'

interface EmptyStateProps {
  className?: string
  imgUrl?: string
  title?: string
  subtitle?: string
  text?: string
  action?: {
    label: string
    callback: () => void
  }
  small?: boolean
  actionLink?: string
  externalLink?: boolean
  smallImage?: boolean
}

export const EmptyState = ({
  className,
  imgUrl,
  title,
  subtitle,
  text,
  action,
  actionLink,
  externalLink,
  small,
  smallImage
}: EmptyStateProps) => {

  return (
    <Container className={classes(className, { small })}>
      <ImageWrapper className={classes({ smallImage })}>
        <Img src={imgUrl ?? '/images/empty_generic.svg'} />
      </ImageWrapper>
      <TxtWrapper>
        <Title>{title}</Title>
        {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
        <Text>{text}</Text>
      </TxtWrapper>
      {action ? (
        <Button
          className='action--button'
          external={externalLink}
          size="sm"
          text={action.label}
          to={actionLink}
          onClick={action.callback}
        />
      ) : null}
    </Container>
  )
}

EmptyState.displayName = 'EmptyState'
