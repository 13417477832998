import React, { FC, useEffect, useState } from 'react'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import {
  InputWrapper,
  InputStyled,
  ErrorText,
  Label,
  HelperText,
  BottomText,

} from './input.styles'

import { classes, lettersNumbersRegex, onlyLettersRegex, oneLetterRegex } from 'config/helpers'
import { InputSwitch } from '../inputSwitch';  // Import InputSwitch component
import Select from 'react-select'
import DurationInput from './durationinput';
import CodeEditor, { ValidationError } from './codeeditor';
import { validateJson } from './helpers';

export type ValidatorType = 'onlyLetters' | 'lettersNumbers' | 'oneLetter' | 'geoJSON';

export interface FieldError {
  isValid: boolean;
  text: string;
}

interface Props {
  ariaLive?: "off" | "assertive" | "polite";
  className?: string;
  disabled?: boolean;
  error?: {
    flag?: boolean;
    text?: string;
    validator?: any;
  };
  helperText?: string;
  icon?: any;
  id?: string;
  label?: string;
  maxLength?: number;
  minLength?: number;
  schema?: object;
  name: string;
  readonly?: boolean;
  ref?: React.RefObject<HTMLInputElement>;
  role?: string;
  small?: boolean;
  tabIndex?: number;
  testid?: string;
  type?: string;
  validate?: boolean;
  validator?: ValidatorType;
  value?: string | number | boolean | object ;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onValidate?: (error: FieldError) => void;
  required?: boolean;
  options?: any
}

export const Input: FC<Props> = ({
  ariaLive,
  className,
  disabled = false,
  error = {},
  helperText,
  icon,
  id,
  label,
  maxLength,
  minLength,
  schema,
  name,
  readonly = false,
  ref,
  role,
  small,
  tabIndex,
  testid,
  type = 'text',
  validate = true,
  validator,
  value = '',
  onBlur,
  onChange,
  onValidate,
  options,
  required
}) => {
  const [currentValue, setCurrentValue] = useState(value)
  const [showError, setShowError] = useState(error.flag ? error.flag : false)
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [jsonEditorErrors, setJsonEditorErrors] = useState<ValidationError[]>([])
  const [showPassword, setShowPassword] = useState(false)
  
  useEffect(() => {    
    setCurrentValue(value);
  }, [value]);

  useEffect(() => {
    if (error.flag !== undefined) setShowError(error.flag)
  }, [error.flag])

  // Custom handleChange for different input types
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const validateInput = (value: string | boolean | number | object) => {  
      let errorMsg = '';
      let isValid: boolean = true;
  
      // Check required validation
      if (value === "") {
        if (required) {
          isValid = false;
          errorMsg = "This field is required and cannot be blank";
        } else {
          isValid = true;
          errorMsg = "";
          setJsonEditorErrors([])
        }
      }
  
      // Check minLength validation
      else if (!!minLength && (value as string).length < minLength) {
        errorMsg = `Length must be at least ${minLength} characters`;
        isValid = false;
      }
      // Check custom validators
      else if (validator === 'onlyLetters') {
        isValid = onlyLettersRegex.test(value as string);
        errorMsg = 'Invalid character';
      } else if (validator === 'lettersNumbers') {
        isValid = lettersNumbersRegex.test(value as string);
        errorMsg = 'Invalid character';
      } else if (validator === 'oneLetter') {
        isValid = oneLetterRegex.test(value as string);
        errorMsg = 'Invalid character';
      } else if (validator === "geoJSON") {      
        if (value === "") {
          console.log("its empty, its valid")
        }
        if (schema) {
          const errors = validateJson(value as string, schema)
          if (errors.length > 0) {
            isValid = false;
            errorMsg = 'Invalid GeoJson, check editor for errors';
          }        
          setJsonEditorErrors(errors);
        } else {
          isValid = false;
          errorMsg = 'JSON Schema not provided for validation and validate JSON is set to true';
        }      
      }
  
      setShowError(!isValid);
  
      if (!!onValidate) {
        onValidate({ isValid: isValid, text: errorMsg });
      }
  
      setErrorMsg(errorMsg);
    }
    
    const value = type === 'checkbox' ? e.currentTarget.checked : 
                    //type === 'json' ? JSON.parse(e.currentTarget.value) :     
                      e.currentTarget.value;
    
    setCurrentValue(value);
    validateInput(e.currentTarget.value);
    if (onChange) onChange(e);
  };


/*
  // Trigger validation on initial render
  useEffect(() => {    
    validateInput(currentValue);
  }, [currentValue, validateInput]);
*/
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) onBlur(e);
  };

  return (
    <InputWrapper className={classes(className)}
      style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
    >

      {type === 'checkbox' ? (
        <InputSwitch
          checked={!!currentValue}
          onChange={handleChange}
          disabled={disabled}
        />
      ) : type === "list" ? (
        <div style={{ maxWidth: "content", width: "100%" }}>
          <Select
            className="custom-select"
            isClearable
            isSearchable
            options={options}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: base => ({ ...base, zIndex: 9999995 }),
              multiValueRemove: base => ({ ...base })
            }}
            onChange={(data) => {
              handleChange({                
                target: { value: (data as any[]).map(role => role.value).join(', ') } as HTMLInputElement,
                currentTarget: { value: (data as any[]).map(role => role.value).join(', ') } as HTMLInputElement,
              } as React.ChangeEvent<HTMLInputElement>);
            }}
            isMulti
            placeholder={label}
          />
        </div>
      ) : type === "timedelta" ? (
        <DurationInput
          name={name}
          onChange={(duration: string | undefined) => {
            handleChange({
              type: "duration",
              target: { value: duration, name: name } as HTMLInputElement,
              currentTarget: { value: duration } as HTMLInputElement,
            } as React.ChangeEvent<HTMLInputElement>);
          }}
          value={currentValue as string}
          onBlur={handleBlur}
        />
      ) : type === "json" ? (
        <CodeEditor
          editable={!disabled}
          errors={jsonEditorErrors}          
          onChange={(geojson: string) => {
            handleChange({         
              type: "codeEditor",     
              target: { value: geojson, name: name } as HTMLInputElement,
              currentTarget: { value: geojson } as HTMLInputElement,
            } as React.ChangeEvent<HTMLInputElement>);
          }}
          value={currentValue as string}
          name={name}
          onBlur={handleBlur}
           />
      ) :
        (
          <InputStyled
            aria-live={ariaLive}
            aria-label={name}
            autoComplete="off"
            className={classes({
              active: !!currentValue,
              disabled,
              error: showError,
              number: type === "number",
              small,
              'with--icon': !!icon,
              password: type === 'password'
            })}
            data-testid={testid}
            id={id || name}
            maxLength={maxLength}
            minLength={minLength}
            name={name}
            readOnly={readonly}
            ref={ref}
            required={!!error.text}
            role={role}
            tabIndex={tabIndex}
            type={showPassword ? 'text' : type}
            value={currentValue as string | number}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        )}
      
      {label && !["list", "timedelta", "json", ].includes(type) && (
        <Label className={classes({ small })} htmlFor={name}>
          {disabled && <div className="disabled_background" />}
          {label}
        </Label>
      )}
      {icon ?? ''}
      {type === 'password' ? (        
        <div
          className={classes("togglerContainer", { disabled })}
          onClick={(e: any) => {
            e.preventDefault();
            setShowPassword(!showPassword);
          }}
        >
        {showPassword ? <EyeIcon /> : <EyeSlashIcon />}  
        </div>
      ) : null}



      <BottomText className={classes({ shown: showError || !!helperText })}>
        {showError && <ErrorText>{error.text || errorMsg}</ErrorText>}
        {!showError && !!helperText && <HelperText>{helperText}</HelperText>}
      </BottomText>
    </InputWrapper>
  )
}

Input.displayName = 'Input';