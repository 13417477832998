import styled from '@emotion/styled/macro'
import { Link } from 'react-router-dom'

export const StyledButton = styled.button`
  width: auto;
  align-items: center;
  background-color: ${(props: any) => props.theme.primary10};
  border: none;
  border-radius: ${(props: any) => props.theme.radius.full};
  color: ${(props: any) => props.theme.btnPrimary};
  cursor: pointer;
  display: flex;
  font-weight: 600;
  justify-content: center;
  transition: all 0.3s linear;
  white-space: nowrap;
  &:hover {
    background-color: ${(props: any) => props.theme.primary20};
    box-shadow: ${(props: any) => props.theme.shadow.medium} ${(props: any) => props.theme.shadowColor};
  }
  &:active {
    background-color: ${(props: any) => props.theme.primary30};
  }
  svg {
    width: ${(props: any) => props.theme.spacing.large15};
    height: ${(props: any) => props.theme.spacing.large15};
    color: ${(props: any) => props.theme.neutral};
    margin: 0 5px 0 0;
    transition: all 0.3s linear;
  }
  &.md {
    height: ${(props: any) => props.theme.spacing.large25};
    padding: ${(props: any) => props.theme.spacing.medium} ${(props: any) => props.theme.spacing.large15};
    ${(props: any) => props.theme.texts.buttonmd};
  }
  &.sm {
    height: ${(props: any) => props.theme.spacing.large20};
    padding: 6px ${(props: any) => props.theme.spacing.large10};
    ${(props: any) => props.theme.texts.buttonsm};
    svg {
      width: ${(props: any) => props.theme.spacing.large10};
      height: ${(props: any) => props.theme.spacing.large10};
    }
  }
  &.xs {
    height: ${(props: any) => props.theme.spacing.large15};
    padding: ${(props: any) => props.theme.spacing.small} ${(props: any) => props.theme.spacing.large10};
    ${(props: any) => props.theme.texts.buttonxs};
    svg {
      width: ${(props: any) => props.theme.spacing.large};
      height: ${(props: any) => props.theme.spacing.large};
    }
  }
  &.disabled {
    background: ${(props: any) => props.theme.grayscale30};
    color: ${(props: any) => props.theme.grayscale40};
    cursor: not-allowed;
    pointer-events: none;
    svg {
      color: ${(props: any) => props.theme.grayscale40};
    }
  }
  &.secondary {
    background-color: ${(props: any) => props.theme.secondary10};
    border: 1px solid ${(props: any) => props.theme.primary10};
    color: ${(props: any) => props.theme.primary10};
    &:hover {
      background-color: ${(props: any) => props.theme.secondary20};
    }
    &:active {
      background-color: ${(props: any) => props.theme.secondary30};
    }
    svg {
      color: ${(props: any) => props.theme.primary10};
    }
    &.disabled {
      background-color: ${(props: any) => props.theme.grayscale30};
      border: 1px solid ${(props: any) => props.theme.bodyGray};
      color: ${(props: any) => props.theme.grayscale40};
      svg {
        color: ${(props: any) => props.theme.grayscale40};
      }
    }
  }
  &.onlyText {
    background: transparent;
    color: ${(props: any) => props.theme.primary10};
    text-decoration: underline;
    svg {
      color: ${(props: any) => props.theme.primary10};
    }
    &:hover {
      background-color: ${(props: any) => props.theme.secondary20};
      box-shadow: ${(props: any) => props.theme.shadow.medium} ${(props: any) => props.theme.shadowColor};
    }
    &:active {
      background-color: ${(props: any) => props.theme.secondary30};
    }
    &.disabled {
      background: transparent;
      border: transparent;
      color: ${(props: any) => props.theme.grayscale40};
      svg {
        color: ${(props: any) => props.theme.grayscale40};
      }
    }
  }
  &.header {
    background-color: ${(props: any) => props.theme.global30};
    color: ${(props: any) => props.theme.global60};
    &:hover {
      background-color: ${(props: any) => props.theme.global40};
    }
    &:active {
      background-color: ${(props: any) => props.theme.global50};
    }
    &.disabled {
      background-color: ${(props: any) => props.theme.grayscale30};
      color: ${(props: any) => props.theme.grayscale40};
    }
    &.secondary {
      background-color: transparent;
      border: transparent;
      color: ${(props: any) => props.theme.primary10};
      text-decoration: underline;
      &:hover, &:active {
        background-color: ${(props: any) => props.theme.secondary60};
        color: ${(props: any) => props.theme.global30};
      }
      &.disabled {
        background: transparent;
        border: transparent;
        color: ${(props: any) => props.theme.grayscale40};
      }
    }
  }
  &.rightIcon {
    flex-direction: row-reverse;
    svg {
      margin: 0 0 0 5px;
    }
  }
  &.onlyIcon {
    padding: 0;
    svg {
      margin: 0;
    }
    &:hover {
      box-shadow: none;
    }
    &.xl {
      width: ${(props: any) => props.theme.spacing.large25};
      height: ${(props: any) => props.theme.spacing.large25};
      svg {
        width: ${(props: any) => props.theme.spacing.large20};
        height: ${(props: any) => props.theme.spacing.large20};
      }
    }
    &.lg {
      width: ${(props: any) => props.theme.spacing.large20};
      height: ${(props: any) => props.theme.spacing.large20};
      svg {
        width: ${(props: any) => props.theme.spacing.large15};
        height: ${(props: any) => props.theme.spacing.large15};
      }
    }
    &.md {
      width: ${(props: any) => props.theme.spacing.large15};
      height: ${(props: any) => props.theme.spacing.large15};
      svg {
        width: ${(props: any) => props.theme.spacing.large10};
        height: ${(props: any) => props.theme.spacing.large10};
      }
    }
    &.sm {
      width: 20px;
      height: 20px;
      svg {
        width: 14px;
        height: 14px;
      }
    }
    &.secondary {
      border: none;
    }
    &.grayscale {
      background-color: ${(props: any) => props.theme.secondary20};
      svg {
        color: ${(props: any) => props.theme.body};
      }
      &:hover {
        background-color: ${(props: any) => props.theme.secondary30};
        box-shadow: unset;
      }
      &.copied {
        background-color: ${(props: any) => props.theme.success};
        svg {
          color: ${(props: any) => props.theme.global60};
        }
      }
      &.disabled {
        background: ${(props: any) => props.theme.grayscale30};
        color: ${(props: any) => props.theme.grayscale40};
        cursor: not-allowed;
        pointer-events: none;
        svg {
          color: ${(props: any) => props.theme.grayscale40};
        }
      }
    }
    &.onlyText {
      svg {
        color: ${(props: any) => props.theme.body};
      }
      &:hover {
        background-color: ${(props: any) => props.theme.base20};
      }
      &:active {
        background-color: ${(props: any) => props.theme.base30};
      }
      &.disabled {
        background: ${(props: any) => props.theme.grayscale30};
        color: ${(props: any) => props.theme.grayscale40};
        cursor: not-allowed;
        pointer-events: none;
        svg {
          color: ${(props: any) => props.theme.grayscale40};
        }
      }
    }
    &.header {
      background-color: ${(props: any) => props.theme.secondary50};
      svg {
        color: ${(props: any) => props.theme.global10};
      }
      &:active svg {
        color: ${(props: any) => props.theme.global30};
      }
      &.disabled {
        background: ${(props: any) => props.theme.grayscale30};
        color: ${(props: any) => props.theme.grayscale40};
        cursor: not-allowed;
        pointer-events: none;
        svg {
          color: ${(props: any) => props.theme.grayscale40};
        }
      }
    }
  }
`;

export const StyledLink = styled(Link)`
  width: auto;
  align-items: center;
  background-color: ${(props: any) => props.theme.primary10};
  border: none;
  border-radius: ${(props: any) => props.theme.radius.full};
  color: ${(props: any) => props.theme.btnPrimary};
  cursor: pointer;
  display: flex;
  font-weight: 600;
  justify-content: center;
  transition: all 0.3s linear;
  white-space: nowrap;
  &:hover {
    background-color: ${(props: any) => props.theme.primary20};
    box-shadow: ${(props: any) => props.theme.shadow.medium} ${(props: any) => props.theme.shadowColor};
  }
  &:active {
    background-color: ${(props: any) => props.theme.primary30};
  }
  svg {
    width: ${(props: any) => props.theme.sizes.body};
    height: ${(props: any) => props.theme.sizes.body};
    color: ${(props: any) => props.theme.neutral};
    margin: 0 5px 0 0;
    transition: all 0.3s linear;
  }
  &.md {
    height: ${(props: any) => props.theme.spacing.large25};
    padding: ${(props: any) => props.theme.spacing.medium} ${(props: any) => props.theme.spacing.large15};
    ${(props: any) => props.theme.texts.buttonmd};
  }
  &.sm {
    height: ${(props: any) => props.theme.spacing.large20};
    padding: 6px ${(props: any) => props.theme.spacing.large10};
    ${(props: any) => props.theme.texts.buttonsm};
  }
  &.xs {
    height: ${(props: any) => props.theme.spacing.large15};
    padding: ${(props: any) => props.theme.spacing.small} ${(props: any) => props.theme.spacing.large10};
    ${(props: any) => props.theme.texts.buttonxs};
    svg {
      width: ${(props: any) => props.theme.spacing.large};
      height: ${(props: any) => props.theme.spacing.large};
    }
  }
  &.disabled {
    background: ${(props: any) => props.theme.grayscale30};
    color: ${(props: any) => props.theme.grayscale40};
    cursor: not-allowed;
    pointer-events: none;
    svg {
      color: ${(props: any) => props.theme.grayscale40};
    }
  }
  &.secondary {
    background-color: ${(props: any) => props.theme.secondary10};
    border: 1px solid ${(props: any) => props.theme.primary10};
    color: ${(props: any) => props.theme.primary10};
    &:hover {
      background-color: ${(props: any) => props.theme.secondary20};
    }
    &:active {
      background-color: ${(props: any) => props.theme.secondary30};
    }
    svg {
      color: ${(props: any) => props.theme.primary10};
    }
    &.disabled {
      background-color: ${(props: any) => props.theme.grayscale30};
      border: 1px solid ${(props: any) => props.theme.bodyGray};
      color: ${(props: any) => props.theme.grayscale40};
      svg {
        color: ${(props: any) => props.theme.grayscale40};
      }
    }
  }
  &.onlyText {
    background: transparent;
    color: ${(props: any) => props.theme.primary10};
    text-decoration: underline;
    svg {
      color: ${(props: any) => props.theme.primary10};
    }
    &:hover {
      background-color: ${(props: any) => props.theme.secondary20};
      box-shadow: ${(props: any) => props.theme.shadow.medium} ${(props: any) => props.theme.shadowColor};
    }
    &:active {
      background-color: ${(props: any) => props.theme.secondary30};
    }
    &.disabled {
      background: transparent;
      border: transparent;
      color: ${(props: any) => props.theme.grayscale40};
      svg {
        color: ${(props: any) => props.theme.grayscale40};
      }
    }
  }
  &.header {
    background-color: ${(props: any) => props.theme.global30};
    color: ${(props: any) => props.theme.global60};
    &:hover {
      background-color: ${(props: any) => props.theme.global40};
    }
    &:active {
      background-color: ${(props: any) => props.theme.global50};
    }
    &.disabled {
      background-color: ${(props: any) => props.theme.grayscale30};
      color: ${(props: any) => props.theme.grayscale40};
    }
    &.secondary {
      background-color: transparent;
      border: transparent;
      color: ${(props: any) => props.theme.primary10};
      text-decoration: underline;
      &:hover, &:active {
        background-color: ${(props: any) => props.theme.secondary60};
        color: ${(props: any) => props.theme.global30};
      }
      &.disabled {
        background: transparent;
        border: transparent;
        color: ${(props: any) => props.theme.grayscale40};
      }
    }
  }
  &.rightIcon {
    flex-direction: row-reverse;
    svg {
      margin: 0 0 0 5px;
    }
  }
  &.onlyIcon {
    padding: 0;
    svg {
      margin: 0;
    }
    &:hover {
      box-shadow: none;
    }
    &.xl {
      width: ${(props: any) => props.theme.spacing.large25};
      height: ${(props: any) => props.theme.spacing.large25};
      svg {
        width: ${(props: any) => props.theme.spacing.large20};
        height: ${(props: any) => props.theme.spacing.large20};
      }
    }
    &.lg {
      width: ${(props: any) => props.theme.spacing.large20};
      height: ${(props: any) => props.theme.spacing.large20};
      svg {
        width: ${(props: any) => props.theme.spacing.large15};
        height: ${(props: any) => props.theme.spacing.large15};
      }
    }
    &.md {
      width: ${(props: any) => props.theme.spacing.large15};
      height: ${(props: any) => props.theme.spacing.large15};
      svg {
        width: 20px;
        height: 20px;
      }
    }
    &.sm {
      width: 20px;
      height: 20px;
      svg {
        width: ${(props: any) => props.theme.spacing.large10};
        height: ${(props: any) => props.theme.spacing.large10};
      }
    }
    &.secondary {
      border: none;
    }
    &.grayscale {
      background-color: ${(props: any) => props.theme.secondary20};
      svg {
        color: ${(props: any) => props.theme.body};
      }
      &:hover {
        background-color: ${(props: any) => props.theme.secondary30};
        box-shadow: unset;
      }
      &.copied {
        background-color: ${(props: any) => props.theme.success};
        svg {
          color: ${(props: any) => props.theme.global60};
        }
      }
    }
    &.onlyText {
      svg {
        color: ${(props: any) => props.theme.body};
      }
      &:hover {
        background-color: ${(props: any) => props.theme.base20};
      }
      &:active {
        background-color: ${(props: any) => props.theme.base30};
      }
    }
    &.header {
      background-color: ${(props: any) => props.theme.secondary50};
      svg {
        color: ${(props: any) => props.theme.global10};
      }
      &:active svg {
        color: ${(props: any) => props.theme.global30};
      }
    }
  }
`;

export const StyledExternalLink = styled.a`
  width: auto;
  align-items: center;
  background-color: ${(props: any) => props.theme.primary10};
  border: none;
  border-radius: ${(props: any) => props.theme.radius.full};
  color: ${(props: any) => props.theme.btnPrimary};
  cursor: pointer;
  display: flex;
  font-weight: 600;
  justify-content: center;
  transition: all 0.3s linear;
  white-space: nowrap;
  &:hover {
    background-color: ${(props: any) => props.theme.primary20};
    box-shadow: ${(props: any) => props.theme.shadow.medium} ${(props: any) => props.theme.shadowColor};
  }
  &:active {
    background-color: ${(props: any) => props.theme.primary30};
  }
  svg {
    width: ${(props: any) => props.theme.sizes.body};
    height: ${(props: any) => props.theme.sizes.body};
    color: ${(props: any) => props.theme.neutral};
    margin: 0 5px 0 0;
    transition: all 0.3s linear;
  }
  &.md {
    height: ${(props: any) => props.theme.spacing.large25};
    padding: ${(props: any) => props.theme.spacing.medium} ${(props: any) => props.theme.spacing.large15};
    ${(props: any) => props.theme.texts.buttonmd};
  }
  &.sm {
    height: ${(props: any) => props.theme.spacing.large20};
    padding: 6px ${(props: any) => props.theme.spacing.large10};
    ${(props: any) => props.theme.texts.buttonsm};
  }
  &.xs {
    height: ${(props: any) => props.theme.spacing.large15};
    padding: ${(props: any) => props.theme.spacing.small} ${(props: any) => props.theme.spacing.large10};
    ${(props: any) => props.theme.texts.buttonxs};
    svg {
      width: ${(props: any) => props.theme.spacing.large};
      height: ${(props: any) => props.theme.spacing.large};
    }
  }
  &.disabled {
    background: ${(props: any) => props.theme.grayscale30};
    color: ${(props: any) => props.theme.grayscale40};
    cursor: not-allowed;
    pointer-events: none;
    svg {
      color: ${(props: any) => props.theme.grayscale40};
    }
  }
  &.secondary {
    background-color: ${(props: any) => props.theme.secondary10};
    border: 1px solid ${(props: any) => props.theme.primary10};
    color: ${(props: any) => props.theme.primary10};
    &:hover {
      background-color: ${(props: any) => props.theme.secondary20};
    }
    &:active {
      background-color: ${(props: any) => props.theme.secondary30};
    }
    svg {
      color: ${(props: any) => props.theme.primary10};
    }
    &.disabled {
      background-color: ${(props: any) => props.theme.grayscale30};
      border: 1px solid ${(props: any) => props.theme.bodyGray};
      color: ${(props: any) => props.theme.grayscale40};
      svg {
        color: ${(props: any) => props.theme.grayscale40};
      }
    }
  }
  &.onlyText {
    background: transparent;
    color: ${(props: any) => props.theme.primary10};
    text-decoration: underline;
    svg {
      color: ${(props: any) => props.theme.primary10};
    }
    &:hover {
      background-color: ${(props: any) => props.theme.secondary20};
      box-shadow: ${(props: any) => props.theme.shadow.medium} ${(props: any) => props.theme.shadowColor};
    }
    &:active {
      background-color: ${(props: any) => props.theme.secondary30};
    }
    &.disabled {
      background: transparent;
      border: transparent;
      color: ${(props: any) => props.theme.grayscale40};
      svg {
        color: ${(props: any) => props.theme.grayscale40};
      }
    }
  }
  &.header {
    background-color: ${(props: any) => props.theme.global30};
    color: ${(props: any) => props.theme.global60};
    &:hover {
      background-color: ${(props: any) => props.theme.global40};
    }
    &:active {
      background-color: ${(props: any) => props.theme.global50};
    }
    &.disabled {
      background-color: ${(props: any) => props.theme.grayscale30};
      color: ${(props: any) => props.theme.grayscale40};
    }
    &.secondary {
      background-color: transparent;
      border: transparent;
      color: ${(props: any) => props.theme.primary10};
      text-decoration: underline;
      &:hover, &:active {
        background-color: ${(props: any) => props.theme.secondary60};
        color: ${(props: any) => props.theme.global30};
      }
      &.disabled {
        background: transparent;
        border: transparent;
        color: ${(props: any) => props.theme.grayscale40};
      }
    }
  }
  &.rightIcon {
    flex-direction: row-reverse;
    svg {
      margin: 0 0 0 5px;
    }
  }
  &.onlyIcon {
    padding: 0;
    svg {
      margin: 0;
    }
    &:hover {
      box-shadow: none;
    }
    &.xl {
      width: ${(props: any) => props.theme.spacing.large25};
      height: ${(props: any) => props.theme.spacing.large25};
      svg {
        width: ${(props: any) => props.theme.spacing.large20};
        height: ${(props: any) => props.theme.spacing.large20};
      }
    }
    &.lg {
      width: ${(props: any) => props.theme.spacing.large20};
      height: ${(props: any) => props.theme.spacing.large20};
      svg {
        width: ${(props: any) => props.theme.spacing.large15};
        height: ${(props: any) => props.theme.spacing.large15};
      }
    }
    &.md {
      width: ${(props: any) => props.theme.spacing.large15};
      height: ${(props: any) => props.theme.spacing.large15};
      svg {
        width: 20px;
        height: 20px;
      }
    }
    &.sm {
      width: 20px;
      height: 20px;
      svg {
        width: ${(props: any) => props.theme.spacing.large10};
        height: ${(props: any) => props.theme.spacing.large10};
      }
    }
    &.secondary {
      border: none;
    }
    &.grayscale {
      background-color: ${(props: any) => props.theme.secondary20};
      svg {
        color: ${(props: any) => props.theme.body};
      }
      &:hover {
        background-color: ${(props: any) => props.theme.secondary30};
        box-shadow: unset;
      }
      &.copied {
        background-color: ${(props: any) => props.theme.success};
        svg {
          color: ${(props: any) => props.theme.global60};
        }
      }
    }
    &.onlyText {
      svg {
        color: ${(props: any) => props.theme.body};
      }
      &:hover {
        background-color: ${(props: any) => props.theme.base20};
      }
      &:active {
        background-color: ${(props: any) => props.theme.base30};
      }
    }
    &.header {
      background-color: ${(props: any) => props.theme.secondary50};
      svg {
        color: ${(props: any) => props.theme.global10};
      }
      &:active svg {
        color: ${(props: any) => props.theme.global30};
      }
    }
  }
`;
