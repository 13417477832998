import React from 'react'
import ReactDOM from 'react-dom';

import {
  LoaderWrapper,
  LoaderContainer,
  LoaderText,
  Satellite,
  Earth,
  LoaderBase,
  LoaderOverlay,
  LoaderWindow,
} from './loader.styles'

import { classes } from 'config/helpers'

interface LoaderProps {
  darkBackground?: boolean
  fullScreen?: boolean
  text?: string
}

export const Loader = ({ darkBackground = false, fullScreen = false, text }: LoaderProps) => {

  const loader = (
    <LoaderBase className={classes({ fullScreen })}>
      <LoaderOverlay/>
      <LoaderWindow>
          <LoaderWrapper>
            <LoaderContainer>
              <Satellite />
              <Earth />
            </LoaderContainer>
            <LoaderText className={classes({ darkBackground })}>{text}</LoaderText>
          </LoaderWrapper>
      </LoaderWindow>
    </LoaderBase>
  )

  return fullScreen ? ReactDOM.createPortal(loader, document.body) : loader
}

Loader.displayName = "Loader"
