import React from 'react'

import { StyledButton, StyledLink, StyledExternalLink } from './button.style'

import { classes } from 'config/helpers'

interface Props {
  // CSS class name
  className?: string
  // Disabled Style
  disabled?: boolean
  // External Variant
  external?: boolean
  // Grayscale Style
  grayscale?: boolean
  // Header Variant
  header?: boolean
  // Icon to display
  icon?: JSX.Element
  // Button name
  name?: string
  // Only Icon Variant
  onlyIcon?: boolean
  // Only Text Style
  onlyText?: boolean
  // Right Icon Variant
  rightIcon?: boolean
  // Secondary Style
  secondary?: boolean
  // Size Variants
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  // Tab Index
  tabIndex?: number
  // Text to display on the button
  text?: string
  // Url to navigate to
  to?: string
  // On click handler
  onClick?: (e?: any) => void
}

export const Button = ({
  className,
  disabled = false,
  external = false,
  grayscale = false,
  header = false,
  icon,
  name,
  onlyIcon = false,
  onlyText = false,
  rightIcon = false,
  secondary = false,
  size = 'md',
  tabIndex,
  text,
  to,
  onClick
}: Props) => {
  if (!to && !external) {
    return (
      <StyledButton
        className={classes(className, size, {
          disabled,
          grayscale,
          header,
          onlyText,
          onlyIcon,
          rightIcon,
          secondary,
        })}
        name={name}
        aria-label={name}
        tabIndex={tabIndex}
        onClick={onClick}
      >
        {icon} {text}
      </StyledButton>
    )
  } else if (to && !external) {
    return (
      <StyledLink
        className={classes(className, size, {
          disabled,
          grayscale,
          header,
          onlyText,
          onlyIcon,
          rightIcon,
          secondary,
        })}
        tabIndex={tabIndex}
        to={to}
        onClick={onClick}
      >
        {icon} {text}
      </StyledLink>
    )
  } else {
    return (
      <StyledExternalLink
        className={classes(className, size, {
          disabled,
          grayscale,
          header,
          onlyText,
          onlyIcon,
          rightIcon,
          secondary,
        })}
        href={to}
        tabIndex={tabIndex}
      >
        {icon} {text}
      </StyledExternalLink>
    )
  }
}
