import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import type { RootState } from "store";
import { TableCRUD } from "components/tableCRUD"
import { EyeIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { polygonSchema } from "./geojsonschema";
import { useNavigate } from "react-router-dom";
import { ContentWrapper } from "views/views.styles";
import { renderDateCell } from "config/helpers";
import { createRestriction, deleteRestriction } from "services/account-service/restrictionsServices";
import { refreshTrigger } from "stores/globalStore/globalStoreSlice";

export const Restrictions = () => {

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const restrictions = useSelector((state: RootState) => state.userStore.restrictions);
  
  const handleCreateRestriction = async (data: any): Promise<{ success: boolean; message: string }> => {
    const token = await getAccessTokenSilently();

    console.log(data)

    const payload = {
      name: data.name,
      restricted_time_window: !!data.restricted_time_window ? data.restricted_time_window : undefined,
      geometry: !!data.geofence ? JSON.parse(data.geofence) : {}
    };

    try {
      await createRestriction(token, payload);

      dispatch(refreshTrigger());
      return {
        success: true,
        message: `Restriction ${data.name} created successfully`
      };
    } catch (error: any) {
      return {
        success: false,
        message: error.response?.data?.Message || error.response?.data?.message + " " + JSON.stringify(error.response?.data?.errors) || 'Failed to create restriction',
      };
    }

  };

  const handleDeleteRestriction = async (item: any): Promise<{ success: boolean; message: string }> => {
    const token = await getAccessTokenSilently();

    try {
      await deleteRestriction(token, item.restriction_id);

      dispatch(refreshTrigger());
      return {
        success: true,
        message: `Restriction ${item.name} deleted successfully`
      };
    } catch (error: any) {
      return {
        success: false,
        message: error.response?.data?.Message || error.response?.data?.message + " " + JSON.stringify(error.response?.data?.errors) || 'Failed to delete restriction',
      };
    }
  };

  
  return (
    <>
      
        <><ContentWrapper full>
          <TableCRUD
            title={"Restrictions"}
            columns={[
              { label: "Name", dataKey: "name" },
              { label: "Created", dataKey: "created_at", renderer: renderDateCell },
              { label: "Last Updated", dataKey: "updated_at", renderer: renderDateCell },
            ]}

            idKey="restriction_id"
            actions={{
              create: {
                actionHandler: handleCreateRestriction,
                fields: [
                  {
                    name: 'name',
                    label: 'Name',
                    type: 'text',
                    validation: { required: true, minLength: 3 },
                  },
                  {
                    name: 'restricted_time_window',
                    label: 'Data Range exclusion',
                    type: 'timedelta',
                  },
                  {
                    name: 'geofence',
                    label: 'Exclusion zones',
                    type: 'json',
                    validation: { validator: "geoJSON", schema: polygonSchema }
                  },
                ]
              },

              view: {
                icon: <EyeIcon />,
                actionHandler: (item: any) => {
                  //dispatch(setActiveUser(item))
                  navigate(`/restrictions/${item.restriction_id}`);
                }
              },

              edit: {
                icon: <PencilSquareIcon />,
                actionHandler: (item: any) => {
                  //dispatch(setActiveUser(item))
                  navigate(`/restrictions/${item.restriction_id}?edit=true`);
                }
              },

              delete: {
                icon: <TrashIcon />,
                tooltip: "Delete restriction",
                confirm: true,                
                confirmationText: "Are you sure you want to delete the restriction: {name} ({id})?",
                actionHandler: (item: any) => { return handleDeleteRestriction(item); }
              },
            }}
            data={{ items: restrictions ? restrictions : [], total: restrictions ? restrictions?.length : 999 }} />
        </ContentWrapper></>              
    </>
  )


};