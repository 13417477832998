import styled from '@emotion/styled/macro'

interface ContentWrapperProps {
  full?: boolean;
}

interface TitleWrapperProps {
  justify?: string;
}

export const ContentWrapper = styled.div<ContentWrapperProps>`
  width: 100%;
  height: 100%;
  max-width: ${props => (props.full ? '100%' : '700px')};
  background-color: ${(props: any) => props.theme.base10};;
  border-radius: ${(props: any) => props.theme.radius.large};
  overflow: hidden;
  padding: 16px;
  position: relative;
`;

export const TitleWrapper = styled.div<TitleWrapperProps>`
  width: 100%;
  align-items: center;
  display: flex;
  gap: 12px;
  justify-content: ${(props: any) => props.justify || 'flex-start'};
  margin-bottom: 20px;
  padding: ${(props: any) => `${props.theme.spacing.large10} ${props.theme.spacing.large10} 0 ${props.theme.spacing.large10}`};

  svg {
    width: 24px;
    height: 24px;
    color: ${(props: any) => props.theme.body};
    flex: 0 0 24px;
  }
`;

export const Title = styled.h1`
  ${(props: any) => props.theme.texts.heading4};
  color: ${(props: any) => props.theme.body};
  font-weight: ${(props: any) => props.theme.weight.bold};
`

export const LoadingWrapper = styled.div`
  width: 100%;
  max-width: 560px;
  height: 100%;
  min-height: 150px;
  align-items: center;
  background-color: ${(props: any) => props.theme.base10};
  border-radius: ${(props: any) => props.theme.radius.large};
  display: flex;
  justify-content: center;
  gap: ${(props: any) => props.theme.spacing.large10};
  margin: 0 auto;
  svg {
    width: auto;
    height: 30px;
    color: ${(props: any) => props.theme.primary};
  }
`

export const TableWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height:75%;

  border: 1px solid #ddd;
  .ReactVirtualized__Table__headerRow {
    background-color: #f5f5f5;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
  .ReactVirtualized__Table__row {
    display: flex;
    align-items: center;
    border-bottom: none;    
    &:nth-of-type(odd) {
      background-color: ${(props: any) => props.theme.grayscale50};
    }
    &:hover {
      background-color: #f0f0f0;
    }
  }

  .ReactVirtualized__Table__headerColumn{
    ${(props: any) => props.theme.texts.buttonsm};
    color: ${(props: any) => props.theme.body};
    font-weight: ${(props: any) => props.theme.weight.bold};
    padding: ${(props: any) => props.theme.spacing.large} ${(props: any) => props.theme.spacing.large10};
    text-align: center;
    white-space: nowrap;
    flex: 1;
    padding: 10px;
  }
    
  .ReactVirtualized__Table__rowColumn {
    flex: 1;
    padding: 10px;   
    text-align: left; 
    ${(props: any) => props.theme.texts.body2};
    color: ${(props: any) => props.theme.body};
    font-weight: ${(props: any) => props.theme.weight.regular};
    padding: ${(props: any) => props.theme.spacing.large} ${(props: any) => props.theme.spacing.large10};
    white-space: nowrap;
  }
`
export const TableHeader = styled.thead``

export const TableBody = styled.tbody``

export const TableRow = styled.tr`
  width: 100%;
  border-bottom: 1px solid ${(props: any) => props.theme.grayscale30};
`

export const TableColumnHeader = styled.th`
  ${(props: any) => props.theme.texts.buttonsm};
  color: ${(props: any) => props.theme.body};
  font-weight: ${(props: any) => props.theme.weight.bold};
  padding: ${(props: any) => props.theme.spacing.large} ${(props: any) => props.theme.spacing.large10};
  text-align: left;
  white-space: nowrap;
`

export const TableColumn = styled.td`
  ${(props: any) => props.theme.texts.body2};
  color: ${(props: any) => props.theme.body};
  font-weight: ${(props: any) => props.theme.weight.regular};
  padding: ${(props: any) => props.theme.spacing.large} ${(props: any) => props.theme.spacing.large10};
  white-space: nowrap;
  
`

export const Table = styled.table`
  width: 100%;
  ${TableBody} ${TableRow} {
    &:last-of-type {
      border-bottom: none;
    }
    &:nth-of-type(odd) {
      background-color: ${(props: any) => props.theme.grayscale50};
    }
  }
  &.manage-accounts {
    ${TableRow} {
      ${TableColumn}, ${TableColumnHeader} {
        &:nth-of-type(5) {
          width: 100px;
          text-align: left;
        }
        &:last-of-type {
          width: 50%;
          text-align: left;
        }
      }
    }
  }
  &.api-credentials {
    ${TableRow} {
      ${TableColumn}, ${TableColumnHeader} {
        &:last-of-type {
          width: 100px;
          text-align: center;
        }
      }
    }
  }
`

export const ActionButtons = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: center;
`

export const EmptyStateWrapper = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
`

export const ModalContainer = styled.div`
  width: 560px;
  border-radius: ${(props: any) => props.theme.radius.large};
  background-color: ${(props: any) => props.theme.base10};
  box-shadow: ${(props: any) => props.theme.shadow.large} ${(props: any) => props.theme.shadowColor};
`

export const ButtonsWrapper = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  gap: ${(props: any) => props.theme.spacing.large10};
  justify-content: flex-end;
  padding-top: ${(props: any) => props.theme.spacing.large25};
  padding-bottom: ${(props: any) => props.theme.spacing.large10};
  &.noapicredentials {
    justify-content: center;
  }
  &.users {
    padding: 0;
  }
`

export const SuccessWrapper = styled.div`
  width: 100%;
  max-width: 560px;
  height: 150px;
  align-items: center;
  background-color: ${(props: any) => props.theme.success};
  border-radius: ${(props: any) => props.theme.radius.large};
  display: flex;
  justify-content: center;
  gap: ${(props: any) => props.theme.spacing.large10};
  svg {
    width: ${(props: any) => props.theme.spacing.large25};
    color: ${(props: any) => props.theme.body};
  }
`

export const SuccessText = styled.p`
  ${(props: any) => props.theme.texts.body};
  color: ${(props: any) => props.theme.secondary70};
`

export const PaginationWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
`

export const PaginationText = styled.p`
  ${(props: any) => props.theme.texts.caption};
  color: ${(props: any) => props.theme.body};
`

export const SearchWrapper = styled.div`
  margin: 1rem 0;
  display: flex;
  justify-content: left;
  align-items: center;
  width:20%;
`;

export const SearchInput = styled.input`
  width: 100%;
  max-width: 400px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #007bff; // Adjust to your primary color
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25); // Adjust to your focus color
  }
`;