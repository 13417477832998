import { useEffect, useState } from 'react'
import { ThemeProvider } from '@emotion/react'
import { useAuth0, User } from "@auth0/auth0-react"
import { useSelector, useDispatch } from 'react-redux'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'

import { theme } from './themes/default'
import type { RootState } from './store'
import { setUser, setScope, setAccounts, setContracts, setRoles, setUsers, setOnboardingRules, setApiCredentials, setActiveAccount, setActiveUser, setMyContracts, setRestrictions } from 'stores/userStore/userStoreSlice'
import { addNotification, removeNotification } from 'stores/notificationsStore/notificationsStoreSlice'

import { UnauthenticatedHome } from 'views/unauthenticatedHome'

import { Menu } from 'components/menu'
import { MainContainer } from 'components/mainContainer'
import { ToastList } from 'components/toast'

import { useLocalStorage } from 'config/hooks'
import { Modal } from 'components/modal'
import { LoadingWrapper, ModalContainer } from 'views/views.styles'
import { Loader } from 'components/loader'
import { getAllAccounts } from 'services/account-service/accountServices'
import { getAllContracts } from 'services/account-service/contractServices'
import { getRoles } from 'services/account-service/rolesServices'
import { getAllUsers, getApiCredentials } from 'services/account-service/userServices'
import { getOnboardingRules } from 'services/account-service/onboardingRulesServices'
import { getMy } from 'services/account-service/profileServices'
import { getRestrictions } from 'services/account-service/restrictionsServices'

const ConsoleUI = () => {
  const notifications = useSelector((state: RootState) => state.notificationsStore.notifications)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [token, setToken] = useState<string | null>(null);

  const [darkTheme] = useLocalStorage('darkTheme', window.matchMedia('(prefers-color-scheme: dark)').matches)
  const location = useLocation()
  const scopes = useSelector((state: RootState) => state.userStore.scopes)
  const refreshCounter = useSelector((state: RootState) => state.globalStore.refreshCounter);
  const active_account = useSelector((state: RootState) => state.userStore.active_account);

  const { isAuthenticated, user, loginWithRedirect, isLoading, getAccessTokenSilently } = useAuth0()

  const [loadingTasks, setLoadingTasks] = useState<string[]>([]);

  const addLoadingTask = (task: string) => {
    setLoadingTasks((prevTasks) => [...prevTasks, task]);  
  };

  const removeLoadingTask = (task: string) => {
    setLoadingTasks((prevTasks) => prevTasks.filter((t) => t !== task));    
  };

  useEffect(() => {
    if (!isAuthenticated && !isLoading) loginWithRedirect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading])


  // Fetch token once and store it in state
  useEffect(() => {
    const fetchToken = async () => {
      const fetchedToken = await getAccessTokenSilently();
      setToken(fetchedToken);
    };

    if (!token) {
      fetchToken();
    }
  }, [token, getAccessTokenSilently]);

  useEffect(() => {

    if (!!user && !!token) {
      const getScopes = async () => {
        //const token = await getAccessTokenSilently()
        const decodedToken: any = jwtDecode(token)
        dispatch(setScope(decodedToken.permissions))
        console.log(decodedToken.permissions)
      }

      dispatch(setUser(user))
      addLoadingTask("scopes")
      getScopes()
      removeLoadingTask("scopes")
      if (location.pathname === '/') {
        if (scopes?.includes("accounts:accounts:read-all")) {
          navigate('/accounts')
        } else {
          navigate('/myprofile')
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isAuthenticated])

  // Fetchers that dont need an active account
  useEffect(() => {

    const fetchData = async () => {

      const getArchiveRestrictions = async () => {
        try {
          if (!!token) {
            addLoadingTask("restrictions")
            getRestrictions(token,
              (response) => {
                dispatch(setRestrictions(response.data.results));
              },
              (error) => {
                console.error('Error reading restrictions:', error.message);
                dispatch(addNotification({ type: 'Error reading restrictions', description: error.response.data.details }));
              }
            );
          }
        } catch (error: any) {
          console.error('Error reading restrictions:', error.message);
          dispatch(addNotification({ type: 'Error reading restrictions', description: error.response.data.details }));
        } finally {
          removeLoadingTask("restrictions")
        }
      };

      const getMyProfile = async () => {
        if (!!token) {
          try {
            addLoadingTask("profile")
            const profile = await getMy(token, "profile");
            const newUser: User = {
              ...profile
            };
            dispatch(setActiveUser(newUser));
          } catch (error: any) {
            console.error("Error loading your profile", error);
            dispatch(addNotification({ type: 'error', description: JSON.stringify(error) }));
          } finally {
            removeLoadingTask("profile")
          }
        }
      };

      const getMyAccount = async () => {
        if (!!token) {
          try {
            addLoadingTask("my-accounts")
            const account = await getMy(token, "accounts");
            if (!scopes?.includes("accounts:accounts:read-all")) dispatch(setActiveAccount(account.results[0]));
          } catch (error: any) {
            console.error("Error loading your account", error);
            dispatch(addNotification({ type: 'error', description: JSON.stringify(error) }));
          } finally {
            removeLoadingTask("my-accounts")
          }
        }
      };

      const getMyContracts = async () => {
        if (!!token) {
          try {
            addLoadingTask("my-contracts")
            const contracts = await getMy(token, "contracts");
            dispatch(setMyContracts(contracts.results));
          } catch (error: any) {
            console.error("Error loading your contracts", error);
            dispatch(addNotification({ type: 'error', description: JSON.stringify(error) }));
          } finally {
            removeLoadingTask("my-contracts")
          }
        }
      };

      const getMyApiCredentials = async () => {
        if (!!token) {
          try {
            addLoadingTask("api-credentials")
            const response = await getMy(token, "api-credentials");
            dispatch(setApiCredentials(response.results));
          } catch (error: any) {
            console.error("Error loading your profile", error);
            dispatch(addNotification({ type: 'error', description: JSON.stringify(error) }));
          } finally {
            removeLoadingTask("api-credentials")
          }
        }
      }

      const fetchOrgs = async () => {
        if (!!token) {
          try {
            addLoadingTask("accounts")

            const { items } = await getAllAccounts(token);
            dispatch(setAccounts(items));
          } catch (error: any) {
            console.error('Error fetching organizations:', error.message);
            dispatch(addNotification({ type: 'error', description: error.response.data.details }));
          } finally {
            removeLoadingTask("accounts")
          }
        }
      };

      const fetchRoles = async () => {
        if (!!token) {
          try {
            addLoadingTask("roles")
            getRoles(token, (response) => {
              dispatch(setRoles(response.data.results));
            }, (error) => {
              console.error('Error fetching roles:', error.message);
              dispatch(addNotification({ type: 'error', description: error.response.data.details }));
            });
          } catch (error: any) {
            console.error('Error fetching roles:', error.message);
            dispatch(addNotification({ type: 'error', description: error.response.data.details }));
          } finally {
            removeLoadingTask("roles")
          }
        }
      };

      // Fetch all data in parallel
      const promises = [
        getMyProfile(),
        getMyAccount(),
        getMyContracts(),
        getMyApiCredentials(),
        getArchiveRestrictions(),
        fetchRoles(),
        scopes?.includes("accounts:accounts:read-all") && fetchOrgs(),
      ];
      await Promise.allSettled(promises);
    };

    if (scopes) {
      fetchData();
    }
  }, [dispatch, token, scopes, refreshCounter]);


  // Those fetchers that need an active_account
  useEffect(() => {    
    const fetchContracts = async () => {

      if (!!active_account && !!token) {

        try {
          addLoadingTask("contracts")
          const { items } = await getAllContracts(token, active_account?.account_id);
          dispatch(setContracts(items));
        } catch (error: any) {
          console.error('Error fetching account contracts:', error.message);
          dispatch(addNotification({ type: 'error', description: error.response.data.details }));
        } finally {
          removeLoadingTask("contracts")
        }
      }
    };

    const fetchUsers = async () => {
      if (!!active_account && !!token) {
        try {
          addLoadingTask("users")

          const { items } = await getAllUsers(token, active_account?.account_id);

          dispatch(setUsers(items));

        } catch (error: any) {
          console.error('Error loading users for the organization:', error.message);
          dispatch(addNotification({ type: 'error', description: "Error loading users: " + error.response.data.details }));
        } finally {
          removeLoadingTask("users")
        }
      }
    };

    const fetchOnboardingRules = async () => {
      if (!!token) {
        try {

          addLoadingTask("onboarding rules")
          getOnboardingRules(token,
            (response) => {
              dispatch(setOnboardingRules(response.data.results));
            },
            (error) => {
              console.error('Error fetching onboarding rules:', error.message);
              dispatch(addNotification({ type: 'error', description: "Error loading onboarding rules: " + error.message }));
            },
            active_account?.account_id
          );
        } catch (error: any) {
          console.error('Error fetching onboarding rules:', error.message);
          dispatch(addNotification({ type: 'error', description: "Error loading onboarding rules: " + error.response.data.details }));
        } finally {
          removeLoadingTask("onboarding rules")
        }
      }
    }

    const fetchApiCredentials = async () => {
      if (!!active_account) {
        if (!!token) {
          try {
            addLoadingTask("api credentials")

            getApiCredentials(token, active_account?.account_id,
              (response) => {
                dispatch(setApiCredentials(response.data.results));
              },
              (error) => {
                console.error("Error loading your profile", error);
                dispatch(addNotification({ type: 'error', description: JSON.stringify(error) }));
              },
            );

          } catch (error: any) {
            console.error('Error account users:', error.message);
            dispatch(addNotification({ type: 'error', description: error.response.data.details }));
          } finally {
            removeLoadingTask("api credentials")
          }
        }
      }
    };

    if (scopes?.includes("accounts:contracts:read-all") || scopes?.includes("accounts:contracts:read-account")) fetchContracts();
    if (scopes?.includes("accounts:users:read-all") || scopes?.includes("accounts:users:read")) fetchUsers(); // XXX: I had to had
    if (scopes?.includes("accounts:accounts:read-all")) fetchOnboardingRules(); // XXX: Are this the right scopes?    
    if (scopes?.includes("accounts:api-credentials:read-account") || scopes?.includes("accounts:api-credentials:read-all")) fetchApiCredentials(); // XXX: Are this the right scopes


  }, [dispatch, token, refreshCounter, active_account, scopes]);

  return (
    <ThemeProvider theme={theme[darkTheme ? 'dark' : 'light']}>
      {isAuthenticated ? <Menu /> : null}

      {isAuthenticated ? (
        <MainContainer>
          <Outlet />
        </MainContainer>
      ) : <UnauthenticatedHome />}

      {notifications.length > 0 ? (
        <ToastList
          notifications={notifications}
          onClose={(id: number) => dispatch(removeNotification(id))}
        />
      ) : null}

      {
        loadingTasks.length > 0 ? (
          <Modal isShown={loadingTasks.length > 0}>
            <ModalContainer>
              <LoadingWrapper>
                <Loader text={"Loading " + loadingTasks.join(", ")} />
              </LoadingWrapper>
            </ModalContainer>
          </Modal>

        ) : null
      }

    </ThemeProvider>


  )
}

export default ConsoleUI
