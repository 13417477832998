import { useAuth0 } from "@auth0/auth0-react"

import { SatellogicLogo } from 'components/satellogicLogo'
import { Loader } from "components/loader"

import { Container, LoaderWrapper, Title } from './unauthenticatedHome.styles'

export const UnauthenticatedHome = () => {
  const { isLoading } = useAuth0()

  return (
    <Container>
      <LoaderWrapper>
        <SatellogicLogo className="logo" />

        <Title>Welcome to Console UI</Title>

        <Loader darkBackground text={isLoading ? "Login in" : 'Redirecting to login'} />
      </LoaderWrapper>
    </Container>
  )
}
