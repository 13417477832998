import styled from '@emotion/styled/macro'

interface ToastActionContainerProps {
  type: 'success' | 'error' | string;
  open: boolean;
}

export const ToastIcon = styled.div`
  background-color: ${(props: any) => props.theme.alert};
  border-radius: ${(props: any) => props.theme.radius.full};
  display: flex;
  margin-right: ${(props: any) => props.theme.spacing.large};
  height: 20px;
  padding: 2px;
  width: 20px;
`

export const ToastTitle = styled.p`
  font-weight: ${(props: any) => props.theme.weight.semiBold};
  margin-bottom: ${(props: any) => props.theme.spacing.small};
`

export const ToastDialogDiv = styled.div`
  align-items: center;
  background-color: ${(props: any) => props.theme.grayscale30};
  background-position: ${(props: any) => props.theme.spacing.large10};
  border-left: ${(props: any) => props.theme.spacing.small} solid ${(props: any) => props.theme.alert};
  border-radius: ${(props: any) => props.theme.radius.small};
  color: ${(props: any) => props.theme.body};
  display: none;
  margin-top: 10px;
  overflow: hidden;
  padding: ${(props: any) => props.theme.spacing.large};
  pointer-events: none;
  position: relative;
  &.open {
    display: flex;
  }
`

export const ToastDialogMessage = styled.div`
  ${(props: any) => props.theme.texts.caption};
`

export const ToastDialogIcon = styled.div`
  background-color: ${(props: any) => props.theme.alert};
  border-radius: ${(props: any) => props.theme.radius.full};
  display: flex;
  margin-right: ${(props: any) => props.theme.spacing.large};
  height: 20px;
  padding: 2px;
  width: 20px;
`

export const ToastActionContainer = styled.div<ToastActionContainerProps>`
  align-items: center;
  background-color: ${(props: any) => props.theme[props.type === 'success' ? `${props.type}Opacity` : props.type]};
  border-radius: ${(props: any) => props.theme.radius.medium};
  color: ${(props: any) => props.type === 'error' ? props.theme.global10 : props.theme.secondary70};
  display: ${(props: any) => props.open ? 'flex' : 'none'};
  justify-content: space-between;
  margin-top: 10px;
  padding: ${(props: any) => props.theme.spacing.large};
  position: relative;
`;

export const ToastActionDiv = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-grow: 1;
`

export const ToastActionMessage = styled.div`
  ${(props: any) => props.theme.texts.caption};
  font-weight: ${(props: any) => props.theme.weight.semiBold};
   overflow: hidden;                   /* Ensures content doesn't overflow */
  text-overflow: ellipsis;            /* Adds ellipses when content overflows */
  display: -webkit-box;               /* Flexbox for multiline ellipsis */
  -webkit-line-clamp: 3;              /* Limits to 3 lines */
  -webkit-box-orient: vertical;       /* Sets the box orientation to vertical */
  line-height: 1.5;                   /* Adjust line height if necessary */
  max-height: calc(1.5em * 3);        /* Adjust based on line-height and number of lines */
`;

export const ToastActionIcon = styled.div((props: any) => {
  const getColor = () => {
    if (props.type === 'error') {
      return props.theme.error
    } else if (props.type === 'success') {
      return props.theme.global10
    } else {
      return props.theme.secondary70
    }
  }
  return {
    backgroundColor: props.theme[props.type === 'success' ? props.type : `${props.type}Opacity`],
    borderRadius: props.theme.radius.full,
    color: getColor(),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: props.theme.spacing.medium,
    padding: props.theme.spacing.small,
    height: '24px',
    width: '24px',
  }
})

export const ToastActionCloseButton = styled.span((props: any) => ({
  width: '16px',
  color: props.type === 'error' ? props.theme.global20 : props.theme.secondary60,
  cursor: 'pointer'
}))

export const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 9999999999;
  position: fixed;
  top: ${(props: any) => props.theme.spacing.large40};
  padding: 0 ${(props: any) => props.theme.spacing.large15};
`
