import { ContentWrapper, LoadingWrapper, ModalContainer, Table, TableColumn, TableRow, TableWrapper, Title } from 'views/views.styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { renderListAsPill, renderDateCell, transformDateTime } from 'config/helpers';
import { Button } from 'components/button';
import { Modal } from 'components/modal';
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Contract, Role, UserApiCredential } from 'typings'
import { DetailsModal } from '../apiCredentials/components/detailsModal/detailsModal'
import { addNotification } from 'stores/notificationsStore/notificationsStoreSlice'
import { Loader } from 'components/loader';
import { Pill } from 'components/pill';
import { Dialog } from 'components/dialog';
import { TrashIcon } from '@heroicons/react/24/outline';
import { TableCRUD } from 'components/tableCRUD';
import { generateApiCredential, resetPassword, revokeApiCredential } from 'services/account-service/userServices';
import { refreshTrigger } from "stores/globalStore/globalStoreSlice";
import { PrincipalInterfaces } from 'services/account-service/rolesServices';

export const MyProfile = () => {

    //const notifications = useSelector((state: RootState) => state.notificationsStore.notifications)    
    const dispatch = useDispatch()

    const { getAccessTokenSilently } = useAuth0()
    const user = useSelector((state: RootState) => state.userStore.active_user);

    const contracts = useSelector((state: RootState) => state.userStore.my_contracts);
    const activeUser = useSelector((state: RootState) => state.userStore.active_user);
    const activeAccount = useSelector((state: RootState) => state.userStore.active_account);
    const api_credentials = useSelector((state: RootState) => state.userStore.apiCredentials);

    const roles = useSelector((state: RootState) => state.userStore.roles);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showResetDialog, setShowResetDialog] = useState<boolean>(false);


    const [showCredentialModal, setShowCredentialModal] = useState<boolean>(false);
    const [activeCredential, setActiveCredential] = useState<UserApiCredential | undefined>(undefined);

    const showResetPassword = user?.principal_id?.split('|')[0] === 'auth0'

    const newApiCredential = async (data: any): Promise<{ success: boolean; message: string }> => {
        const token = await getAccessTokenSilently()

        if (Object.keys(data).includes("role_ids")) {
            data['role_ids'] = data['role_ids'].split(",").map((x: string) => x.trim())
        } else {
            data['role_ids'] = []
        }
        if (Object.keys(data).includes("contract_ids")) {
            data['contract_ids'] = data['contract_ids'].split(",").map((x: string) => x.trim())
        } else {
            data['contract_ids'] = []
        }

        return new Promise<{ success: boolean; message: string }>((resolve, reject) => {
            generateApiCredential(
                token,
                user?.account!,
                data,
                (response) => {
                    const credential: UserApiCredential = {
                        ...response.data
                    }
                    setActiveCredential(credential);
                    setShowCredentialModal(true);
                    dispatch(refreshTrigger());
                    resolve({
                        success: true,
                        message: "Credential succesfully created",
                    });
                },
                (error) => {
                    console.error(error)
                    reject({
                        success: false,
                        message: `Couldn´t create credential: ${error.response.data.message}`,
                    });
                }
            )
        })
    }

    const removeApiCredential = async (credentialName: string): Promise<{ success: boolean; message: string }> => {
        const token = await getAccessTokenSilently()

        return new Promise<{ success: boolean; message: string }>((resolve, reject) => {
            if (!!activeUser?.account) {
                revokeApiCredential(
                    token,
                    activeUser.account,
                    credentialName,
                    () => {
                        dispatch(refreshTrigger());
                        resolve({
                            success: true,
                            message: "Credential succesfully created",
                        });
                    },
                    (error) => {
                        reject({
                            success: false,
                            message: `Couldn´t revoke credential: ${error.response.data.message}`,
                        });
                    }
                )
            }
        }
        )
    }

    const handleResetPassword = async () => {
        const token = await getAccessTokenSilently();
        if (user?.account && user.principal_id) {

            setIsLoading(true);

            const result = resetPassword(token, user.account, user.principal_id);

            if (result instanceof Promise) {

                result
                    .then(() => {
                        setIsLoading(false);
                        dispatch(addNotification({ type: 'success', description: "successful operation" }));
                    })
                    .catch(error => {
                        setIsLoading(false);
                        console.error(`Reset password operation failed: `, error);
                        dispatch(addNotification({ type: 'error', description: `Reset password operation failed: ` + error.message + ", " + error.response.data.details }));
                    });
            }
        }
    };

    const roleOptions = roles ? Object.entries(roles.filter((role: Role) => role.interface === PrincipalInterfaces.REST_API)).map(([key, role]) => ({
        value: role.role_id,
        label: role.name,
    })) : undefined;

    const contractOptions = contracts ? contracts.map((contract: Contract) => ({
        value: contract.contract_id,
        label: contract.name,
    })) : undefined;


    return (
        <>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <Title> My profile</Title>
                {!!user &&
                    <>
                        <ContentWrapper full>
                            <TableWrapper>
                                <Table style={{ tableLayout: "fixed" }}>
                                    <tbody>
                                        <TableRow>
                                            <TableColumn width={'20%'}>
                                                <b>Name</b>
                                            </TableColumn>
                                            <TableColumn>
                                                {user?.name}
                                            </TableColumn>
                                        </TableRow>


                                        <TableRow>
                                            <TableColumn >
                                                <b>E-mail</b>
                                            </TableColumn>
                                            <TableColumn>
                                                {user?.email}
                                            </TableColumn>
                                        </TableRow>

                                        <TableRow>
                                            <TableColumn width={'20%'}>
                                                <b>Principal id</b>
                                            </TableColumn>
                                            <TableColumn>{user?.principal_id}</TableColumn>
                                        </TableRow>

                                        <TableRow>
                                            <TableColumn>
                                                <b>Created</b>
                                            </TableColumn>
                                            <TableColumn>
                                                {user.created_at ? transformDateTime(user?.created_at) : ""}
                                            </TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn>
                                                <b>Last modified</b>
                                            </TableColumn>
                                            <TableColumn>
                                                {user.updated_at ? transformDateTime(user?.updated_at) : ""}
                                            </TableColumn>
                                        </TableRow>

                                        <TableRow>
                                            <TableColumn>
                                                <b>Member of</b>
                                            </TableColumn>
                                            <TableColumn>
                                                {activeAccount?.name}
                                            </TableColumn>
                                        </TableRow>


                                        {
                                            !!contracts &&
                                            <TableRow>
                                                <TableColumn>
                                                    <b>Contracts</b>
                                                </TableColumn>
                                                <TableColumn>
                                                    <div style={{ maxWidth: "100%", width: "100%" }}>
                                                        {
                                                            contracts.map((x) => <Pill key={x.contract_id} label={x.name ? x.name : ""}></Pill>)
                                                        }
                                                    </div>
                                                </TableColumn>
                                            </TableRow>
                                        }

                                    </tbody>
                                </Table>
                            </TableWrapper>

                            {showResetPassword &&
                                <div style={{ display: 'flex', gap: '10px', margin: '10px' }}>
                                    <Button
                                        size="sm"
                                        text="Reset password"
                                        onClick={() => setShowResetDialog(true)}
                                    />

                                </div>
                            }
                        </ContentWrapper>

                        <ContentWrapper style={{ height: "500px" }} full>
                            <TableCRUD
                                title={"API Credentials"}
                                data={{ items: api_credentials || [], total: api_credentials?.length || 999 }}
                                columns={[
                                    { label: "Name", dataKey: "name" },
                                    { label: "ID", dataKey: "principal_id" },
                                    {
                                        label: "Roles", dataKey: "role_ids",
                                        renderer: ({ cellData }: { cellData?: any }) =>
                                            renderListAsPill({
                                                data: cellData?.map((item: any) => ({
                                                    id: item,
                                                    label: roles?.find((role: any) => role.role_id === item)?.name || item
                                                }))
                                            })
                                    },
                                    {
                                        label: "Contracts", dataKey: "contracts_ids",
                                        renderer: ({ cellData }: { cellData?: any }) =>
                                            renderListAsPill({
                                                data: cellData?.map((item: any) => ({
                                                    id: item,
                                                    label: contracts?.find((role: any) => role.contract_id === item)?.name || item
                                                }))
                                            })
                                    },
                                    { label: "Created", dataKey: "created_at", renderer: renderDateCell },
                                    { label: "Updated", dataKey: "updated_at", renderer: renderDateCell },
                                ]}
                                idKey="name"
                                actions={{

                                    create: {
                                        actionHandler: newApiCredential,
                                        fields: [
                                            {
                                                name: 'name',
                                                label: 'Name',
                                                type: 'text',
                                                validation: { required: true, minLength: 3, message: 'Name is required' },
                                            },
                                            {
                                                name: 'role_ids',
                                                label: 'Roles',
                                                type: 'list',
                                                validation: { options: roleOptions },
                                            },
                                            {
                                                name: 'contract_ids',
                                                label: 'Assigned contracts',
                                                type: 'list',
                                                validation: { options: contractOptions },
                                            },
                                        ]
                                    },
                                    revoke: {
                                        tooltip: "Revoke credentials",
                                        icon: <TrashIcon />,
                                        confirm: true,
                                        confirmationText: "Are you sure you want to revoke your credential {name}?",
                                        confirmTitle: "Revoke credential",
                                        actionHandler: (credential: any) => { return removeApiCredential(credential.principal_id) }
                                    },

                                }}
                            />
                        </ContentWrapper>

                    </>
                }
            </div>

            {isLoading && <Modal isShown={isLoading}>
                <ModalContainer>
                    <LoadingWrapper>
                        <Loader text={"Loading data..."} />
                    </LoadingWrapper>
                </ModalContainer>
            </Modal>}

            <DetailsModal
                apiCredential={activeCredential}
                isShown={showCredentialModal}
                onClose={() => {
                    setShowCredentialModal(false);
                    setActiveCredential(undefined)
                    refreshTrigger();
                }}
            />


            <Dialog
                cancel={{
                    text: 'Cancel',
                    action: () => {
                        setShowResetDialog(false)
                    }
                }}
                confirmation={{
                    text: 'Confirm',
                    action: () => {
                        setShowResetDialog(false)
                        handleResetPassword();
                    }
                }}
                description={<>
                    Are you sure you want to reset your password? This will send you an email to create a new one.
                </>}
                title={"Reset password"}
                type="warning"
                isShown={showResetDialog}
            />

        </>
    )
}