import axios from 'axios'
import { APIURL } from 'config/helpers'

export const getOnboardingRules = (
    token: string,
    onSuccess: (r: any) => void,
    onError: (r: any) => void,
    account_id?: string, // to get only those for this account
) => {    
    axios
        .get(`${APIURL}/onboarding/rules`, {
            headers: { Authorization: `Bearer ${token}` },
            params: {
                account_id: account_id
            }
        })
        .then(response => {
            onSuccess(response)
        })
        .catch(error => {
            onError(error)
        })
}

export const getOnboardingRule = (
    token: string,
    onboarding_rule_id: string,
    onSuccess: (r: any) => void,
    onError: (r: any) => void
) => {
    axios
        .get(`${APIURL}/onboarding/rules/${onboarding_rule_id}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then(response => {
            onSuccess(response)
        })
        .catch(error => {
            onError(error)
        })
}

// Promise based instead of callbacks

export const deleteOnboardingRule = async (
    token: string,
    onboarding_rule_id: string,
): Promise<any> => {
    try {
        const response = await axios
            .delete(`${APIURL}/onboarding/rules/${onboarding_rule_id}`, {
                headers: { Authorization: `Bearer ${token}` }
            })
        return response
    } catch (error) {
        throw error
    }
}

export const createOnboardingRule = async (
    token: string,
    payload: any,
): Promise<any> => {
    try {
        const response = await axios
            .post(`${APIURL}/onboarding/rules/`, payload, {
                headers: { Authorization: `Bearer ${token}` }
            })
        return response
    } catch (error) {
        throw error
    }
};