import styled from '@emotion/styled/macro'

export const ErrorPageContainer = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: 0 auto;
`
