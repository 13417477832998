/* eslint-disable react/display-name */
import { createBrowserRouter } from 'react-router-dom'

import ConsoleUI from 'consoleUI'
import { ErrorPage } from 'views/errorPage'
import { ScopedView } from 'views/scopedView'
import { ApiCredentials2 } from 'views/apiCredentials'
import { Users } from 'views/users'
import { Contracts } from 'views/contracts'
import { Documentation } from 'views/documentation'
import { Organizations } from "views/organizations";
import { ContractInfo } from "views/contracts/contractPage"
import { OrganizationPage } from "views/organizations/organizationPage"
import { UserPage } from 'views/users/userPage'
import { MyProfile } from 'views/myprofile'
import { RestrictionPage } from 'views/restrictions/restrictionPage'
import { Restrictions } from 'views/restrictions'
import { OnBoardingRules } from 'views/onboardingrules'
import { OnBoardingRulePage } from 'views/onboardingrules/onboardingRulePage'

//import { Restrictions } from 'views/restrictions'

export const router = createBrowserRouter([
  {
    path: "/",
    element: <ConsoleUI />,
    errorElement: <ErrorPage />,
    children: [
      // ---------- Organizations -----------
      {
        path: "organizations",
        element: (
          <ScopedView
            scopes={["accounts:accounts:read-all"]}
            view={<Organizations />}
          />
        ),
      },
      {
        path: "organizations/:account_id",
        element: (
          <ScopedView
            scopes={["accounts:accounts:read"]}
            view={<OrganizationPage />}
          />
        ),
      },

      // ----------- Contracts -----------
      {
        path: "contracts/:account_id",
        element: (
          <ScopedView scopes={["accounts:contracts:read-all","accounts:contracts:read-account"]} view={<Contracts />} />
        ),
      },
      {
        path: "contracts/:account_id/:contract_id",
        element: (
          <ScopedView scopes={["accounts:contracts:read"]} view={<ContractInfo />} />
        ),
      },

      // ----------- Users -----------
      {
        path: "users/:account_id",
        element: (
          <ScopedView scopes={["accounts:users:read-all", "accounts:users:read"]} view={<Users />} />
        ),
      },
      {
        path: "users/:account_id/:principal_id",
        element: (
          <ScopedView scopes={["accounts:users:read", "accounts:principals:profile"]} view={<UserPage />} />
        ),
      },      

      // ----------- API CREDENTIALS -----------
      {
        path: "api-credentials/:account_id",
        element: (
          <ScopedView scopes={["accounts:api-credentials:read-all", "accounts:api-credentials:read-account"]} view={<ApiCredentials2 />} />
        ),
      },
      
      // ----------- RESTRICTIONS -----------    
      {
        path: "restrictions/",
        element: (
          <ScopedView scopes={["accounts:contracts:read-all"]} view={<Restrictions />} />
        ),
      },
      {
        path: "restrictions/:restriction_id",
        element: (
          <ScopedView scopes={["accounts:contracts:read"]} view={<RestrictionPage />} />
        ),
      },
      
      // ----------- On boarding rules -----------    
      {
        path: "onboardingrules/",
        element: (
          <ScopedView scopes={["accounts:users:write-all", "accounts:users:write"]} view={<OnBoardingRules />} />
        ),
      },
      {
        path: "onboardingrules/:onboarding_rule_id",
        element: (
          <ScopedView scopes={["accounts:users:write"]} view={<OnBoardingRulePage />} />
        ),
      },
                    
      {
        path: "docs",
        element: <Documentation />,
      },

      {
        path: "myprofile",
        element: <MyProfile/>,
      },
    ],
  },
]);
