import React from "react"
//import { ThemeProvider } from "@emotion/react"
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"

//import { theme } from "themes/default"

//import { ContentWrapper } from "views/views.styles"

export const Documentation = () => {
  const spec = require('./gateway-service.json')
  return (
    <SwaggerUI spec={spec} />
  )
}
