import styled from '@emotion/styled/macro'

export const ApiText = styled.p`
  ${(props: any) => props.theme.texts.caption};
  color: ${(props: any) => props.theme.bodyGray};
`

export const ApiCredentialContent = styled.div`
  padding: ${(props: any) => props.theme.spacing.large10};
  .client-secret--warning {
    margin-bottom: ${(props: any) => props.theme.spacing.large15};
  }
`

export const Caption = styled.p`
  ${(props: any) => props.theme.texts.body2};
  color: ${(props: any) => props.theme.body};
  margin-bottom: ${(props: any) => props.theme.spacing.large15};
`

export const Disclaimer = styled.p`
  ${(props: any) => props.theme.texts.small};
  color: ${(props: any) => props.theme.body};
  font-weight: ${(props: any) => props.theme.weight.semiBold};
  margin-top: ${(props: any) => props.theme.spacing.large10};
  a {
    color: ${(props: any) => props.theme.primary10};
    margin: 0 3px;
    text-decoration: none;
    transition: opacity 0.2s ease-in-out;
    &:hover {
      opacity: 0.75;
    }
  }
`

export const CredentialInputWrapper = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  gap: ${(props: any) => props.theme.spacing.large10};
  margin-bottom: ${(props: any) => props.theme.spacing.large15};
`
