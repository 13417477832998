
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from "react-router-dom";
import { InputSwitch } from 'components/inputSwitch'
import { useAuth0 } from "@auth0/auth0-react";

import Select from 'react-select'

import {
    ContentWrapper,
    Table,
    TableRow,
    TableColumn,
    ModalContainer,
    LoadingWrapper,
    TableWrapper,
} from 'views/views.styles'

import "react-sliding-pane/dist/react-sliding-pane.css";
import { Button } from 'components/button';
import { activateUser, assignRoles, deactivateUser, getUser, removeRoles, resetPassword } from 'services/account-service/userServices';
import { addNotification } from 'stores/notificationsStore/notificationsStoreSlice';
import { Modal } from 'components/modal';
import { Loader } from 'components/loader';
import { Contract } from 'typings';
import { Dialog } from 'components/dialog';
import { RootState } from 'store';
import { transformDateTime } from 'config/helpers';
import { assignContract, dissociateContract, getContractsForUser } from 'services/account-service/contractServices';
import { setActiveUser } from 'stores/userStore/userStoreSlice';

export const UserPage = () => {

    const { account_id, principal_id } = useParams();

    const dispatch = useDispatch()

    const [searchParams, setSearchParams] = useSearchParams();
    const isEditMode = searchParams.get('edit') === 'true'; // Check if 'edit' param is present and set to 'true'

    const user = useSelector((state: RootState) => state.userStore.active_user);
    const roles = useSelector((state: RootState) => state.userStore.roles);
    const accounts = useSelector((state: RootState) => state.userStore.accounts);
    const contracts = useSelector((state: RootState) => state.userStore.contracts);

    // This wont work if standalone
    const roleOptions = roles ? (Object.entries(roles).map(([key, role]) => ({
        value: role.role_id,
        label: role.name,
    }))) : undefined;

    // This wont work if standalone
    const contractOptions = contracts ? (contracts.map((contract: Contract) => ({
        value: contract.contract_id,
        label: contract.name,
        archive_configuration: {
            collections: [],
            restrictions: [],
            quality_tiers: undefined,
        },
        delivery_configuration: {},
        tasking_configuration: {}
    })) as Contract[]) : undefined;

    let userRoles: any[] = [];
    if (!!roles && !!user?.role_ids) {
        userRoles = roles
            .filter(role => user?.role_ids?.includes(role.role_id))
            .map(role => ({
                value: role.role_id,
                label: role.name,
            }));
    }

    const [rolesValues, setRolesValues] = useState<any | undefined>(userRoles);
    const [contractsValues, setContractValues] = useState<any | undefined>(undefined);
    const [originalContracts, setOriginalContracts] = useState<any | undefined>(undefined);

    const [formData, setFormData] = useState({
        status: user?.status === 'ACTIVE',
        roles: userRoles.map(x => { return x.value }) || [],
        contracts: [],
    });

    const [showLoading, setShowLoading] = useState(false);
    const [showResetDialog, setShowResetDialog] = useState(false);

    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {

        const fectchRelatedContracts = async () => {
            console.log("Fetching contracts data")
            if (!!account_id && !!principal_id) {
                const token = await getAccessTokenSilently();
                getContractsForUser(token, account_id, principal_id,
                    (response) => {
                        const userContracts = response.data.results;

                        const selectedContracts = userContracts
                            .map((contract: { contract_id: any; name: any; }) => ({
                                value: contract.contract_id,
                                label: contract.name,
                            }));
                        setOriginalContracts(selectedContracts);
                        setContractValues(selectedContracts);
                        setFormData(prevFormData => ({
                            ...prevFormData,
                            contracts: selectedContracts.map((x: { value: any; }) => { return x.value }) || [],
                        }));
                        setShowLoading(false);
                    },
                    (error) => {
                        //console.error("Cannot load related contracts", error.response),               
                        dispatch(addNotification({ type: 'error', description: error.response.data.details }));
                        setShowLoading(false);
                    }
                )
            }
        }

        const fetchUser = async () => {
            console.log("Fetching user data")
            if (!!account_id && !!principal_id) {
                const token = await getAccessTokenSilently();
                getUser(token, account_id, principal_id,
                    (response) => {
                        dispatch(setActiveUser(response.data));
                        fectchRelatedContracts();
                    },
                    (error) => {
                        console.error('Error getting user information', error.message);
                        dispatch(addNotification({ type: 'error', description: error.response.data.details }));
                        setShowLoading(false);
                    },
                )
            }
        }

        setShowLoading(true);
        fetchUser();

    }, [getAccessTokenSilently, dispatch, account_id, principal_id])

    
    // Handle switch change separately
    const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.checked,
        });
        return e.target.value === "on"
    };

    const handleSubmit = async () => {
        const transformedData = {
            ...formData,
            status: formData.status ? 'ACTIVE' : 'INACTIVE',
        };
        console.log(transformedData)

        if (user?.principal_id && account_id) {

            const token = await getAccessTokenSilently();
            const transformedData = {
                ...formData,
                status: formData.status ? 'ACTIVE' : 'INACTIVE',
            };

            try {
                setShowLoading(true);
                //await updateUser(token, account_id, user?.principal_id, transformedData);

                /* Status update */
                try {
                    if (user.status === "ACTIVE" && transformedData.status === "INACTIVE") await deactivateUser(token, account_id, user.principal_id);
                    if (user.status === "INACTIVE" && transformedData.status === "ACTIVE") await activateUser(token, account_id, user.principal_id);
                    dispatch(addNotification({ type: 'success', description: "Account status updated succesfully" }));
                } catch (error: any) {
                    console.log(error)
                    dispatch(addNotification({ type: 'error', description: "Error updating user status: " + error.response?.data?.message + " " + JSON.stringify(error.response?.data?.errors), }));
                }

                /* Roles assignment update */

                try {
                    const newRoles = transformedData.roles.filter(newItem =>
                        !userRoles.map(x => { return x.value }).some(oldItem => oldItem === newItem)
                    );

                    if (newRoles.length > 0) await assignRoles(token, account_id, user.principal_id, newRoles);

                    const removedRoles = userRoles.map(x => { return x.value }).filter(oldItem =>
                        !transformedData.roles.some(newItem => newItem === oldItem)
                    );
                    if (removedRoles.length > 0) await removeRoles(token, account_id, user.principal_id, removedRoles);

                    if (removedRoles.length > 0 || newRoles.length > 0) dispatch(addNotification({ type: 'success', description: "Roles updated succesfully" }));
                } catch (error: any) {
                    dispatch(addNotification({ type: 'error', description: "Error updating roles: " + error.response?.data?.message + " " + JSON.stringify(error.response?.data?.errors), }));
                }

                /* Contracts assignment update */

                try {
                    console.log("original", originalContracts)
                    console.log("form", transformedData.contracts)
                    const newContracts = transformedData.contracts.filter(newItem =>
                        !originalContracts.map((x: { value: any; }) => { return x.value }).some((oldItem: any) => oldItem === newItem)
                    );
                    console.log("NEW", newContracts)

                    newContracts.forEach(contract_id => {
                        if (user.principal_id) {
                            assignContract(token, account_id, contract_id, user.principal_id);
                        }
                    })

                    const removedContracts = originalContracts.map((x: { value: any; }) => { return x.value }).filter((oldItem: any) =>
                        !transformedData.contracts.some(newItem => newItem === oldItem)
                    );
                    console.log("REMOVED", removedContracts)
                    removedContracts.forEach((contract_id: any) => {
                        if (user.principal_id) {
                            dissociateContract(token, account_id, contract_id, user.principal_id);
                        }
                    });
                    if (removedContracts.length > 0 || newContracts.length > 0) dispatch(addNotification({ type: 'success', description: "Contracts updated succesfully" }));

                } catch (error: any) {
                    dispatch(addNotification({ type: 'error', description: "Error updating roles: " + error.response?.data?.message + " " + JSON.stringify(error.response?.data?.errors), }));
                }

                setSearchParams({}); // Remove the 'edit' parameter to exit edit mode

            } catch (error: any) {
                console.log(error)
                dispatch(addNotification({ type: 'error', description: "Error updating: " + error.response?.data?.message + " " + JSON.stringify(error.response?.data?.errors) || 'Failed to update account', }));
            } finally {
                setShowLoading(false);
            }
        }

    };

    // Handle cancel action
    const handleCancel = () => {
        setFormData({
            status: user?.status === 'ACTIVE',
            roles: userRoles.map(x => { return x.value }) || [],
            contracts: originalContracts.map((x: { value: any; }) => { return x.value }) || [],
        });
        setSearchParams({}); // Remove the 'edit' parameter to exit edit mode
        setRolesValues(userRoles); // Restore the original ones
        setContractValues(originalContracts);
    };

    const handleResetPassword = async () => {
        const token = await getAccessTokenSilently();
        if (account_id && user?.principal_id) {

            setShowLoading(true);

            const result = resetPassword(token, account_id, user.principal_id);

            if (result instanceof Promise) {

                result
                    .then(() => {
                        setShowLoading(false);
                        dispatch(addNotification({ type: 'success', description: "successful operation" }));
                    })
                    .catch(error => {
                        setShowLoading(false);
                        console.error(`Reset password operation failed: `, error);
                        dispatch(addNotification({ type: 'error', description: `Reset password operation failed: ` + error.message + ", " + error.response.data.message }));
                    });
            }
        }
    };

    const handleRolesChange = (data: any) => {
        setRolesValues(data);
        setFormData({
            ...formData,
            roles: data.map((x: { value: any; }) => { return x.value })
        });
    }

    const handleContractsChange = (data: any) => {
        setContractValues(data);
        setFormData({
            ...formData,
            contracts: data.map((x: { value: any; }) => { return x.value })
        });
    }

    // Toggle edit mode by modifying query parameters
    const toggleEditMode = () => {
        if (isEditMode) {
            setSearchParams({}); // Remove 'edit' parameter to exit edit mode
        } else {
            setSearchParams({ edit: 'true' }); // Add 'edit=true' to enter edit mode
        }
    };


    return (
        <>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <Link to={`/users/${account_id}`}>← Back to users</Link>

                {!!user && <ContentWrapper full>
                    <TableWrapper>
                        <Table style={{ tableLayout: "fixed" }}>
                            <TableRow>
                                <TableColumn width={'20%'}>
                                    <b>Principal id</b>
                                </TableColumn>
                                <TableColumn>{user?.principal_id}</TableColumn>
                            </TableRow>

                            <TableRow>
                                <TableColumn>
                                    <b>Created</b>
                                </TableColumn>
                                <TableColumn>
                                    {user.created_at ? transformDateTime(user?.created_at) : ""}
                                </TableColumn>
                            </TableRow>
                            <TableRow>
                                <TableColumn>
                                    <b>Last modified</b>
                                </TableColumn>
                                <TableColumn>
                                    {user.updated_at ? transformDateTime(user?.updated_at) : ""}
                                </TableColumn>
                            </TableRow>
                            {user.account && accounts &&
                                <TableRow>
                                    <TableColumn>
                                        <b>Member of account</b>
                                    </TableColumn>
                                    <TableColumn>
                                        <Link to={`/accounts/${user.account}`}> <>{accounts.find(account => account.account_id === user.account)?.name}</></Link>
                                    </TableColumn> {/* TODO: add link to the account */}
                                </TableRow>
                            }
                            <TableRow>
                                <TableColumn>
                                    <b>Name</b>
                                </TableColumn>
                                <TableColumn>
                                    {user?.name}
                                </TableColumn>
                            </TableRow>


                            <TableRow>
                                <TableColumn >
                                    <b>E-mail</b>
                                </TableColumn>
                                <TableColumn>
                                    {user?.email}
                                </TableColumn>
                            </TableRow>

                            {!!userRoles && roleOptions &&
                                <TableRow>
                                    <TableColumn>
                                        <b>Roles</b>
                                    </TableColumn>
                                    <TableColumn>
                                        <div style={{ maxWidth: "100%", width: "100%" }}>
                                            <Select
                                                isSearchable
                                                isDisabled={!isEditMode}
                                                value={rolesValues}
                                                options={roleOptions}
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    menuPortal: base => ({ ...base, zIndex: 9999995 }),
                                                    multiValueRemove: base => ({ ...base })
                                                }}
                                                onChange={(data) => {
                                                    handleRolesChange(data);
                                                }}
                                                isMulti
                                                placeholder={"No roles assigned"}
                                            />
                                        </div>

                                    </TableColumn>
                                </TableRow>
                            }


                            {!!contractOptions &&
                                <TableRow>
                                    <TableColumn>
                                        <b>Contracts</b>
                                    </TableColumn>
                                    <TableColumn>
                                        <div style={{ maxWidth: "100%", width: "100%" }}>
                                            <Select
                                                isSearchable
                                                isDisabled={!isEditMode}
                                                value={contractsValues}
                                                options={contractOptions}
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    menuPortal: base => ({ ...base, zIndex: 9999995 }),
                                                    multiValueRemove: base => ({ ...base })
                                                }}
                                                onChange={(data) => {
                                                    handleContractsChange(data);
                                                }}
                                                isMulti
                                                placeholder={"No contracts assigned"}
                                            />
                                        </div>

                                    </TableColumn>
                                </TableRow>
                            }

                            <TableRow>
                                <TableColumn width={'100px'}>
                                    <b>Status</b>
                                </TableColumn>
                                <TableColumn>
                                    {isEditMode ? (
                                        <InputSwitch
                                            checked={formData.status}
                                            name="status"
                                            small
                                            onChange={handleSwitchChange}
                                        />
                                    ) : (
                                        user?.status
                                    )}
                                </TableColumn>
                            </TableRow>

                        </Table>
                    </TableWrapper>

                    <div style={{ display: 'flex', gap: '10px', margin: '10px' }}>
                        <Button
                            size="sm"
                            text="Reset password"
                            onClick={() => setShowResetDialog(true)}
                        />

                        {!isEditMode ? (
                            <Button
                                size="sm"
                                text="Edit"
                                onClick={toggleEditMode}
                            />
                        ) : (
                            <>
                                <Button
                                    size="sm"
                                    text="Save"
                                    onClick={handleSubmit}
                                />
                                <Button
                                    size="sm"
                                    text="Cancel"
                                    onClick={handleCancel}
                                />
                            </>
                        )}
                    </div>
                </ContentWrapper>
                }
            </div>

            <Modal isShown={showLoading}>
                <ModalContainer>
                    <LoadingWrapper>
                        <Loader text={"Loading data..."} />
                    </LoadingWrapper>
                </ModalContainer>
            </Modal>


            <Dialog
                cancel={{
                    text: 'Cancel',
                    action: () => {
                        setShowResetDialog(false)
                    }
                }}
                confirmation={{
                    text: 'Confirm',
                    action: () => {
                        setShowResetDialog(false)
                        handleResetPassword();
                    }
                }}
                description={<>
                    Are you sure you want to reset user password? This will send an email to for the user to create a new one.
                </>}
                title={"Reset password"}
                type="warning"
                isShown={showResetDialog}
            />
        </>
    );
};