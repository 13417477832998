import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAuth0 } from "@auth0/auth0-react"
import { NavLink, useNavigate } from 'react-router-dom'

import type { RootState } from 'store'

import { MoonIcon, ArrowLeftEndOnRectangleIcon, DocumentTextIcon, Cog6ToothIcon } from '@heroicons/react/24/outline'
import { UserCircleIcon, Bars3Icon } from '@heroicons/react/24/solid'

import { InputSwitch } from 'components/inputSwitch'
import { SatellogicLogo } from 'components/satellogicLogo'


import {
  HeaderContainer,
  Navigation,
  NavigationItem,
  UserMenuWrapper,
  UserMenu,
  UserSubmenu,
  SubmenuItem,
} from './menu.styles'

import { classes } from 'config/helpers'
import { useLocalStorage } from 'config/hooks'
import { Tooltip } from 'components/tooltip'
import { Title } from 'views/views.styles'


export const Menu = () => {
  const { logout } = useAuth0()

  const navigate = useNavigate()

  const [showUserSubmenu, setShowUserSubmenu] = useState<boolean>(false)

  const userMenuWrapperRef = useRef<HTMLDivElement>(null)

  const [darkTheme, setDarkTheme] = useLocalStorage('darkTheme', true)


  const active_account = useSelector((state: RootState) => state.userStore.active_account);

  const scopes = useSelector((state: RootState) => state.userStore.scopes);

  const userName = useSelector((state: RootState) => state.userStore.user?.email)

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (userMenuWrapperRef.current && !userMenuWrapperRef.current.contains(event.target)) {
        setShowUserSubmenu(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  return (
    <HeaderContainer>
      <div style={{ width: "100px" }}>
        <SatellogicLogo />
      </div>

      <div style={{ paddingLeft: '50px' }}>

        <div style={{ width: "200px" }}>

          <Title style={{ color: "#FFFFFF" }}> {active_account?.name}</Title>
        </div>
      </div>

      <Navigation>

        <React.Fragment>

          <NavigationItem>
            {scopes?.includes("accounts:accounts:read-all") ?
              <NavLink className="menu-link" to={`/organizations/`} >Organizations</NavLink>
              :
              null
            }

          </NavigationItem>

          {!!active_account &&
            <>
              <NavigationItem>
                {
                  ["accounts:contracts:read-all", "accounts:contracts:read-account"]?.find(scope => scopes?.includes(scope)) ?
                    <NavLink className="menu-link" to={`/contracts/${active_account?.account_id}`} >Contracts</NavLink>
                    :
                    null
                }
              </NavigationItem>


              <NavigationItem>
                {["accounts:users:read-all", "accounts:users:read"]?.find(scope => scopes?.includes(scope)) ?
                  <NavLink className="menu-link" to={`/users/${active_account?.account_id}`} >Users</NavLink>
                  : null
                }
              </NavigationItem>

              {["accounts:api-credentials:read-all", "accounts:api-credentials:read-account"]?.find(scope => scopes?.includes(scope)) ?
                <NavigationItem>
                  <NavLink
                    className="menu-link"
                    to={`/api-credentials/${active_account?.account_id}`}
                  >API Credentials</NavLink>
                </NavigationItem>
                : null
              }

              {["accounts:users:write", "accounts:users:write-all"]?.find(scope => scopes?.includes(scope)) ? // There are no specific scope for Restrictions at the moment
                <NavigationItem>
                  <NavLink
                    className="menu-link"
                    to={`/onboardingrules/`}
                  >SSO On-boarding rules</NavLink>
                </NavigationItem>
                : null
              }
            </>
          }
          {["accounts:contracts:read-all"]?.find(scope => scopes?.includes(scope)) ? // There are no specific scope for Restrictions at the moment
            <NavigationItem>
              <NavLink
                className="menu-link"
                to={`/restrictions/`}
              >Archive Restrictions</NavLink>
            </NavigationItem>
            : null
          }



        </React.Fragment>

      </Navigation>

      <UserMenuWrapper ref={userMenuWrapperRef}>

        <Tooltip content={userName ? userName : ""} position={'left'} toggleElement={
          <UserMenu onClick={() => setShowUserSubmenu(!showUserSubmenu)}>
            <Bars3Icon className='icon burger' />
            <UserCircleIcon className='icon user' />
          </UserMenu>
        }></Tooltip>

        <UserSubmenu className={classes({ show: showUserSubmenu })}>

          <SubmenuItem onClick={() => {
            setShowUserSubmenu(false)
            navigate(`/myprofile`)
          }}>
            <Cog6ToothIcon className='icon' />
            My profile
          </SubmenuItem>

          <SubmenuItem onClick={() => setDarkTheme(!darkTheme)}>
            <MoonIcon className='icon' />
            Dark Mode
            <InputSwitch
              checked={darkTheme}
              name="dark_mode"
              readOnly
              small
            />
          </SubmenuItem>

          <SubmenuItem onClick={() => {
            setShowUserSubmenu(false)
            navigate('/docs')
          }}>
            <DocumentTextIcon className='icon' />
            API Documentation
          </SubmenuItem>

          <SubmenuItem onClick={() => {
            setShowUserSubmenu(false)
            logout({ logoutParams: { returnTo: window.location.origin } })
          }}>
            <ArrowLeftEndOnRectangleIcon className='icon' />
            Logout
          </SubmenuItem>
        </UserSubmenu>
      </UserMenuWrapper>
    </HeaderContainer>
  )
}

Menu.displayName = 'Menu'
