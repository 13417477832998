import React, { FC } from 'react'
import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
  CheckIcon,
  ExclamationCircleIcon,
  XMarkIcon
} from '@heroicons/react/24/outline'

import {
  ToastActionContainer,
  ToastActionDiv, ToastActionMessage,
  ToastActionIcon,
  ToastActionCloseButton
} from './toast.styles'


export type Props = {
  /** Unique ID */
  id?: number
  /** CSS class name */
  className?: string
  /** Description for toast */
  description: string
  /** Function to close toast */
  onClose?: (id: number) => void
  /** Whether to display close button or not */
  allowClose?: boolean
  /** Open */
  open?: boolean
  /** Type of alert */
  type?: 'info' | 'alert' | 'success' | 'error'
}

export const ToastAction: FC<Props> = ({description, open = true, type = 'info', onClose, allowClose = true }) => {
  const getIcon = () => {
    switch (type) {
      case 'info':
        return <InformationCircleIcon />
      case 'alert':
        return <ExclamationTriangleIcon />
      case 'success':
        return <CheckIcon />
      case 'error':
        return <ExclamationCircleIcon />
      default:
        break
    }
  }

  return (
    <ToastActionContainer data-testid="toast-div" open={open} type={type}>
      <ToastActionDiv>
        <ToastActionIcon type={type}>{getIcon()}</ToastActionIcon>

        <ToastActionMessage>{description}</ToastActionMessage>
      </ToastActionDiv>

      {allowClose ? (
        <ToastActionCloseButton data-testid="toast-close-button" type={type} onClick={onClose}>
          <XMarkIcon />
        </ToastActionCloseButton>
      ) : null}
    </ToastActionContainer>
  )
}
