import { configureStore } from '@reduxjs/toolkit'
import userStoreReducer from './stores/userStore/userStoreSlice'
import notificationsStoreReducer from './stores/notificationsStore/notificationsStoreSlice'
import globalStoreReducer from './stores/globalStore/globalStoreSlice'

export const store = configureStore({
  reducer: {
    userStore: userStoreReducer,
    notificationsStore: notificationsStoreReducer,
    globalStore: globalStoreReducer
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
