import axios from 'axios'
import { APIURL } from 'config/helpers'

export const createRestriction = async (
    token: string,    
    payload: any,
  ): Promise<any> => {
    try {
      const response = await axios
        .post(`${APIURL}/contracts/restrictions/`, payload, {
          headers: { Authorization: `Bearer ${token}` }
        })
      return response
    } catch (error) {
      throw error
    }
  };

  
export const getRestrictions = (
    token: string,
    onSuccess: (r: any) => void,
    onError: (r: any) => void
) => {
    axios
        .get(`${APIURL}/contracts/restrictions`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then(response => {
            onSuccess(response)
        })
        .catch(error => {
            onError(error)
        })
}


export const getRestriction = (
    token: string,
    restriction_id: string,
    onSuccess: (r: any) => void,
    onError: (r: any) => void
) => {
    axios
        .get(`${APIURL}/contracts/restrictions/${restriction_id}`, {
            headers: { Authorization: `Bearer ${token}` },
        })        
        .then(response => {
            onSuccess(response)
        })
        .catch(error => {
            onError(error)
        })
}

// Promise based instead of callbacks

export const deleteRestriction = async (
    token: string,
    restriction_id: string,
): Promise<any> => {
    try {
        const response = await axios
            .delete(`${APIURL}/contracts/restrictions/${restriction_id}`, {
                headers: { Authorization: `Bearer ${token}` }
            })
        return response
    } catch (error) {
        throw error
    }
}

export const updateRestriction = async (
    token: string,
    restriction_id: string,
    payload: any
): Promise<any> => {
    try {
        const response = await axios
            .put(`${APIURL}/contracts/restrictions/${restriction_id}`, payload,
                {
                    headers: { Authorization: `Bearer ${token}` }
                })
        return response
    } catch (error) {
        throw error
    }
};