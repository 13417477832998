import { Wrapper, BodyText } from './noScopesLanding.styles'

export const NoScopesLanding = () => {
  return (
    
      <Wrapper>      
        <BodyText>
          {/* TODO: Email might become a variable in the future */}
          You don´t have permission to access this resource. If you believe this might be an error, please reach out to <a className="link" href="mailto:support@satellogic.com">support@satellogic.com</a> for assistance.
        </BodyText>
      </Wrapper>
    
  )
}
