import styled from '@emotion/styled/macro'

export const StyledPill = styled.span`
    display: inline-block;
    padding: 5px 10px;
    margin: 4px;
    background-color: #e0e0e0;
    border-radius: 16px;
    font-size: 14px;
    color: #333;
    cursor: default;
`;