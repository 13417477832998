import styled from "@emotion/styled/macro"

export const Container = styled.main`
  width: 100%;
  height: calc(100vh - 60px);
  align-items: center;
  background-image: url('/assets/satellogic-background-image.jpeg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-flow: column;
  gap: ${(props: any) => props.theme.spacing.large10};
  justify-content: center;
`

export const Wrapper = styled.section`
  width: 550px;
  align-items: center;
  background-color: ${(props: any) => props.theme.secondary10};
  border-radius: ${(props: any) => props.theme.radius.large};
  display: flex;
  flex-flow: column;
  gap: ${(props: any) => props.theme.spacing.large10};
  justify-content: center;
  padding: ${(props: any) => props.theme.spacing.large20};
  
  
`

export const BodyText = styled.p`
  ${(props: any) => props.theme.texts.body};
  text-align: center;
  color: ${(props: any) => props.theme.body};
  .link {
    color: ${(props: any) => props.theme.primary10};
  }
`
