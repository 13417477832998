import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { type RootState } from "store";
import { TableCRUD } from "components/tableCRUD"

import { activateContract, createContract, deactivateContract, deleteContract } from "services/account-service/contractServices";

import { EyeIcon, LockClosedIcon, LockOpenIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { ContentWrapper } from "views/views.styles";
import { renderDateCell } from "config/helpers";
import { Contract } from "typings"
import { refreshTrigger } from "stores/globalStore/globalStoreSlice";

// Define the props type, including the contracts array
interface ContractsProps {
  fixedContracts?: Contract[];
}

export const Contracts: React.FC<ContractsProps> = ({ fixedContracts }) => {

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const storeContracts = useSelector((state: RootState) => state.userStore.contracts);
  const contracts = fixedContracts || storeContracts;
  
  const scopes = useSelector((state: RootState) => state.userStore.scopes)

  const navigate = useNavigate()

  const active_account = useSelector((state: RootState) => state.userStore.active_account);

  const handleActivateContract = async (item: any): Promise<{ success: boolean; message: string }> => {
    const token = await getAccessTokenSilently();
    const contractId = item.contract_id;

    if (!active_account) return {
      success: false,
      message: "No active account",
    };

    try {
      await activateContract(token, active_account?.account_id, contractId);
      dispatch(refreshTrigger());
      return {
        success: true,
        message: 'Account activated successfully'
      };
    } catch (error: any) {
      return {
        success: false,
        message: error.response?.data?.message + " " + JSON.stringify(error.response?.data?.errors) || 'Failed to activate account',
      };
    }
  };

  const handleDeactivateContract = async (item: any): Promise<{ success: boolean; message: string }> => {
    const token = await getAccessTokenSilently();
    const contractId = item.contract_id;

    if (!active_account) return {
      success: false,
      message: "No active account",
    };

    try {

      await deactivateContract(token, active_account?.account_id, contractId);
      dispatch(refreshTrigger());

      return {
        success: true,
        message: 'Account deactivated successfully'
      };
    } catch (error: any) {
      return {
        success: false,
        message: error.response?.data?.message + " " + JSON.stringify(error.response?.data?.errors) || 'Failed to deactivate account',
      };
    }
  };

  const handleCreateContract = async (item: any): Promise<{ success: boolean; message: string }> => {
    const token = await getAccessTokenSilently();

    try {
      if (active_account?.account_id) {
        await createContract(token, active_account?.account_id, { name: item.contract_name });
        dispatch(refreshTrigger());
        return {
          success: true,
          message: `Contract ${item.name} created successfully`
        };
      } else {
        return {
          success: false,
          message: "Please first select an account to manage",
        };
      }
    } catch (error: any) {

      return {
        success: false,
        message: error.response?.data?.Message || error.response?.data?.message + " " + JSON.stringify(error.response?.data?.errors) || 'Failed to create account',
      };
    }
  };

  const handleDeleteContract = async (item: any): Promise<{ success: boolean; message: string }> => {
    const token = await getAccessTokenSilently();
    const contractId = item.contract_id;

    try {
      if (active_account?.account_id) {
        await deleteContract(token, active_account?.account_id, contractId);
        dispatch(refreshTrigger());
        return {
          success: true,
          message: `Account ${item.name} deleted successfully`
        };
      } else {
        return {
          success: false,
          message: "Please first select an account to manage",
        };
      }
    } catch (error: any) {
      return {
        success: false,
        message: "Error deleting: " + error.response?.data?.message + " " + JSON.stringify(error.response?.data?.errors) || 'Failed to delete account',
      };
    }
  };

  const rowStyle = (rowData: any) => {
    if (rowData && active_account && rowData.status === "INACTIVE") {
      return { background: "#BBB", fontWeight: "bold", color: "#555555" };

    } else {
      return {};
    }
  };
  
  return (
      <ContentWrapper full>
        <TableCRUD
          title={"Contracts"}
          data={{ items: contracts ? contracts : [], total: contracts ? contracts?.length : 999 }}
          columns={[
            { label: "Name", dataKey: "name" },
            { label: "ID", dataKey: "contract_id" },
            { label: "Created", dataKey: "created_at", renderer: renderDateCell },
            { label: "Last Updated", dataKey: "updated_at", renderer: renderDateCell },
          ]}
          rowStyle={rowStyle}
          idKey="contract_id"

          actions={{
            view: {
              icon: <EyeIcon />,
              actionHandler: (item: Contract) => {
                navigate(`/contracts/${item.account}/${item.contract_id}`)
              }
            },

            ...(scopes?.includes("accounts:contracts:write") ? {
              edit: {
                icon: <PencilSquareIcon />,
                actionHandler: (item: any) => {
                  navigate(`/contracts/${item.account}/${item.contract_id}?edit=true`)
                }
              },

              create: {
                actionHandler: handleCreateContract,
                fields: [
                  {
                    name: 'contract_name',
                    label: 'Contract name',
                    type: 'text',
                    validation: { required: true, minLength: 3, message: 'Name is required' },
                  },
                ]

              },

              lock: {
                tooltip: "Deactivate contract",
                icon: <LockClosedIcon />,
                confirm: true,
                confirmationText: "Are you sure you want to deactivate the contract {name} ({id})?",
                confirmTitle: "Contract deactivation",
                enabled: (item) => item.status === 'ACTIVE',
                actionHandler: (item: any) => { return handleDeactivateContract(item) },
              },


              unlock: {
                tooltip: "Activate contract",
                icon: <LockOpenIcon />,
                confirm: true,
                confirmationText: "Are you sure you want to reactivate the contract {name} ({id})?",
                confirmTitle: "Contract activation",
                enabled: (item) => item.status === 'INACTIVE',
                actionHandler: (item: any) => { return handleActivateContract(item) },
              },

              delete: {
                icon: <TrashIcon />,
                tooltip: "Delete contract",
                confirm: true,
                confirmationText: "Are you sure you want to delete the contract: {name} ({id})?",
                actionHandler: (item: any) => { return handleDeleteContract(item) }
              },

            } : {}),
          }
          }
        />
      </ContentWrapper>      
  )
};