import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import type { RootState } from "store";
import { TableCRUD } from "components/tableCRUD"
import { addNotification } from "stores/notificationsStore/notificationsStoreSlice";

import { createUser, deleteUser, deactivateUser, activateUser } from "services/account-service/userServices";

import { EyeIcon, LockClosedIcon, LockOpenIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";

import { useNavigate } from "react-router-dom";
import { ContentWrapper } from "views/views.styles";
import { renderListAsPill, renderDateCell } from "config/helpers";

import { Contract, Role, User } from "typings";
import { setActiveUser } from "stores/userStore/userStoreSlice";
import { assignContract } from "services/account-service/contractServices";
import { refreshTrigger } from "stores/globalStore/globalStoreSlice";
import { PrincipalInterfaces } from "services/account-service/rolesServices";

// Define the props type, including the contracts array
interface ContractsProps {
  fixedUsers?: User[];
}

export const Users: React.FC<ContractsProps> = ({ fixedUsers }) => {

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const scopes = useSelector((state: RootState) => state.userStore.scopes)

  const roles = useSelector((state: RootState) => state.userStore.roles);

  const contracts = useSelector((state: RootState) => state.userStore.contracts);

  const storeUsers = useSelector((state: RootState) => state.userStore.users);
  const users = fixedUsers || storeUsers; // Use the props one or the one in store

  const active_account = useSelector((state: RootState) => state.userStore.active_account);
  const account = active_account;// Use the props one or the one in store

  const handleActivateUser = async (item: any): Promise<{ success: boolean; message: string }> => {
    const token = await getAccessTokenSilently();
    const principal_id = item.principal_id;

    if (!account) return {
      success: false,
      message: "No active account",
    };

    try {
      await activateUser(token, account?.account_id, principal_id);
      dispatch(refreshTrigger());
      return {
        success: true,
        message: 'User activated successfully'
      };
    } catch (error: any) {
      return {
        success: false,
        message: error.response?.data?.message + " " + JSON.stringify(error.response?.data?.errors) || 'Failed to activate user',
      };
    }
  };

  const handleDeactivateUser = async (item: any): Promise<{ success: boolean; message: string }> => {
    const token = await getAccessTokenSilently();
    const principal_id = item.principal_id;

    if (!account) return {
      success: false,
      message: "No active account",
    };

    try {

      await deactivateUser(token, account?.account_id, principal_id);
      dispatch(refreshTrigger());
      return {
        success: true,
        message: 'User deactivated successfully'
      };
    } catch (error: any) {
      return {
        success: false,
        message: error.response?.data?.message + " " + JSON.stringify(error.response?.data?.errors) || 'Failed to deactivate user',
      };
    }
  };


  const handleCreateUser = async (data: any): Promise<{ success: boolean; message: string }> => {
    const token = await getAccessTokenSilently();

    try {
      if (account?.account_id) {
        if (Object.keys(data).includes("role_ids")) {
          data['role_ids'] = data['role_ids'].split(",").map((x: string) => x.trim())
        } else {
          data['role_ids'] = []
        }

        const response = await createUser(token, account?.account_id, data);
        console.log("Created user", response)

        try {
          if (Object.keys(data).includes("contract_ids")) {
            const newAccountId = response.data.principal_id;

            data['contract_ids'].split(",").forEach(async (contract_id: string) => {

              await assignContract(token, account?.account_id, contract_id.trim(), newAccountId)

            });
          }
        } catch (error: any) {
          console.log(error)
          console.error('Error assignign contract:', error.message);
          dispatch(addNotification({ type: 'error', description: error.response.data.details }));
        }
        dispatch(refreshTrigger());

        return {
          success: true,
          message: `User ${data.name} created successfully`
        };
      } else {
        return {
          success: false,
          message: "Please first select an account to manage",
        };
      }
    } catch (error: any) {

      return {
        success: false,
        message: error.response?.data?.Message || error.response?.data?.message + " " + JSON.stringify(error.response?.data?.errors) || 'Failed to create account',
      };
    }
  };

  const handleDeleteUser = async (item: any): Promise<{ success: boolean; message: string }> => {
    const token = await getAccessTokenSilently();
    const principalId = item.principal_id;

    try {
      if (account?.account_id) {
        await deleteUser(token, account?.account_id, principalId);
        dispatch(refreshTrigger());
        return {
          success: true,
          message: `User ${item.name} deleted successfully`
        };
      } else {
        return {
          success: false,
          message: "Please first select an account to manage",
        };
      }
    } catch (error: any) {
      return {
        success: false,
        message: "Error deleting: " + error.response?.data?.message + " " + JSON.stringify(error.response?.data?.errors) || 'Failed to delete user',
      };
    }
  };

  const rowStyle = (rowData: any) => {
    if (rowData && account && rowData.status === "INACTIVE") {
      return { background: "#BBB", fontWeight: "bold", color: "#555555" };

    } else {
      return {};
    }
  };

  /*
  const renderRolesCell = ({ cellData }: { cellData?: any }) => {

    if (!cellData || !roles) return '';

    return <div style={{ display: "flex", justifyContent: "center" }}>
      {cellData.map((element: any) => {
        const role = roles.find(role => role.role_id === element);
        if (role) {
          return <Pill key={role.role_id} label={role.name} />
        } else {
          return ""
        }
      })}
    </div>
  };
  */
  const roleOptions = roles ? Object.entries(roles.filter((role: Role) => role.interface === PrincipalInterfaces.USER)).map(([key, role]) => ({
    value: role.role_id,
    label: role.name,
  })) : undefined;

  const contractOptions = contracts ? contracts.map((contract: Contract) => ({
    value: contract.contract_id,
    label: contract.name,
  })) : undefined;

  return (

    <ContentWrapper full>

      <TableCRUD
        title={"Users"}
        data={{ items: users ? users : [], total: users ? users?.length : 999 }}
        columns={[
          { label: "Name", dataKey: "name" },
          { label: "ID", dataKey: "principal_id" },
          { label: "E-mail", dataKey: "email" },
          {
            label: "Roles", dataKey: "role_ids",
            renderer: ({ cellData }: { cellData?: any }) =>
              renderListAsPill({
                data: cellData?.map((item: any) => ({
                  id: item,
                  label: roles?.find((role: any) => role.role_id === item)?.name || item
                }))
              })
          },          
          { label: "Created", dataKey: "created_at", renderer: renderDateCell },
          { label: "Last Updated", dataKey: "updated_at", renderer: renderDateCell },
        ]}
        rowStyle={rowStyle}
        idKey="principal_id"

        actions={{

          view: {
            icon: <EyeIcon />,
            actionHandler: (item: any) => {
              dispatch(setActiveUser(item))
              navigate(`/users/${account?.account_id}/${item.principal_id}`)
            }
          },

          ...(scopes?.includes("accounts:users:write") ? {
            create: {
              actionHandler: handleCreateUser,
              fields: [
                {
                  name: 'name',
                  label: 'Name',
                  type: 'text',
                  validation: { required: true, minLength: 3, message: 'Name is required' },
                },
                {
                  name: 'email',
                  label: 'E-mail',
                  type: 'email',
                  validation: { required: true, pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, message: 'Invalid email' },
                },
                {
                  name: 'role_ids',
                  label: 'Roles',
                  type: 'list',
                  validation: { options: roleOptions },
                },
                {
                  name: 'contract_ids',
                  label: 'Assigned contracts',
                  type: 'list',
                  validation: { options: contractOptions },
                },
              ]
            },


            edit: {
              icon: <PencilSquareIcon />,
              actionHandler: (item: any) => {
                dispatch(setActiveUser(item))
                navigate(`/users/${account?.account_id}/${item.principal_id}?edit=true`)
              }
            },

            lock: {
              tooltip: "Deactivate user",
              icon: <LockClosedIcon />,
              confirm: true,
              confirmationText: "Are you sure you want to deactivate the user {name} ({id})?",
              confirmTitle: "User deactivation",
              enabled: (item) => item.status === 'ACTIVE',
              actionHandler: (item: any) => { return handleDeactivateUser(item) },
            },

            unlock: {
              tooltip: "Activate user",
              icon: <LockOpenIcon />,
              confirm: true,
              confirmationText: "Are you sure you want to reactivate the user {name} ({id})?",
              confirmTitle: "User activation",
              enabled: (item) => item.status === 'INACTIVE',
              actionHandler: (item: any) => { return handleActivateUser(item) },
            },

            delete: {
              icon: <TrashIcon />,
              tooltip: "Delete user",
              confirm: true,
              confirmationText: "Are you sure you want to delete the user: {name} ({id})?",
              actionHandler: (item: any) => { return handleDeleteUser(item) }
            },
          } : {}),
        }

        }

      />
    </ContentWrapper>

  )

};