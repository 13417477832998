import React from 'react';
import { StyledPill } from './pill.styles'

interface PillProps {
    label: string;
}

export const Pill: React.FC<PillProps> = ({ label }) => {
    return <StyledPill>{label}</StyledPill>;
};

export default Pill;