function getTextWidth(text: string, font = '16px Arial'): number {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
      context.font = font;
      return context.measureText(text).width;
    }
    return 0;
  }

export function calculateColumnWidths(
    columns: { dataKey: string; label: string }[], // List of columns
    items: any[], // List of rows
    containerWidth: number, // Width of the table container
    padding = 20 // Optional padding for each column
  ): { [key: string]: number } {
    // Calculate the content width for each column
    const columnWidths = columns.map(({ dataKey, label }) => {
      const headerWidth = getTextWidth(label); // Calculate header width
      const contentWidths = items.map((item) => getTextWidth(String(item[dataKey]))); // Calculate content widths
      const maxContentWidth = Math.max(...contentWidths); // Find the maximum width
      return Math.max(headerWidth, maxContentWidth) + padding; // Add padding
    });

    // Calculate the total content width of all columns
    const totalContentWidth = columnWidths.reduce((acc, width) => acc + width, 0);

    // Scale each column's width to fit the available container width
    const scaleFactor = containerWidth / totalContentWidth;

    // Return an object mapping column keys to their scaled width
    return columns.reduce((result, { dataKey }, index) => {
      result[dataKey] = columnWidths[index] * scaleFactor;
      return result;
    }, {} as { [key: string]: number });
  }

export function renderTemplate(template: string | undefined, values: { [key: string]: string }): string {
    if (!template) return ""
    return template.replace(/{(\w+)}/g, (_, key) => values[key] || '');
  }

export const sortItems = (items: any[], sortBy:string|undefined, sortDirection: string) => {
    if (!sortBy) return items; // If no sorting column is selected, return items as is

    const sortedItems = [...items].sort((a, b) => {
      if (a[sortBy] < b[sortBy]) {
        return sortDirection === "ASC" ? -1 : 1;
      }
      if (a[sortBy] > b[sortBy]) {
        return sortDirection === "ASC" ? 1 : -1;
      }
      return 0;
    });

    return sortedItems;
  }