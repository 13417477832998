import { createSlice } from '@reduxjs/toolkit'
import { UserApiCredential, User, Organization, Contract, Role, Restriction, OnboardingRules } from 'typings'

interface userState {
  accounts: Organization[] | undefined,
  contracts: Contract[] | undefined,
  my_contracts: Contract[] | undefined,
  restrictions: Restriction[] | undefined,
  active_account: Organization | undefined,  
  users: User[] | undefined,  
  user: User | undefined,  
  active_user: User | undefined,  
  roles: Role[] | undefined, 
  scopes: string[] | undefined,
  apiCredentials: UserApiCredential[] | undefined,  
  onboardingRules: OnboardingRules [] | undefined,
}

const initialState: userState = {
  user: undefined,
  my_contracts: undefined,
  active_user: undefined,  
  users: undefined,
  scopes: undefined,
  roles: undefined,
  apiCredentials: undefined,
  accounts: undefined,
  active_account: undefined,
  contracts: undefined,
  restrictions: undefined,
  onboardingRules: undefined
}

export const userStoreSlice = createSlice({
  name: 'userStore',
  initialState,
  reducers: {
    setMyContracts: (state, action)=> {      
      state.my_contracts = action.payload
    },
    setUser: (state, action) => {      
      state.user = action.payload
    },
    setActiveUser: (state, action) => {      
      state.active_user = action.payload
    },
    setUsers: (state, action) => {
      state.users = action.payload
    },
    setRestrictions: (state, action) => {
      state.restrictions = action.payload
    }, 
    setScope: (state, action) => {
      state.scopes = action.payload
    },
    setApiCredentials: (state, action) => {
      state.apiCredentials = action.payload
    },
    addApiCredential: (state, action) => {
      state.apiCredentials?.push(action.payload)
    },
    deleteApiCredential: (state, action) => {
      state.apiCredentials = state.apiCredentials?.filter(apiCredential => apiCredential.principal_id !== action.payload)
    },
    setAccounts: (state, action) => {      
      state.accounts = action.payload    
      if (!action.payload){
        state.users = undefined
        state.contracts = undefined
      }      
    },
    setActiveAccount: (state, action) => {    
      state.active_account = action.payload
    },
    setContracts: (state, action) => {      
      state.contracts = action.payload      
    },
    setRoles: (state, action) => {
      state.roles = action.payload      
    },
    setOnboardingRules: (state, action) => {
      state.onboardingRules = action.payload      
    },
  }
})

export const { setMyContracts, setOnboardingRules, setUser, setActiveUser, setUsers, setRestrictions, setScope, setApiCredentials, addApiCredential, deleteApiCredential, setActiveAccount, setAccounts, setContracts, setRoles } = userStoreSlice.actions

export default userStoreSlice.reducer
