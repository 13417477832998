export const colors = {
  global10: '#FFFFFF',
  global20: '#BFD0DA',
  global30: '#A6C0FF',
  global40: '#84A9FF',
  global50: '#6DA6FF',
  global60: '#0B3390',

  base10: '#FFFFFF',
  base20: '#F1F4FC',
  base30: '#E0ECF8',

  primary10: '#1068EB',
  primary20: '#84A9FF',
  primary30: '#548DFF',
  primary40: '#0056D0',

  secondary10: '#FFFFFF',
  secondary20: '#F1F4FC',
  secondary30: '#E0ECF8',
  secondary40: '#747F9F',
  secondary50: '#56607D',
  secondary60: '#30374B',
  secondary70: '#1A1E23',

  grayscale10: '#FFFFFF',
  grayscale20: '#BFD0DA',
  grayscale30: '#2B323D1F',
  grayscale40: '#2B323D61',
  grayscale50: '#2B323D08',
  grayscale60: '#ffffff4d',

  error: '#DB3420',
  errorOpacity: '#F3AAA1',
  alert: '#F5BA40',
  alertOpacity: '#FADC9F',
  success: '#73CF9A',
  successOpacity: '#B9E7CC',
  info: '#84A9FF',
  infoOpacity: '#A6C0FF',
  text: '#687782',

  body: '#2B323D',
  bodyGray: '#2B323D99',
  btnPrimary: '#FFFFFF',
  btnSecondary: '#1B5CF5',

  shadowColor: '#0000001A'
}
