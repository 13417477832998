import Ajv from 'ajv';
import { ValidationError } from './codeeditor';
import CodeMirror from 'codemirror';

const ajv = new Ajv({
  allErrors: true, // Collect all errors, not just the first one
  strict: true, // Enable strict mode for catching issues in schema or data
  verbose: true // Provide detailed error messages
});


export const validateJson = (geojsonText: string, polygonSchema: Object): ValidationError[] => {

  try {
    const parsed = JSON.parse(geojsonText);
    const valid = ajv.validate(polygonSchema, parsed);

    if (valid) {
      return ([]);
    } else {
      const errors = ajv.errors || [];      
      return (        
        errors.map((error) => {
          const { line, column } = getPositionFromInstancePath(error.instancePath, geojsonText);
          return {
            line: line,
            column: column,
            message: error.message + " " + error.schema || "Invalid JSON",
            severity: "error"
          }
        })
      );
    }
  } catch (error) {

    if (error instanceof SyntaxError) {
      const errorMessage = error.message || "Unknown error";  // Safely extract message        
      const { line, column } = parseLineColumn(errorMessage);  // Parse line and column if available        
      // Handle syntax errors from JSON.parse
      return (
        [{
          line: line > 0 ? line : 1,
          column: column > 0 ? column : 1,
          message: "Invalid JSON format: " + errorMessage,
          severity: "error"
        }
        ])
    } else {
      // Fallback for other unknown errors
      console.log(error);  // Log unknown errors for debugging
      return ([{
        line: 1,
        column: 1,
        message: "Unknown error occurred.",
        severity: "error"
      }]
      );
    }
  }
}


// Function to extract line and column from a string like "(line 23 column 4)"
export const parseLineColumn = (message: string) => {
  const match = message.match(/\(line (\d+) column (\d+)\)/);
  if (match) {
    const line = parseInt(match[1], 10);
    const column = parseInt(match[2], 10);
    return { line, column };
  }
  return { line: 0, column: 0 };  // Default value if parsing fails
};

// Function to calculate positions in the document based on line/column
// Function to calculate positions in the document based on line/column
export const getPositionFromLineColumn = (line: number, column: number, editor: CodeMirror.Editor) => {
  if (!editor) {
    console.error("Editor instance is not defined.");
    return { from: 0, to: 0 };  // Fallback in case editor is undefined
  }

  const doc = editor.getDoc(); // Get the document associated with the editor

  if (!doc) {
    console.error("Document is not defined.");
    return { from: 0, to: 0 };  // Fallback in case editor is undefined
  }

  // Calculate the "from" position based on line and column
  const fromPos = { line: line - 1, ch: column - 1 }; // 0-based line and column indexing
  const toPos = { line: line - 1, ch: column };       // Adjust `to` for the length of the error (1 character span)

  return { from: fromPos, to: toPos };
};

export const getPositionFromInstancePath = (instancePath: string, jsonString: string) => {
  try {
    const pathSegments = instancePath.split('/').slice(1); // Remove the empty first element
    let current = JSON.parse(jsonString);
    let startPos = 0;

    // Traverse the JSON structure to locate the segment from instancePath
    pathSegments.forEach((key) => {
      /*
      if (Array.isArray(current)) {
        key = parseInt(key); // Convert array indexes to integers
      }*/
      current = current[key];
      // Find the key in the JSON string and calculate the position
      const keyPos = jsonString.indexOf(`"${key}"`, startPos);
      if (keyPos !== -1) {
        startPos = keyPos;
      }
    });

    // Now calculate the line and column number from the character position (startPos)
    return calculateLineAndColumn(startPos, jsonString);
  } catch (error) {
    console.error("Error calculating position:", error);
    return { line: 1, column: 1 }; // Return a default position (line 1, column 1)
  }
};

// Function to calculate line and column from a character position
export const calculateLineAndColumn = (charPos: number, jsonString: string) => {
  let line = 1; // Line number starts at 1
  let column = 1; // Column number starts at 1
  let currentPos = 0;

  // Iterate through the string to count lines and columns
  for (let i = 0; i < jsonString.length; i++) {
    if (currentPos === charPos) {
      return { line, column };
    }

    if (jsonString[i] === '\n') {
      line++; // Increment line count on each newline
      column = 1; // Reset column count on new line
    } else {
      column++; // Increment column count on each character
    }

    currentPos++;
  }

  // Fallback, return last line and column in case charPos is out of bounds
  return { line, column };
};