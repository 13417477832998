import { useState } from 'react'

import { KeyIcon, CheckIcon, DocumentDuplicateIcon } from '@heroicons/react/24/outline'

import { Dialog } from 'components/dialog'
import { Input } from 'components/input'
import { Button } from 'components/button'
import { Tooltip } from 'components/tooltip'

import {
  ApiCredentialContent,
  CredentialInputWrapper
} from '../../apiCredentials.styles'

import { UserApiCredential } from 'typings'
import { classes } from 'config/helpers'

export interface Props {
  apiCredential?: UserApiCredential
  isShown: boolean
  onClose: () => void
}

export const DetailsModal = ({ apiCredential, isShown, onClose }: Props) => {
  const [idCopied, setIdCopied] = useState(false)
  const [secretCopied, setSecretCopied] = useState(false)

  return (
    <Dialog
      confirmation={{
        text: 'Close',
        action: () => onClose()
      }}
      customIcon={<KeyIcon />}
      isShown={isShown}
      size="medium"
      title={`${apiCredential?.name} API Credentials`}
      onClose={() => onClose()}
    >
      <ApiCredentialContent>
        <CredentialInputWrapper>
          <Input
            label="Client ID"
            name="clientId"
            readonly
            type="text"
            value={apiCredential?.principal_id}
          />
          <Tooltip
            content={ idCopied ? 'Copied' : 'Copy Client ID' }
            position="left"
            toggleElement={
              <Button
                className={classes({ copied: idCopied })}
                grayscale
                icon={ idCopied ? <CheckIcon /> : <DocumentDuplicateIcon /> }
                onlyIcon
                onClick={() => {
                  if (apiCredential) navigator.clipboard.writeText(apiCredential.principal_id)
                  setIdCopied(true)
                  setTimeout(() => {
                    setIdCopied(false)
                  }, 2500)
                }}
              />
            }
          />
        </CredentialInputWrapper>

        <CredentialInputWrapper>
          <Input
            label="Client Secret"
            name="clientSecret"
            readonly
            type="password"
            value={apiCredential?.secret}
          />
          <Tooltip
            content={ secretCopied ? 'Copied' : 'Copy Client Secret' }
            position="left"
            toggleElement={
              <Button
                className={classes({ copied: secretCopied })}
                grayscale
                icon={ secretCopied ? <CheckIcon /> : <DocumentDuplicateIcon /> }
                onlyIcon
                onClick={() => {
                  if (apiCredential) navigator.clipboard.writeText(apiCredential.secret)
                  setSecretCopied(true)
                  setTimeout(() => {
                    setSecretCopied(false)
                  }, 2500)
                }}
              />
            }
          />
        </CredentialInputWrapper>
      </ApiCredentialContent>
    </Dialog>
  )
}

DetailsModal.displayName = 'DetailsModal'
