import styled from '@emotion/styled/macro'

export const HeaderContainer = styled.div`
  width: 100%;
  height: 60px;
  align-items: center;
  background-color: ${(props: any) => props.theme.secondary70};
  display: flex;
  justify-content: space-between;
  padding: 0 2.5%;
  transition: opacity 0.3s ease-in-out;
  z-index: 9999993;
  position: relative;
`

export const Navigation = styled.ul`
  margin: 0 auto;  
  height: 100%;
  align-items: center;
  display: flex;
  gap: ${(props: any) => props.theme.spacing.large15};
  justify-content: space-between;
`

export const NavigationItem = styled.li`
  height: ${(props: any) => props.theme.spacing.large25};
  display: flex;
  align-items: center;
  justify-content: center;
  .menu-link {
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    color: ${(props: any) => props.theme.global10};
    justify-content: center;
    position: relative;
    text-align: center;
    &:before {
      width: 0;
      height: 1px;
      background-color: ${(props: any) => props.theme.global10};
      border-radius: ${(props: any) => props.theme.radius.small};
      content: '';
      transition: width 0.2s cubic-bezier(0.74, 0.04, 0.24, 1.04);
      transform: translateX(-50%);
      transform-origin: center;
      position: absolute;
      bottom: 0;
      left: 50%;
    }
    &:hover {
      &:before {
        width: ${(props: any) => props.theme.spacing.medium};
      }
    }
    &.active {
      &:before {
        width: ${(props: any) => props.theme.spacing.large10};
      }
    }
  }
`

export const Title = styled.h1`
  height: ${(props: any) => props.theme.spacing.large25};
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  .menu-link {
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    color: ${(props: any) => props.theme.global10};
    justify-content: center;
    position: relative;
    text-align: center;
    &:before {
      width: 0;
      height: 1px;
      background-color: ${(props: any) => props.theme.global10};
      border-radius: ${(props: any) => props.theme.radius.small};
      content: '';
      transition: width 0.2s cubic-bezier(0.74, 0.04, 0.24, 1.04);
      transform: translateX(-50%);
      transform-origin: center;
      position: absolute;
      bottom: 0;
      left: 50%;
    }
    &:hover {
      &:before {
        width: ${(props: any) => props.theme.spacing.medium};
      }
    }
    &.active {
      &:before {
        width: ${(props: any) => props.theme.spacing.large10};
      }
    }
  }
`

export const UserMenuWrapper = styled.div`
  width: 300px;  
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
`

export const UserMenu = styled.div`
  width: ${(props: any) => props.theme.spacing.large50};
  height: ${(props: any) => props.theme.spacing.large25};
  align-items: center;
  background-color: ${(props: any) => props.theme.secondary50};
  border-radius: ${(props: any) => props.theme.radius.full};
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  transition: background-color 0.3s ease-in-out;
  .icon {
    width: ${(props: any) => props.theme.spacing.large15};
    height: auto;
    color: ${(props: any) => props.theme.global20};
    flex: 0 0 auto;
    &.user {
      width: 36px;
      color: ${(props: any) => props.theme.global10};
      margin: 0 3px 0 5px;
    }
  }
  &:hover {
    background-color: ${(props: any) => props.theme.secondary40};
  }
`

export const UserSubmenu = styled.div`
  width: 100%;
  max-width: 253px;
  background-color: ${(props: any) => props.theme.secondary50};
  border-radius: ${(props: any) => props.theme.radius.large};
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
  padding: ${(props: any) => props.theme.spacing.medium};
  position: absolute;
  top: 66px;
  right: 2.5%;
  &.show {
    opacity: 1;
    pointer-events: all;
  }
`

export const SubmenuItem = styled.div`
  height: ${(props: any) => props.theme.spacing.large20};
  color: ${(props: any) => props.theme.global10};
  align-items: center;
  border-radius: ${(props: any) => props.theme.radius.large};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: ${(props: any) => props.theme.spacing.medium};
  justify-content: flex-start;
  padding: 6px ${(props: any) => props.theme.spacing.medium};
  transition: background-color 0.3s ease-in-out;
  white-space: nowrap;
  .icon {
    width: 20px;
    height: 20px;
  }
  &:hover {
    background-color: ${(props: any) => props.theme.secondary40};
  }
`
