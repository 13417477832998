import styled from '@emotion/styled/macro'
import { keyframes } from '@emotion/react'

export const LoaderWrapper = styled.div`
  width: auto;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
`

export const LoaderText = styled.div`
  ${(props: any) => props.theme.texts.body};
  color: ${(props: any) => props.theme.body};
  font-weight: ${(props: any) => props.theme.weight.semiBold};
  margin-left: ${(props: any) => props.theme.spacing.large10};
  &.darkBackground {
    color: ${(props: any) => props.theme.global10};
  }
`

export const animate = keyframes`
0%{
  transform: rotate(0deg);
}
100%{
  transform: rotate(360deg);
}

`

export const LoaderContainer = styled.div`
  width: ${(props: any) => props.theme.spacing.large25};
  height: ${(props: any) => props.theme.spacing.large25};
  position: relative;
  animation: ${animate} 1.5s cubic-bezier(0.66, 0.03, 0.26, 1) infinite;
`

export const Satellite = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${(props: any) => props.theme.primary20};
  border-radius: ${(props: any) => props.theme.radius.full};
  transform: translateX(-50%);
  z-index: 10;
  position: absolute;
  top: -5px;
  left: 50%;
`

export const Earth = styled.div`
  width: 25px;
  height: 25px;
  background-color: ${(props: any) => props.theme.primary10};
  border-radius: ${(props: any) => props.theme.radius.full};
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
`

export const LoaderOverlay = styled.div`
  width: auto;
  background: none;
  position: static;
`

export const LoaderWindow = styled.div`
  width: auto;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
`

export const LoaderBase = styled.div`
  width: auto;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;

  &.fullScreen {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 9999994;

    ${LoaderWindow} {
      margin: 0;
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      left: 50%;
    }
    ${LoaderOverlay} {
      width: 100%;
      height: 100%;
      background: #000000B2;
      position: absolute;
      top: 0;
      left: 0;
    }
    ${LoaderText} {
      color: ${(props: any) => props.theme.global10};
      pointer-events: none;
    }
  }
`
