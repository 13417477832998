import React from 'react'
import {
  InformationCircleIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
  ExclamationCircleIcon
} from '@heroicons/react/24/outline'

import { Modal } from 'components/modal/modal'
import { Button } from 'components/button'

import {
  DialogContainer,
  DialogHeader,
  DialogBody,
  BodyText,
  BodyTitle,
  ButtonContainer,
  HeaderTitle,
  DialogFooter
} from './dialog.styles'

import { classes } from 'config/helpers'

interface Props {
  cancel?: {
    text: string
    action: () => void
  }
  children?: React.ReactNode
  className?: string
  confirmation?: {
    text?: string
    action: () => void
  }
  confirmationLink?: string
  customIcon?: React.ReactNode
  description?: string | React.ReactNode
  disableCancel?: boolean
  disableConfirmation?: boolean
  hideKey?: boolean
  isShown: boolean
  overlayOff?: boolean
  size?: "medium" | "large" | "extra-large"
  subtitle?: string
  subtitleOn?: boolean
  title: string
  type?: "info" | "success" | "warning" | "error"
  onClose?: () => void
}

export const Dialog = ({
  cancel,
  children,
  className,
  confirmation,
  confirmationLink,
  customIcon,
  description,
  disableCancel = false,
  disableConfirmation = false,
  hideKey,
  isShown,
  overlayOff,
  size,
  subtitle,
  subtitleOn = true,
  title,
  type,
  onClose
}: Props) => {

  // eslint-disable-next-line react/display-name
  const getIcon = () => {
    switch (type) {
      case "info":
        return <InformationCircleIcon />
      case "success":
        return <CheckCircleIcon />
      case "warning":
        return <ExclamationTriangleIcon />
      case "error":
        return <ExclamationCircleIcon />
      default:
        return undefined
    }
  }

  return (
    <Modal isShown={isShown} hide={onClose} overlayOff={overlayOff} hideKey={hideKey}>
      <DialogContainer className={classes(className, type, size)}>
        <DialogHeader>
          <HeaderTitle>
            {customIcon ? customIcon :  getIcon()}
            {title}
          </HeaderTitle>
        </DialogHeader>
        <DialogBody>
          {children || (
            <>
              {description && subtitleOn ? <BodyTitle>{subtitle || ''}</BodyTitle> : null}
              {description ? <BodyText>{description}</BodyText> : null}
            </>
          )}
        </DialogBody>
        {cancel || confirmation ? (
          <DialogFooter>
            <ButtonContainer>
              {cancel ? (
                <Button
                  className="cancel--button"
                  disabled={disableCancel}
                  secondary
                  size="sm"
                  text={cancel.text}
                  onClick={cancel.action}
                />
              ) : null}
              {confirmation ? (
                <Button
                  disabled={disableConfirmation}
                  size="sm"
                  text={confirmation.text || "Close"}
                  to={confirmationLink}
                  onClick={confirmation.action}
                />
              ) : null}
            </ButtonContainer>
          </DialogFooter>
        ) : null}
      </DialogContainer>
    </Modal>
  )
}

Dialog.displayName = "Dialog"
