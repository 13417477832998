import styled from '@emotion/styled/macro'

export const SwitchWrapper = styled.div`
  height: 36px;
  align-items: center;
  cursor: default;
  display: flex;
  padding: ${(props: any) => props.theme.spacing.medium} ${(props: any) => props.theme.spacing.large};
`;

export const Label = styled.label`
  ${(props: any) => props.theme.texts.body2};
  color: ${(props: any) => props.theme.body};
  margin-left: ${(props: any) => props.theme.spacing.medium};
  user-select: none;
  &:hover {
    cursor: pointer;
  }
`;

export const Switch = styled.input`
  width: 36px;
  height: ${(props: any) => props.theme.spacing.large};
  appearance: none;
  background-color: ${(props: any) => props.theme.grayscale40};
  border: 1px solid transparent;
  border-radius: 40px;
  flex: 0 0 auto;
  position: relative;
  transition: all 0.2s ease-in-out;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-progress-appearance: none;
  &::before {
    width: 20px;
    height: 20px;
    background-color: ${(props: any) => props.theme.global10};
    border: 1px solid ${(props: any) => props.theme.primary10};
    border-radius: 100%;
    content: '';
    transition: all 0.2s ease-in-out;
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    left: -4px;
  }

  &:hover {
    cursor: pointer;
    &::before {
      box-shadow: 0px 0px 0px 10px ${(props: any) => props.theme.grayscale30};
    }
  }
  &:checked {
    background-color: ${(props: any) => props.theme.primary20};
    &::before {
      background-color: ${(props: any) => props.theme.primary10};
      left: calc(100% - 18px);
    }
  }

  &:disabled {
    background-color: ${(props: any) => props.theme.grayscale40};
    cursor: default;
    & + ${Label} {
      color: ${(props: any) => props.theme.bodyGray};
      cursor: default;
    }
    &:hover {
      cursor: default;
      &:before {
        box-shadow: 0px 0px 0px 0px transparent;
      }
    }
    &::before {
      background-color: ${(props: any) => props.theme.global20};
      border: 1px solid ${(props: any) => props.theme.grayscale40};
    }
  }
`;

export const HelperText = styled.span`
  ${(props: any) => props.theme.texts.caption};
  color: ${(props: any) => props.theme.bodyGray};
  display: block;
  flex: 0 0 auto;
  margin-left: ${(props: any) => props.theme.spacing.large10};
`;

export const SwitchContainer = styled.div`
  &.last {
    margin-top: 36px;
  }
  &.disabled {
    pointer-events: none;
    input {
      cursor: not-allowed;
    }
  }
  &.small {
    ${SwitchWrapper} {
      height: ${(props: any) => props.theme.spacing.large15};
      padding: ${(props: any) => props.theme.spacing.small};
    }
    ${Switch} {
      width: 30px;
      height: 10px;
      &::before {
        width: ${(props: any) => props.theme.spacing.large10};
        height: ${(props: any) => props.theme.spacing.large10};
      }
      &:checked::before {
        left: calc(100% - 14px);
      }
    }
    ${HelperText} {
      margin-left: 6px;
    }
  }
`;
