import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import type { RootState } from "store";
import { TableCRUD } from "components/tableCRUD"

import { setActiveAccount } from "stores/userStore/userStoreSlice";
import { activateAccount, createAccount, deactivateAccount, deleteAccount } from "services/account-service/accountServices";

import { EyeIcon, LockClosedIcon, LockOpenIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { renderDateCell } from "config/helpers";
import { ContentWrapper} from "views/views.styles";
import { refreshTrigger } from "stores/globalStore/globalStoreSlice";

export const Organizations = () => {

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const navigate = useNavigate()

  //const scopes = useSelector((state: RootState) => state.userStore.scopes);
  
  const active_account = useSelector((state: RootState) => state.userStore.active_account);
  const accounts = useSelector((state: RootState) => state.userStore.accounts);  

  const handleActivateAccount = async (item: any): Promise<{ success: boolean; message: string }> => {
    const token = await getAccessTokenSilently();
    const accountId = item.account_id;

    try {

      await activateAccount(token, accountId);
      dispatch(refreshTrigger());
      return {
        success: true,
        message: 'Organization activated successfully'
      };
    } catch (error: any) {
      return {
        success: false,
        message: error.response?.data?.message + " " + JSON.stringify(error.response?.data?.errors) || 'Failed to activate organization',
      };
    }
  };

  const handleDeactivateAccount = async (item: any): Promise<{ success: boolean; message: string }> => {
    const token = await getAccessTokenSilently();
    const accountId = item.account_id;

    try {

      await deactivateAccount(token, accountId);
      setTimeout(() => {
        dispatch(refreshTrigger())
      }, 1000);
      return {
        success: true,
        message: 'Organization deactivated successfully'
      };
    } catch (error: any) {
      return {
        success: false,
        message: error.response?.data?.message + " " + JSON.stringify(error.response?.data?.errors) || 'Failed to deactivate organization',
      };
    }
  };

  const handleCreateAccount = async (data: any): Promise<{ success: boolean; message: string }> => {
    const token = await getAccessTokenSilently();
    try {      
      await createAccount(token, data['account_name']);
      
      setTimeout(() => {
        dispatch(refreshTrigger())
      }, 1000);
      return {
        success: true,
        message: `Organization ${data['account_name']} created successfully`
      };
    } catch (error: any) {
      return {
        success: false,
        message: error.response?.data?.message + " " + JSON.stringify(error.response?.data?.errors) || 'Failed to create organization',
      };
    }
  };

  const handleDeleteAccount = async (item: any): Promise<{ success: boolean; message: string }> => {
    const token = await getAccessTokenSilently();
    const accountId = item.account_id;
    setTimeout(() => {
      dispatch(refreshTrigger())
    }, 1000);
    try {
      await deleteAccount(token, accountId);
      return {
        success: true,
        message: `Organization ${accountId} deleted successfully`
      };
    } catch (error: any) {
      return {
        success: false,
        message: "Error deleting: " + error.response?.data?.message + " " + JSON.stringify(error.response?.data?.errors) || 'Failed to delete organization',
      };
    }
  };


  const rowStyle = (rowData: any) => {
    if (rowData && active_account && rowData.account_id === active_account.account_id) {
      return { background: "#afe4e5", fontWeight: "bold" };

    } else if (rowData && rowData.status === "INACTIVE") {
      return { background: "#DDD", fontWeight: "bold", color: "#555555" };

    } else {
      return {};
    }
  };



  return (
    <>
      <ContentWrapper full>
      <TableCRUD
        title={"Organizations"}
        columns={[
          { label: "Name", dataKey: "name" },
          { label: "ID", dataKey: "account_id" },
          { label: "Created", dataKey: "created_at", renderer: renderDateCell },
          { label: "Last Updated", dataKey: "updated_at", renderer: renderDateCell },
        ]}
        rowStyle={rowStyle}
        onSelect={(account) => { console.log(account); dispatch(setActiveAccount(account)) }}
        isSelected={(id) => active_account?.account_id === id}
        idKey="account_id"

        actions={{

          create: {
            actionHandler: handleCreateAccount,
            fields: [
              {
                name: 'account_name',
                label: 'Account name',
                type: 'text',
                validation: { required: true, minLength: 3, validator:"lettersNumbers", message: 'Name is required' },
              },
            ]
          },

          view: {
            icon: <EyeIcon />,
            actionHandler: (item: any) => {
              navigate(`/organizations/${item.account_id}`)
            }
          },

          edit: {
            icon: <PencilSquareIcon />,
            actionHandler: (item: any) => {
              navigate(`/organizations/${item.account_id}?edit=true`)
            }
          },

          lock: {
            tooltip: "Deactivate organization",
            icon: <LockClosedIcon />,
            confirm: true,
            confirmationText: "Are you sure you want to deactivate the organization {name} ({id})?",
            confirmTitle: "Account deactivation",
            enabled: (item) => item.status === 'ACTIVE',
            actionHandler: (item: any) => { return handleDeactivateAccount(item) },
          },

          unlock: {
            tooltip: "Activate organization",
            icon: <LockOpenIcon />,
            confirm: true,
            confirmationText: "Are you sure you want to reactivate the organization {name} ({id})?",
            confirmTitle: "Organization activation",
            enabled: (item) => item.status === 'INACTIVE',
            actionHandler: (item: any) => { return handleActivateAccount(item) },
          },

          delete: {
            icon: <TrashIcon />,
            tooltip: "Delete organization",
            confirm: true,
            confirmationText: "Are you sure you want to delete the contract: {name} ({id})?",
            actionHandler: (item: any) => { return handleDeleteAccount(item) }
          },
        }}

        data={{ items: accounts ? accounts : [], total: accounts ? accounts?.length : 999 }}
      />

    </ContentWrapper>
    </>
  )
};