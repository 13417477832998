import React from 'react'

import {
  SwitchContainer,
  SwitchWrapper,
  Label,
  Switch,
  HelperText
} from './inputSwitch.styles'

import { classes } from 'config/helpers'

interface Props {
  checked?: boolean
  className?: string
  disabled?: boolean
  helperText?: string
  id?: string
  label?: string
  name?: string
  readOnly?: boolean
  small?: boolean
  tabIndex?: number
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const InputSwitch: React.FC<Props> = ({
  checked,
  className,
  disabled,
  helperText,
  id,
  label,
  name,
  readOnly = false,
  small = false,
  tabIndex,
  onChange
}) => {
  return (
    <SwitchContainer className={classes(className, { small })}>
      <SwitchWrapper className="switch--wrapper">
        <Switch
          aria-label={name}
          checked={checked}
          disabled={disabled}
          id={id || name}
          name={name}
          readOnly={readOnly}
          tabIndex={tabIndex}
          type={'checkbox'}
          onChange={onChange}
        />
        {label ? (
          <Label className="label--switch label--disabled" htmlFor={name}>
            {label}
          </Label>
        ) : null}
      </SwitchWrapper>
      <HelperText>{helperText}</HelperText>
    </SwitchContainer>
  )
}

InputSwitch.displayName = 'InputSwitch'
