import axios from 'axios'
import { APIURL } from 'config/helpers'

export const getContracts = (
  token: string,
  account_id: string,
  limit: number = 25,
  offset: number = 0,
  onSuccess: (r: any) => void,
  onError: (r: any) => void
) => {
  axios
    .get(`${APIURL}/contracts/${account_id}`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        limit: limit,
        offset: offset,
      }
    })
    .then(response => {
      onSuccess(response)
    })
    .catch(error => {
      onError(error)
    })
}

export const getContract = (
  token: string,
  account_id: string,
  contract_id: string,
  onSuccess: (r: any) => void,
  onError: (r: any) => void
) => {
  axios
    .get(`${APIURL}/contracts/${account_id}/${contract_id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(response => {
      onSuccess(response)
    })
    .catch(error => {
      onError(error)
    })
}


export const getContractUsers = (
  token: string,
  account_id: string,
  contract_id: string,
  onSuccess: (r: any) => void,
  onError: (r: any) => void
) => {
  axios
    .get(`${APIURL}/contracts/${account_id}/${contract_id}/users`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(response => {
      onSuccess(response)
    })
    .catch(error => {
      onError(error)
    })
}

export const getContractsForUser = (
  token: string,
  account_id: string,
  principal_id: string,
  onSuccess: (r: any) => void,
  onError: (r: any) => void
) => {
  axios
    .get(`${APIURL}/contracts/${account_id}/`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        principal_id: principal_id
      }
    })
    .then(response => {
      onSuccess(response)
    })
    .catch(error => {
      onError(error)
    })
}


// Promise based instead of callbacks

export const deleteContract = async (
  token: string,
  account_id: string,
  contract_id: string,
): Promise<any> => {
  try {
    const response = await axios
      .delete(`${APIURL}/contracts/${account_id}/${contract_id}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
    return response
  } catch (error) {
    throw error
  }
}


export const createContract = async (
  token: string,
  account_id: string,
  payload: any,
): Promise<any> => {
  try {
    const response = await axios
      .post(`${APIURL}/contracts/${account_id}`, payload, {
        headers: { Authorization: `Bearer ${token}` }
      })
    return response
  } catch (error) {
    throw error
  }
};

export const updateContract = async (
  token: string,
  account_id: string,
  contract_id: string,
  payload: any
): Promise<any> => {
  try {
    const response = await axios
      .put(`${APIURL}/contracts/${account_id}/${contract_id}`, payload, {
        headers: { Authorization: `Bearer ${token}` }
      })
    return response
  } catch (error) {
    throw error
  }
};

export const activateContract = async (
  token: string,
  account_id: string,
  contract_id: string,
): Promise<any> => {
  try {
    const response = await axios
      .post(`${APIURL}/contracts/${account_id}/${contract_id}/activate`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      })
    return response
  } catch (error) {
    throw error
  }
};

export const deactivateContract = async (
  token: string,
  account_id: string,
  contract_id: string,
): Promise<any> => {
  try {
    const response = await axios
      .post(`${APIURL}/contracts/${account_id}/${contract_id}/deactivate`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      })
    return response
  } catch (error) {
    throw error
  }
};

type ContractResponse = {
  data: {
    results: any[]; // Replace 'any' with the actual type of the items if you know it
    count: number;
  };
};

export const getAllContracts = async (token: string, account_id: string) => {
  let allItems: any[] = [];
  let offset = 0;
  const itemsPerPage = 200; // The number of items to fetch per request
  let total = 0;

  try {
    while (true) {
      let currentOffset = offset;
      // Wrap getAccounts in a promise to use async/await
      const response = await new Promise<ContractResponse>((resolve, reject) => {
        getContracts(
          token,
          account_id,
          itemsPerPage,
          currentOffset,
          (response: any) => resolve(response),
          (error: any) => reject(new Error(error.response.data.details))
        );
      });

      const items = response.data.results;
      total = response.data.count;      
      allItems = [...allItems, ...items];

      if (allItems.length >= total) {
        break; // Exit the loop when all items have been fetched
      }
      offset += items.length; // Increase the offset by the number of items fetched
    }
    return { items: allItems, total };
  } catch (error: any) {
    throw new Error(error.message || "Something went wrong.");
  }
};

export const assignContract = async (
  token: string,
  account_id: string,
  contract_id: string,
  principal_id: string
): Promise<any> => {
  try {
    const response = await axios
      .post(`${APIURL}/contracts/${account_id}/${contract_id}/assign`,
        { principal_id: principal_id },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
    return response
  } catch (error) {
    throw error
  }
};

export const dissociateContract = async (
  token: string,
  account_id: string,
  contract_id: string,
  principal_id: string
): Promise<any> => {
  try {
    const response = await axios
      .post(`${APIURL}/contracts/${account_id}/${contract_id}/dissociate`,
        { principal_id: principal_id },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
    return response
  } catch (error) {
    throw error
  }
};